import React,{useState,useEffect,useRef} from 'react'
import axios from "axios";
import {APIURL} from "../../../Global";
import HospRegisterPatient from "../../BDO/HospRegisterPatient";
import { ClearButton, Typeahead } from "react-bootstrap-typeahead";

import { Spinner } from 'react-bootstrap';


function MiscPage(props) {

const[pageShow,setPageShow]=useState(false)
const[pageShow1,setPageShow1]=useState(false)
const SubjectTypeahead = useRef(null)
const [nameError, setNameError] = useState("")
const [dataList, setDataList] = useState([])
const [serviceList, SetServiceList] = useState([]);
let placeholder = "Please select name"
const [id, setId] = useState("")
const [sub_service, setServiceName] = useState("")
const [vat, setVat] = useState("")
const [discount, setDiscount] = useState("")
const [amount, setAmount] = useState("")
const [main_service_name, setMainService] = useState("misc")

// let url = ""
// let name = ""

//  if (pageShow===true) {
//     name = "service_name"
//     placeholder = "Search a miscellaneous feature"
//     url =  "/api/v1/service-provider/hospital-services/?type=misc"
// }

const isValid = dataList.filter((option) => !!option["service_name"]).length === dataList.length;

const options = isValid ? dataList && dataList.map((option) => {
    return {
        label: option["service_name"],
        main_service_name:"misc",
        //unified_code: option["unified_code"],
        id: option["id"],
        amount:option["amount"],
        vat:option["vat"],
        discount:option["discount"]
    }

}) : [];




const handleAdd =() =>{

    props.dataHandle(serviceList)
    props.modalClose()
}

const handleRemoveItem = idx => {
    // assigning the list to temp variable
    const temp = [...serviceList];

    // removing the element using splice
    temp.splice(idx, 1);

    // updating the list
    SetServiceList(temp);
}

const handlePage =()=>
{
    setPageShow(true);
    setPageShow1(false);
}
const handleRegPage =()=>
{
    setPageShow(false);
    setPageShow1(true);
}


return(
<div className="paymentconfiginner">

 
        <button className="medicinebuttons1" onClick={()=>handleRegPage(true)}>Registration</button>
        <button className="medicinebuttons2" onClick={() => handlePage()}>Other</button><br/><br/>
       
{pageShow  ? 

     <div className="" style={{marginRight:"4%",marginTop:"-6%" ,alignItems:"center"}} >
        <div style={{ display: "flex", marginTop:"7%", width:"50%" ,marginLeft:"25%"}}>
    
<Typeahead
                            ref={SubjectTypeahead}
                            placeholder={placeholder}
                            style={{width:"100%"}}
                            onFocus={() => {
                                setNameError("")
                                const tokenString = sessionStorage.getItem("usertoken");

                                let v2 = tokenString.replace(
                                    /["]+/g,
                                    ""
                                ); /* REGEX To remove double quotes from tokenstring */
                                
                              
                                axios
                                    .get(`${APIURL}/api/v1/service-provider/hospital-services/?type=misc`, {
                                        headers: { Authorization: "Token " + v2 },
                                    })
                                    .then((res) => {
                                      
                                        //console.log(res);
                                        if (res.status === 200) {

                                            const datas = res.data;

                                            setDataList(datas);
                                            //console.log(featureList)
                                        }
                                    })
                            }}

                            options={options}
                            onChange={(data) => {
                              
                              
                                if (data.length) {
                                    setServiceName(data[0].label);
                                    //setUnicode(data[0].unified_code);
                                    setId(data[0].id)
                                    setAmount(data[0].amount)
                                    setVat(data[0].vat)
                                    setDiscount(data[0].discount)
                                    // setSerCategory(data[0].service_category)
                                }
                            }
                            }

                        >
                            {({ onClear, selected }) => (
                                <div className="rbt-aux" style={{ width:"5%"}}>
                                    {!!selected.length && <ClearButton onClick={onClear} />}
                                    {!selected.length && <Spinner animation="grow" size="sm" />}
                                </div>
                            )}
                        </Typeahead>
                        <button onClick={() =>{


                            serviceList.filter(value =>
                                value.sub_service === sub_service).length > 0 ?
                                setNameError(sub_service + " already there in your list!") :sub_service&&
                                SetServiceList(prevArray => [...prevArray, { sub_service, id,amount,vat,discount,main_service_name }]);
                        }}
                            className="btn btn-primary btn-col search-btn">
                            <i class="fa fa-arrow-right" aria-hidden="true"></i>
                           
                        </button></div>
                        {nameError ? <span className="error-validation-msg" style={{marginTop:"10%", width:"10%",height:"10%",color:"red"}}>{nameError}</span>:null}
                       
               
                    <div className="formField">
                        {serviceList.filter(value => Object.keys(value).length > 0).map((list, idx) => {
                            const value = list.sub_service;
                            return <>
                                <div key={idx} className='list-medicines' style={{ padding: "1px", marginTop: "20px" }}>
                                  
                                    <label style={{ color: "#000000" ,marginLeft:"30%",marginTop:"2%" }} className='list-names'><b>{value}</b>  </label>
                                    {/* <i
                                        class="fa fa-trash-o"
                                        onClick={() => handleRemoveItem(idx)}
                                        style={{ float: "right", color: "red", marginLeft:"60%",marginTop:"-15%" }}
                                    ></i> */}
                                </div>
                               
                            </>

                        })}<br />
             
                       


                    </div>
                  
                    <br/>
        <p className='text-center'>
          <button onClick={handleAdd} disabled={serviceList.length < 1} className='btn btn-primary btn-col'>Add</button>
        </p>
    </div>


: pageShow1 ?
<div style={{width:"100%"}}>

<HospRegisterPatient/>
</div>

:""}





</div>




)


}

export default MiscPage