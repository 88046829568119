import React, { useState, useCallback, useEffect } from "react";
//import {useHistory} from 'react-router-dom';
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import DatePicker from "react-date-picker";
import axios from "axios";
import Select from "react-select";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { APIURL } from "../../Global";
import VerifyOTPPatient from "../BDO/VerifyOTPPatient";
import VerifyOTPEmailUpdate from "../BDO/VerifyOTPEmailUpdate";
import VerifyMobile from "../BDO/VerifyMobile";
import VerifyEmail from "../BDO/VerifyEmail";
//import HospBdoNavbar from '../HospBdoNavbar/HospBdoNavbar';
import "./hospregisterpatient.css";
import "react-table-v6/react-table.css";
//import GeneratePatientInvoice from './GeneratePatientInvoice';
import debounce from "lodash.debounce";
import { useAlert } from "react-alert";
function HospPatienUpdate(props) {

  let navigate = useNavigate();
  const [data, setData] = useState(props.data);
  
  console.log("data", props.data);

  const value2 = props.data.idcard_type
    ? props.data.idcard_type.toLowerCase()
    : "";
    let regionval=props.data.state
    
  const label2 = props.data.idcard_type ? props.data.idcard_type : "";
  const [idcard, setIdCard] = useState({ value: value2, label: label2 });
  const [patientId, setPatientId] = useState("");
  const [rawPhone, setRawPhone] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [invalid, setInvalid] = useState(false);
  const [isValidUsername, setIsValidUsername] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);

  const [errorMsg, setErrorMsg] = useState("");
  const [errorShow, setErrorShow] = useState(false);
  const [successShow, setSuccessShow] = useState(null);
  const [successMsg, setSuccessMsg] = useState("");
  const [verifyOTP, setVerifyOTP] = useState(false);
  const [verifyOTPEmailUpdate, setVerifyOTPEmailUpdate] = useState(false);
  const [verifyMobile, setVerifyMobile] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState(false);
  const [otpval, setotpval] = useState("");
  const [signmethod, setSignMethod] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [loginEnable, setLoginEnabled] = useState(false);
  const [consentlog, setConsent] = useState(false);
  const [dob, setDob] = useState();
  const [, setFormattedDob] = useState("");
  const [invoiceShow, setInvoiceShow] = useState(false);
  const [country, setCountry] = useState("Kerala");
  const [login_datas, setLogin_datas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas"))
      ? JSON.parse(sessionStorage.getItem("logindatas"))
      : null
  );
  console.log("login_datas", login_datas);

  // const history =useHistory();
  const alert = useAlert();
  const [error, setError] = useState({
    email: "",
    mobile_number: "",
  });

  const identityoptions = [
    { value: "pan", label: "PAN" },
    { value: "passport", label: "PASSPORT" },
    { value: "aadhar", label: "AADHAR" },
    { value: "driving_license", label: "DRIVING LICENCE" },
  ];
  window.original_value = props.data;

  const getChangedData = (data) => {
    let objectByKeys = {};
    if (window.original_value) {
      let sda_changed_keys = Object.keys(data).filter(
        (key) => data[key] !== window.original_value[key]
      );
      sda_changed_keys.forEach((key) => {
        objectByKeys[key] = data[key];
      });
      delete window.original_value;
    }
    return objectByKeys;
    console.log("aaaa", objectByKeys);
  };
  const handleChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;
    setData((current) => ({
      ...current,
      [key]: val,
    }));
  };

  useEffect(() => {

    if(props.data.country_name==="INDIA"){
      setData((current) => ({
        ...current,
        country_name:"India",
      }));
    }

    setData((current) => ({
      ...current,
      state:props.data.state.charAt(0).toUpperCase() + props.data.state.slice(1)
    }));


  },[])

  const onSuccess = (data) => {
    setotpval(data.otp);
    setSignMethod(data);
    console.log(signmethod, otpval);
    setVerifyOTP(false);
    setModalShow(true);
    setLoginEnabled(true);
    setConsent(true);
  };
  const onSuccess1 = (data) => {
    //setotpval(data.otp)
    //setSignMethod(data)
    //console.log(signmethod,otpval)
    setVerifyMobile(false);
    setModalShow(true);
    //setLoginEnabled(true);
    //setConsent(true);
  

    handleNext();
  };
  const onSuccess2 = (data) => {
    //setotpval(data.otp)
    //setSignMethod(data)
    //console.log(signmethod,otpval)
   // setVerifyEmail(false);
    setModalShow(true);
    // setData((currentstate) => ({
    //   ...currentstate,
    //   email_verified: true,
    // }));
    // setData((currentstate) => ({
    //   ...currentstate,
    //   username: data.email,
    // }));
    //setLoginEnabled(true);
    //setConsent(true);

    handleUpdate();
  };
  const onSuccess4 = (data) => {
    // data.mobile_number
    // ? setData((currentstate) => ({
    //     ...currentstate,
    //     username: data.mobile_number,
    //   }))
    // : setData((currentstate) => ({
    //     ...currentstate,
    //     username: data.email,
    //   }));


    // data.mobile_number ?
    // setData((currentstate) => ({
    //   ...currentstate,
    //   phone_verified: true
    // })) :
    // setData((currentstate) => ({
    //   ...currentstate,
    //   email_verified: true
    // }))
    //setotpval(data.otp)
    //setSignMethod(data)
    //console.log(signmethod,otpval)
    setVerifyOTPEmailUpdate(false);
    setModalShow(true);
    //setLoginEnabled(true);
    //setConsent(true);
    



    // setData(currentstate=>({
    //   ...currentstate,
    //  login_consent: true

    // }))
  };
  const onCancel = () => {
    setVerifyOTP(false);
    setData((currentstate) => ({
      ...currentstate,
      consent: "",
    }));
  };
  const onCancel1 = () => {
    setVerifyMobile(false);
    setData((currentstate) => ({
      ...currentstate,
      // login_consent: ""
    }));
  };
  const onCancel2 = () => {
    setVerifyEmail(false);
    setData((currentstate) => ({
      ...currentstate,
      // login_consent: ""
    }));
  };
  const onCancel4 = () => {
    setVerifyOTPEmailUpdate(false);
    // setData((currentstate) => ({
    //   ...currentstate,
    //   login_consent: false,
    // }));
  };
  const SubmitPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Verified Successfully!!</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleDOB = (date) => {
    setDob(date);

    let dateUpdated = date;
    if (date !== null) {
      const offset = date.getTimezoneOffset();

      dateUpdated = new Date(date.getTime() - offset * 60 * 1000);

      let DateExtracted = dateUpdated.toISOString().split("T")[0];

      /*let dateString= DateExtracted.toString(); */
      setFormattedDob(DateExtracted);

      setData((currentstate) => ({
        ...currentstate,
        dob: DateExtracted,
      }));
    }
  };

  const selectCountry = (val) => {
    setData((currentstate) => ({
      ...currentstate,
      country: val,
    }));
  };

  const selectRegion = (e) => {

    
    setData((currentstate) => ({
      ...currentstate,
      state: e,
    }));
  };

  const handleGenderRadioButton = (e) => {
    let genderOpted = e.target.value;
    setData((currentstate) => ({
      ...currentstate,
      gender: genderOpted,
    }));
  };

  const handleConsentRadioButton = (e) => {
   

    if (data.mobile_number !== "") {
      let consentOpted = false;
      if (e.target.value === "true") {
        consentOpted = true;
      } else {
        consentOpted = false;
      }
      setData((currentstate) => ({
        ...currentstate,
        login_consent: consentOpted,
      }));
      // setData((currentstate) => ({
      //   ...currentstate,
      //   email_verified: consentOpted,
      // }));
      // setData((currentstate) => ({
      //   ...currentstate,
      //   phone_verified: consentOpted,
      // }));
      if (consentOpted === true) {
        setVerifyOTPEmailUpdate(true);
      } else {
        //setLoginEnabled(false)
        //setConsent(false)
      }
    } else {
      setErrorMsg("Please fill all mandatory fields");
      setErrorShow(true);
    }
    // setUserDatas(currentstate=>({
    //   ...currentstate,
    //   consent: consentOpted

    // }))
  };

  function OtpPopUp(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <VerifyOTPPatient
            onSuccess={onSuccess}
            onCancel={onCancel}
            email={data.email}
            phone={data.mobile_number}
          />
        </Modal.Body>
      </Modal>
    );
  }
  function OtpPopUp1(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <VerifyMobile
            onSuccess1={onSuccess1}
            onCancel1={onCancel1}
            phone={data.mobile_number}
            email={data.email}
          />
        </Modal.Body>
      </Modal>
    );
  }
  function OtpPopUp2(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <VerifyEmail
            onSuccess2={onSuccess2}
            onCancel2={onCancel2}
            email={data.email}
          />
        </Modal.Body>
      </Modal>
    );
  }
  function OtpPopUp4(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <VerifyOTPEmailUpdate
            onSuccess4={onSuccess4}
            onCancel4={onCancel4}
            phone={data.mobile_number}
            email={data.email}
          />
        </Modal.Body>
      </Modal>
    );
  }

  //  const handlePhoneInput= (value, data, event, formattedValue)=>{

  //     const rawPhone = value.slice(data.dialCode.length) ;

  //     const dialcode = value.slice(0,data.dialCode.length) ;
  //      setData(currentstate=>({
  //           ...currentstate,
  //              phone: {
  //               dialcode: dialcode,
  //                 rawphone: rawPhone,
  //                 mobile_number: value
  //               }

  //                          }));

  //    }
  const handlePhoneInput = (value, data, event, formattedValue) => {
    //setRawPhone(value.slice(data.dialCode.length));
    //setDialCode(value.slice(0, data.dialCode.length))
    setData((currentstate) => ({
      ...currentstate,
      mobile_number: value,
    }));
    // setVerifyMobile(true)
  };

  const handleCheckExistingPhone = (e) => {

    setIsValidPhone(true);
    if (e.target.value !== "") {
      // const rawPhone =  e.rawPhone ;

      //const dialcode =  e.dialcode;
      setError((currentstate) => ({
        ...currentstate,
        mobile_number: "",
      }));

      const data = {
        code: "91",
        mobile_number: e.target.value.replace(/-/g, ""),
      };

      axios
        .post(`${APIURL}/api/v1/account/check-user/`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            if (res.data.is_existing_user === true) {
              setError(currentstate=>({
                ...currentstate,
              mobile_number: "Phone Number already registered!"}));
                      setIsValidPhone(false);
                   
              setVerifyOTPEmailUpdate(false);
            } else {
              setIsValidPhone(true);
            }
          } else {
            setIsValidPhone(true);
          }
        })
        .catch((err) => {});
    }
  };

  const handleCheckExisting = (e) => {
    setIsValidEmail(true);
    if (e.target.value !== "") {
      setError((currentstate) => ({
        ...currentstate,
        email: "",
      }));
      e.preventDefault();
      let key = e.target.name;

      const data = {
        [e.target.name]: e.target.value,
      };

      axios
        .post(`${APIURL}/api/v1/account/check-user/`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            if (res.data.is_existing_user === true) {
              setIsValidEmail(false);

              setError(currentstate=>({
                ...currentstate,
              email: `This ${key} is already registered!`}));
                   setIsValidEmail(false);
                   
              setVerifyOTPEmailUpdate(false);
              setData((currentstate) => ({
                ...currentstate,
                login_consent: false,
              }));
        
            } else {
              setIsValidEmail(true);
            }
          } else {
            setIsValidEmail(true);
          }
        })
        .catch((err) => {});
    }
  };

  const handleChangecard = (e) => {
    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;

    setError((currentstate) => ({
      ...currentstate,
      [key]: "",
    }));

    setData((currentstate) => ({
      ...currentstate,
      [key]: val,
    }));
  };

  const IdentityChange = (data, e) => {
    const val = data.value;
    const label = data.label;

    setData((currentstate) => ({
      ...currentstate,
      idcard_type: val,
    }));
  };

  //handleChangeID
  const handleChangeID = (e) => {
    e.preventDefault();
    const val = e.target.value;
    // const key=e.target.name;
    setData((current) => ({
      ...current,
      idcard_no: val,
    }));
  };

  const validate = async () => {
    let input = data;
    let errors = {};
    let isValid = true;
    setError({});

    if (!input["first_name"] || input["first_name"] === "") {
      isValid = false;
      errors["firstname"] = "Please enter first name";
    }
    // if (!input["idcard_type"].value || input["idcard_type"].value==="" ) {
    //   isValid = false;
    //   errors["identitycard"] = "Please select identity card";
    // }
    if (!input["pincode"] || input["pincode"] === "") {
      isValid = false;
      errors["pincode"] = "Please enter pincode";
    }
    // if (!input["email"] || input["email"]==="" ) {
    //   isValid = false;
    //   errors["email"] = "Please enter email address";
    // }
    // if (!input["idcard_no"] || input["idcard_no"]==="" ) {
    //   isValid = false;
    //   errors["identitynumber"] = "Please enter identity card number";
    // }

    if (!input["last_name"] || input["last_name"] === "") {
      isValid = false;
      errors["lastname"] = "Please enter last name";
    }

    if (!input["state"] || input["state"] === "") {
      isValid = false;
      errors["state"] = "Please select a region";
    }

    if (isValidPhone === false) {
      isValid = false;
      errors["mobile_number"] = "Phone number already registered!";
    }
    if (isValidEmail === false) {
      isValid = false;
      errors["email"] = "Email already registered!";
    }

    if (input["email"] !== "") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(input["email"])) {
        isValid = false;
        errors["email"] = "Please enter a valid email address.";
      }
    }

    if (typeof input["first_name"] !== "undefined") {
      var pattern2 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/);
      if (pattern2.test(input["firstname"])) {
        isValid = false;
        errors["firstname"] = "Special Characters are not allowed";
      }
    }

    if (typeof input["last_name"] !== "undefined") {
      var pattern3 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/);
      if (pattern3.test(input["lastname"])) {
        isValid = false;
        errors["lastname"] = "Special Characters are not allowed";
      }
    }

    if (
      !data.mobile_number ||
      data.mobile_number === "" ||
      !data.mobile_number ||
      data.mobile_number === ""
    ) {
      isValid = false;
      errors["mobile_number"] = "Please enter phone number";
    }

    if (input["dob"] === "" || input["dob"] === null) {
      isValid = false;
      errors["dob"] = "Please enter your date of birth";
    }
    if (
      typeof data.gender == "undefined" ||
      data.gender === "" ||
      data.gender === null
    ) {
      isValid = false;
      errors["gender"] = "Please select gender";
    }

    console.log("errors", errors);

    setError((currentstate) => ({
      ...currentstate,
      ...errors,
    }));

    return isValid;
  };

  // const handleContinue= async (e)=>{

  //  // e.preventDefault();
  //  let data2=props.data.country_code+props.data.mobile_number
  //   if( data2!==data.phone || props.data.email!==data.email){

  //     if(await validate() || isValidPhone || isValidEmail){

  //       //setVerifyMobile(true)
  //       handleUpdate();

  //     }
  //     else{
  //       if(await validate()){
  //         handleUpdate();
  //       }

  //     }

  //   }

  //}

  const handleContinue = async (e) => {
    let data2 = props.data.mobile_number;
    //let data3=props.data.email!==data.email
    if (data.mobile_number === undefined) {
      handleNext();
    } else if (data2 !== data.mobile_number) {
      if (await isValidPhone) {
        setVerifyMobile(true);
      }
    } else {
      if (await validate()) {
        handleNext();
      }
    }
  };

  const handleNext = async (e) => {
    //if( data.email!=="" && data.email!==null){
    let data3 = props.data.email;

    if (data.email === undefined) {
      handleUpdate();
    } else if (data3 !== data.email) {
      if (isValidEmail) {
        setData((currentstate) => ({
          ...currentstate,
          email: data.email,
        }));
        alert.show('an email verification link is send to your new mail id');
  
        handleUpdate();
      }
    } else {
      handleUpdate();
    }

    //}
  };

  const handleUpdate = () => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    let dataToSend = getChangedData(data);

    axios
      .put(
        `${APIURL}/api/v1/staff/patient-list/?patient_id=${data.id}`,
        dataToSend,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + str,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          alert.success(res.data.message);
        } else {
          alert.Error(res.data.message);
        }
      })
      .catch((err) => {
        setErrorMsg("Error in registration");
        setErrorShow(true);
      });
  };

  const handleSuccess = () => {
    setSuccessShow(false);
    // history.push('/bdo')
  };

  const handleInvoice = () => {
    setInvoiceShow(true);
  };

  const SuccessPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Patient Registered Successfully!!</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ErrorPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="text-danger">
            {errorMsg !== "" ? (
              errorMsg
            ) : (
              <h5 className="text-danger">
                Error in Data Submission. Please try again!
              </h5>
            )}
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <div>
      <div className="">
        <div
          className="hosp-patient-form"
          style={{ marginTop: "1%", width: "82%" }}
        >
          <h4 className="title-of-tasks">Patient Details</h4>

          <br />
          <div className="form-align">
            <div className="item item-left-row">
              <label className="">* First Name: </label>
              <input
                className="form-control"
                type="text"
                name="first_name"
                placeholder=""
                value={data.first_name}
                onChange={handleChange}
                maxLength="30"
              />

              {error.firstname ? (
                <div className="error-validation-msg">{error.firstname}</div>
              ) : null}
            </div>
            <div className="item item-left-row">
              <label className="">* Last Name: </label>{" "}
              <input
                className="form-control"
                type="text"
                name="last_name"
                value={data.last_name}
                placeholder=""
                onChange={handleChange}
                maxLength="30"
              />
              {error.lastname ? (
                <div className="error-validation-msg">{error.lastname}</div>
              ) : null}
            </div>
            <div className="form-control phone-input item item-left-row">
              <label className="phone-hov">* Phone: </label>
              <PhoneInput
                inputProps={{}}
                country={"in"}
                onlyCountries={["in"]}
                //countryCodeEditable={false}
                value={data.mobile_number}
                onChange={handlePhoneInput}
                onBlur={handleCheckExistingPhone}
                disableCountryCode={true}
              />
              {error.mobile_number ? (
                <div className="error-validation-msg"> {error.mobile_number}</div>
              ) : null}
            </div>

            <div className="item item-left-row">
              <label className=""> Email Address: </label>
              <input
                className="form-control"
                type="email"
                name="email"
                value={data.email}
                placeholder=""
                onChange={handleChange}
                onBlur={handleCheckExisting}
                maxLength="50"
              />

              {error.email ? (
                <div className="error-validation-msg"> {error.email}</div>
              ) : null}
            </div>

            {/*  <input className="form-control" type="text" name="dob" placeholder="Date of Birth* : YYYY-MM-DD" required />
             */}

            {/* <div style={{alignSelf:"end"}} className="form-control gender-div">
                                       
                                       
                 <span>* Date of Birth:</span> 
                    <DatePicker
                          onChange={date=>{handleDOB(date)}}
                       
                          maxDate = {new Date()}
                          value={data.dob}
                          
                     /> 
                       {error.dob?  <div className="error-validation-msg"> {error.dob}</div> : null}
         
         </div>   */}
            <div className="item item-left-row">
              <label className=""> Identity card: </label>
              <Select
                defaultValue={idcard}
                onChange={(data, e) => {
                  IdentityChange(data, e);
                }}
                options={identityoptions}
                name="idcard_type"
                className="select-currency select-flex"
              />
              {/* {error.identitycard ? <div className="error-validation-msg"> {error.identitycard}</div> : null} */}
            </div>

            <div
              className="form-control gender-div"
              style={{ alignSelf: "end" }}
            >
              <span>* Gender:</span>
              <input
                type="radio"
                id="male"
                name="gender"
                value="male"
                checked={data.gender === "male"}
                onClick={handleGenderRadioButton}
              />
              <label htmlFor="male">Male</label>

              <input
                type="radio"
                id="female"
                name="gender"
                value="female"
                checked={data.gender === "female"}
                onClick={handleGenderRadioButton}
              />

              <label htmlFor="female">Female</label>

              <input
                type="radio"
                id="others"
                name="gender"
                value="others"
                checked={data.gender === "others"}
                onClick={handleGenderRadioButton}
              />
              <label htmlFor="others">Others</label>

              {error.gender ? (
                <div className="error-validation-msg"> {error.gender}</div>
              ) : null}
            </div>

            <div className="item item-left-row">
              <span> Identity Card Number:</span>
              <input
                className="form-control"
                type="text"
                name="idcard_no"
                value={data.idcard_no}
                onChange={handleChangeID}
              />
              {/* {error.idcard_no ?  <div className="error-validation-msg"> {error.idcard_no}</div> : null} */}
            </div>

            <div className="item item-left-row">
              <label className=""> Address line1: </label>
              <input
                className="form-control"
                type="text"
                name="province"
                value={data.province}
                placeholder="province"
                onChange={handleChange}
              />
            </div>

            <div className="item item-left-row">
              <label className=""> Address line2: </label>
              <input
                className="form-control"
                type="text"
                name="city"
                placeholder="city"
                value={data.city}
                onChange={handleChange}
              />
            </div>
            <div className="item item-left-row">
              <label className=""> Address line3: </label>
              <input
                className="form-control"
                type="text"
                name="location"
                placeholder="district"
                value={data.location}
                onChange={handleChange}
              />
            </div>

            <div className="country-selector item-left-row">
              <label className=""> Country: </label>
              <input
                className="form-control"
                value={data.country_name}
                disabled
                onChange={(val) => selectCountry(val)}
              />
            </div>
            <div className="region-selector item-left-row">
              <label className="">* Region: </label>
              <RegionDropdown
            
                country="India"
                value={data.state}
                onChange={(e) => selectRegion(e)}
              />
              {error.state ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {" "}
                  {error.state}
                </div>
              ) : null}
            </div>

            {/* <div className="item item-left-row"><label className="">* Zipcode: </label><input className="form-control" type="text" name="zipcode" placeholder="" onChange={handleChange} maxLength='50'
                                        /> 
                                        {error.zipcode ? <div className="error-validation-msg"> {error.zipcode}</div> :null}
                                           </div> */}

            <div className="item item-left-row">
              <label className="">* Pincode: </label>
              <input
                className="form-control"
                type="number"
                name="pincode"
                placeholder=""
                value={data.pincode}
                onChange={handleChange}
                maxLength="10"
              />
              {error.pincode ? (
                <div className="error-validation-msg"> {error.pincode}</div>
              ) : null}
            </div>

            <div className="form-control gender-div consent-div">
              <span>* App login :</span>
              <input
                type="radio"
                id="yes"
                name="consent"
                value="true"
                checked={data.login_consent === true}
                onClick={handleConsentRadioButton}
              />
              <label htmlFor="yes">Yes</label>

              <input
                type="radio"
                id="no"
                name="consent"
                value="false"
                checked={data.login_consent === false}
                onClick={handleConsentRadioButton}
              />

              <label htmlFor="no">No</label>
            </div>
          </div>
          <br />
          <div className="form-button">
            <button
              onClick={() => handleContinue()}
              className="btn btn-primary btn-col"
            >
              Update
            </button>
          </div>
        </div>
      </div>

      {successShow ? (
        <SuccessPopup show={successShow} onHide={handleSuccess} />
      ) : null}
      {verifyOTP ? (
        <OtpPopUp
          show={verifyOTP}
          onHide={() => {
            setVerifyOTP(false);
          }}
        />
      ) : null}

      {verifyMobile ? (
        <OtpPopUp1
          show={verifyMobile}
          onHide={() => {
            setVerifyMobile(false);
          }}
        />
      ) : null}
      {verifyEmail ? (
        <OtpPopUp2
          show={verifyEmail}
          onHide={() => {
            setVerifyEmail(false);
          }}
        />
      ) : null}
      {verifyOTPEmailUpdate ? (
        <OtpPopUp4
          show={verifyOTPEmailUpdate}
          onHide={() => {
            setVerifyOTPEmailUpdate(false);
          }}
        />
      ) : null}

      {errorShow ? (
        <ErrorPopup show={errorShow} onHide={() => setErrorShow(false)} />
      ) : null}

      {modalShow ? (
        <SubmitPopUp
          show={modalShow}
          onHide={() => {
            setModalShow(false);
            // data.modalClose();

            // setSubmitMsg("");
          }}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default HospPatienUpdate;
