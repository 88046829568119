import React, { useState,useEffect,useContext } from "react";
import axios from 'axios';
import DatePicker from "react-date-picker";
import { useAlert } from "react-alert";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import 'react-datepicker/dist/react-datepicker.css';
import PatientNav from "../PatientNav";
import {VitalDetails} from '../Context';
let apiUrl = '';
let graphName = 'Loading...!';
function MyHealthVitalsGraph({ setShowNavbarAndFooter }) {
  const { vitalsName, setVitalsName } = useContext(VitalDetails);
  const [show, setShow] = useState(false);
  const [selectedBtn, setSelectedBtn] = useState("OneWeek");
  const [selectedGlucose, setSelectedGlucose] = useState("glucose_fasting");
  const [graphData, setGraphData] = useState([]);
  const [graphData1, setGraphData1] = useState([]);
  const [errorMsg, setErrorMsg] = useState(false);
  const [errorMsg1, setErrorMsg1] = useState(false);
 
  const [OneMonthDate, setMonthDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
      .toISOString()
      .split("T")[0]
  );
  const currentDates = new Date().toISOString().slice(0, 10);
  const currentDate = new Date();
  const oneWeekBefore = new Date();
  oneWeekBefore.setDate(currentDate.getDate() - 7);
  const oneWeekBeforeDate = oneWeekBefore.toISOString().split('T')[0];
  const [refreshGraph, setRefreshGraph] = useState(false);
  const [refreshWeekGraph, setRefreshWeekGraph] =useState(false);
  const [validationError, setValidationError] = useState(false);
  const [validationError1, setValidationError1] = useState(false);
  const [updateSystolic, setUpdateSystolic] = useState('');
  const [updateDiastolic, setUpdateDiastolic] = useState('');
  const [updateSpo2, setUpdateSpo2] = useState('');
  const [updatepulse, setUpdatePulse] = useState("");
  const [updateTemp, setUpdateTemp] = useState('');
  const [updateGlucose, setUpdateGlucose] = useState('');
  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
      .toISOString()
      .split("T")[0]
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000)
      .toISOString()
      .split("T")[0]
  );
  const alert = useAlert();
  useEffect(() => {
    setShowNavbarAndFooter(false);
    return () => setShowNavbarAndFooter(true);
  }, [setShowNavbarAndFooter]);
  useEffect(() =>{
    console.log("inside vitals name check useEffect");
    let vitals = '';
    if(vitalsName === 'blood_pressure'){
      vitals = 'blood-pressure';
      console.log("blood pressure selected",oneWeekBeforeDate);
      graphName = 'Blood Pressure '; 
    }else if(vitalsName === 'spo2'){
      vitals = 'spo2';
      graphName = 'SpO2 ';
    }
    else if(vitalsName === 'pulse'){
      vitals = 'pulse';
      graphName = 'Pulse ';
    }
    else if (vitalsName === 'glucose'){
      vitals = 'glucose';
      graphName = 'Blood Sugar ';
    }
    else if (vitalsName === 'temperature'){
      vitals = 'temperature';
      graphName = 'Temeperature ';
    }else{
      vitals = 'blood-pressure';
      graphName = 'Blood Pressure ';
    }
    apiUrl = `https://uniapi.cianlogic.com/api/v1/doctor/patient-${vitals}/?start_date=${oneWeekBeforeDate}&end_date=${currentDates}&timeZone=Asia/Kolkata&sort_by=asc`;
    setRefreshWeekGraph(false);
    setRefreshGraph(true);
  },[vitalsName,refreshWeekGraph]);
 
  const selectDateGraph = () => {
    console.log("inside selectDateGraph");
    setSelectedBtn("selectDate");
    handleShow();

  };
  const selectMonthGraph = () => {
    let Vitals = '';
    console.log("inside one month Graph",OneMonthDate);
    setSelectedBtn("OneMonth");
    if(vitalsName === 'blood_pressure'){
      Vitals = 'blood-pressure';
    }else if(vitalsName === 'spo2'){
      Vitals = 'spo2';
    }else if(vitalsName === 'pulse'){
      Vitals = 'pulse';
    }
    else if (vitalsName === 'glucose'){
      Vitals = 'glucose';
    }
    else if (vitalsName === 'temperature'){
      Vitals = 'temperature';
    }else{
      Vitals = 'blood-pressure';
      
    }
    apiUrl = `https://uniapi.cianlogic.com/api/v1/doctor/patient-${Vitals}/?start_date=${OneMonthDate}&end_date=${currentDates}&timeZone=Asia/Kolkata&sort_by=asc`;
    setRefreshGraph(true);
  };

  const handleSetDateGraph = () =>{
    let Vitals = '';
    console.log("inside select date graph");
    if(vitalsName === 'blood_pressure'){
      Vitals = 'blood-pressure';
    }else if(vitalsName === 'spo2'){
      Vitals = 'spo2';
    }else if(vitalsName === 'pulse'){
      Vitals = 'pulse';
    }
    else if (vitalsName === 'glucose'){
      Vitals = 'glucose';
    }
    else if (vitalsName === 'temperature'){
      Vitals = 'temperature';
    }else{
      Vitals = 'blood-pressure';
      
    }
    apiUrl = `https://uniapi.cianlogic.com/api/v1/doctor/patient-${Vitals}/?start_date=${startDate}&end_date=${endDate}&timeZone=Asia/Kolkata&sort_by=asc`;
    setRefreshGraph(true);
    handleClose();
  }
  const selectWeekGraph = () => {
    console.log("inside week Graph");
    setSelectedBtn("OneWeek");
    setRefreshWeekGraph(true);
  };
  const selectGlucoseFasting = () => {
    setSelectedGlucose("glucose_fasting");
    setRefreshGraph(true);
  };
  const selectGlucoseNF = () => {
    setSelectedGlucose("glucose");
    setRefreshGraph(true);
  };
  const selectGlucoseRandom = () => {
    setSelectedGlucose("glucose_random");
    setRefreshGraph(true);
  };

  

  
  useEffect(() => {
   
    console.log("inside one week date",oneWeekBeforeDate);
    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')
    axios
      .get(apiUrl
        ,
        {
          headers: {
            Authorization: 'Token ' + str
        
          },
        }
      )
      .then(function (response) {
        console.log("api response", response.data);
        if (response.status === 200) {
          // console.log("success to retrive data",response.data.results);
          console.log("success to retrive data2",response.data.message);
          const arr = response.data.results;
          if(response.data.message === 'Spo2 details'){
            setGraphData(null);
            const spo2Data = [];
            arr.map((result) => {
              spo2Data.push({
                date: result.date.slice(0, 10),
                value: result.spo2,
                
              });
            });
             setGraphData1(spo2Data);
          }else if(response.data.message === 'Blood pressure details'){
            setGraphData1(null);
            const systolicData = [];
          
            arr.map((result) => {
              systolicData.push({
                date: result.date.slice(0, 10),
                systolic: result.systolic,
                diastolic:result.diastolic,
              });
            });
             setGraphData(systolicData);
          }else if(response.data.message === 'Pulse details'){
            setGraphData(null);
            const pulseData = [];
            arr.map((result) => {
              pulseData.push({
                date: result.date.slice(0, 10),
                value: result.pulse,
                
              });
            });
             setGraphData1(pulseData);
          }else if(response.data.message === 'Temp details'){
            setGraphData(null);
            const temperatureData = [];
            arr.map((result) => {
              temperatureData.push({
                date: result.date.slice(0, 10),
                value: result.temp,
                
              });
            });
             setGraphData1(temperatureData);
          }else if(response.data.message === 'Glucose details'){
            setGraphData(null);
            const glucoseData = [];
            arr.map((result) => {
              if(selectedGlucose === result.glucose_type){
                glucoseData.push({
                  date: result.date.slice(0, 10),
                  value: result.value,
                  
                });
              }
             
            });
             setGraphData1(glucoseData);
          }
          
          
         
        
        }
      })
      .catch(function (error) {
        console.log("inside catch funv");
        if (error.response.status === 404) {
          console.log(error.response.data);
           setGraphData(null);
        }
      });
   setRefreshGraph(false);
  }, [refreshGraph,vitalsName]);

  //method to post updated vitals - blood pressure
  const UpdateVitalsBloodPressure = () =>{
    if(!validationError && !validationError1){
      const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')
    axios
      .post(
        `https://uniapi.cianlogic.com/api/v1/patient/vitals/`,
        {
      
          vital_name:"blood_pressure",
          result:updateSystolic,
          additional_result: updateDiastolic
          //date:"updatedBloodGToApi"
        },
        {
          headers: {
            Authorization: 'Token ' + str
        
          },
        }
      )
      .then(function (response) {
        console.log("api response", response.data);
        if (response.status === 200) {
          console.log("success to update data");
          alert.success("successfully updated");
        }
      })
      .catch(function (error) {
        console.log("inside catch funv");
        if (error.response.status === 404) {
          console.log(error.response.data);
          
        }
      });
      setRefreshWeekGraph(true);
    }else{
      alert.error("Faild to update vital details");
    }
    setUpdateSystolic('');
    setUpdateDiastolic('');
    };
  //method to post updated vitals -
  const UpdateVitals = () =>{
    let vitaltype = '';
    let result = '';
    if(!validationError){
      if(vitalsName === 'glucose'){
        vitaltype = selectedGlucose;
      }else if(vitalsName === 'spo2'){
        vitaltype = 'spo2';
      }else if(vitalsName === 'pulse'){
        vitaltype = 'pulse';
      }else if(vitalsName === 'temperature'){
        vitaltype = 'temperature';
      }
      
        if(updateGlucose !== ''){
          result = updateGlucose;
        }else if(updateSpo2 !== ''){
          result = updateSpo2;
        }else if(updatepulse !== ''){
          result = updatepulse;
        }else if(updateTemp !== ''){
          result = updateTemp;
        }
        const tokenString = sessionStorage.getItem('usertoken');

        let str = tokenString.replace(/["]+/g, '')

    axios
      .post(
        `https://uniapi.cianlogic.com/api/v1/patient/vitals/`,
        {
      
          vital_name:vitaltype,
          result:result
          //additional_result: updateDiastolic
          //date:"updatedBloodGToApi"
        },
        {
          headers: {
            Authorization: 'Token ' + str
        
          },
        }
      )
      .then(function (response) {
        console.log("api response", response.data);
        if (response.status === 200) {
          console.log("success to update data");
          alert.success("successfully updated");
        }
      })
      .catch(function (error) {
        console.log("inside catch funv");
        if (error.response.status === 404) {
          console.log(error.response.data);
          alert.error("Faild to update vital details");
          
        }
      });
      setRefreshWeekGraph(true);
    }else{
      alert.error("Faild to update vital details");
    }
    setUpdateSpo2('');
    setUpdatePulse("");
    setUpdateTemp('');
    setUpdateGlucose('');
    };
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  console.log("graph data after",graphData);

  //methods for validating vitals data
  const SystolicValidator = (event) =>{
    event.preventDefault();
    if(event.target.value < 40 || event.target.value > 400){
      setValidationError(true);
      setErrorMsg(true);
    }else{
      setValidationError(false);
      setUpdateSystolic(event.target.value);
      setErrorMsg(false);
     
    }setUpdateSystolic(event.target.value);
  };
  
  const DiastolicValidator = (event) =>{
    event.preventDefault();
    if(event.target.value < 20 || event.target.value > 250){
      setValidationError1(true);
      setErrorMsg1(true);
    }else{
      setValidationError1(false);
      setErrorMsg1(false);
      setUpdateDiastolic(event.target.value);
      
    }setUpdateDiastolic(event.target.value);
  };
  const spo2Validator = (event) =>{
    event.preventDefault();
    if(event.target.value < 60 || event.target.value > 100){
      setValidationError(true);
      setErrorMsg(true);
    }else{
      setUpdateSpo2(event.target.value);
      
      setValidationError(false);
      setErrorMsg(false);
      
      
    }setUpdateSpo2(event.target.value);
  };
  const pulseValidator = (event) =>{
    event.preventDefault();
    if(event.target.value < 20 || event.target.value > 200){
      setValidationError(true);
      setErrorMsg(true);
    }else{
      setUpdatePulse(event.target.value);
      setValidationError(false);
      setErrorMsg(false);
      
      
    }setUpdatePulse(event.target.value);
  };
  const tempValidator = (event) =>{
    event.preventDefault();
    if(event.target.value < 25 || event.target.value > 50){
      setValidationError(true);
      setErrorMsg(true);
    }else{
      setValidationError(false);
      setErrorMsg(false);
      setUpdateTemp(event.target.value);
      
    }setUpdateTemp(event.target.value);
  };  
  const glucoseValidator = (event) =>{
    
    event.preventDefault();
    if(event.target.value < 20 || event.target.value > 900){
      setValidationError(true);
      setErrorMsg(true);
    }else{
      setValidationError(false);
      setErrorMsg(false);
      setUpdateGlucose(event.target.value);
      
    }setUpdateGlucose(event.target.value);
  };
  const handleKeyPress = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    // Allow only numbers (0-9) and the backspace key
    if (charCode !== 8 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
    }
  };
  const handleFromDate = (date) => {
    if (date !== null) {
      const offset = date.getTimezoneOffset();

      let dateUpdated = new Date(date.getTime() - offset * 60 * 1000);

      let DateExtracted = dateUpdated.toISOString().split("T")[0];

      setStartDate(DateExtracted);
      console.log("date extracted ",DateExtracted);
    }
  };
  const handleToDate = (date) => {
    if (date !== null) {
      const offset = date.getTimezoneOffset();
 
      let dateUpdated = new Date(date.getTime() - offset * 60 * 1000);

      let DateExtracted = dateUpdated.toISOString().split("T")[0];

      setEndDate(DateExtracted);
      console.log("date extracted 2 ",DateExtracted);
    }
  };
  return (
    <><PatientNav/><br/><br/>
    <div className="row " style={{ padding: "2% 1% 1% 3%" }}>
      <div className="col-8 each-list-doc" style={{padding:"1% 1% 1% 5%"}}>
    
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <b style={{ fontFamily: "Poppins", fontSize: "22px" }}>
            {graphName}
          </b>
        </div>
      {graphData &&  
      <LineChart width={600} height={330} data={graphData}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" style={{fontFamily:"Poppins",fontSize:"12px"}} />
      <YAxis dataKey="systolic" style={{fontFamily:"Poppins",fontSize:"14px"}}/>
      <Tooltip />
      <Legend />
      <Line type="monotone" dataKey="diastolic" stroke="#8884d8" strokeWidth={2} />
      <Line type="monotone" dataKey="systolic" stroke="#82ca9d" strokeWidth={2}/>
    </LineChart>}

    {graphData1 && (  
      <LineChart width={600} height={330} data={graphData1}>
        
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" style={{fontFamily:"Poppins",fontSize:"12px"}} />
      <YAxis dataKey="value" style={{fontFamily:"Poppins",fontSize:"14px"}}/>
      <Tooltip />
      <Legend />
      <Line type="monotone" dataKey="value" stroke="#8884d8" strokeWidth={2} dot={{r:4}} activeDot={{r:6}} />
     
    </LineChart>)}

    
      <div
        style={{
          margin: "12px",
          display: "flex",
          justifyContent: "center",
          width: "75%",
          gap: "20px",
        }}
      >
        <span>
          <button
            className="graphBtn"
            onClick={selectWeekGraph}
            style={
              selectedBtn === "OneWeek"
                ? { background: "#007E85", color: "white", fontWeight: "800" }
                : {}
            }
          >
            One week
          </button>
        </span>

        <span>
          <button
            className="graphBtn"
            onClick={selectMonthGraph}
            style={
              selectedBtn === "OneMonth"
                ? { background: "#007E85", color: "white", fontWeight: "800" }
                : {}
            }
          >
            One month
          </button>
        </span>
        <span>
          <button
            className="graphBtn"
            onClick={selectDateGraph}
            style={
              selectedBtn === "selectDate"
                ? { background: "#007E85", color: "white", fontWeight: "800" }
                : {}
            }
          >
            Select a date
          </button>
        </span>
      </div>
      {vitalsName === 'glucose' && (
        <div
        style={{
          margin: "12px",
          display: "flex",
          justifyContent: "center",
          width: "75%",
          gap: "20px",
        }}
      >
        <span>
          <button
            className="graphBtn"
            onClick={selectGlucoseFasting}
            style={
              selectedGlucose === "glucose_fasting"
                ? { background: "#007E85", color: "white", fontWeight: "800" }
                : {}
            }
          >
            Fasting
          </button>
        </span>

        <span>
          <button
            className="graphBtn"
            onClick={selectGlucoseNF}
            style={
              selectedGlucose === "glucose"
                ? { background: "#007E85", color: "white", fontWeight: "800" }
                : {}
            }
          >
            Non-Fasting
          </button>
        </span>
        <span>
          <button
            className="graphBtn"
            onClick={selectGlucoseRandom}
            style={
              selectedGlucose === "glucose_random"
                ? { background: "#007E85", color: "white", fontWeight: "800" }
                : {}
            }
          >
            Random
          </button>
        </span>
      </div>
      )}
      <div style={{ margin: "20px", display: "flex", flexDirection: "column" }}>
        {vitalsName === 'blood_pressure' && (<div style={{display:"flex",flexDirection:"column"}}><div style={{ display: "flex", alignItems: "center" }}>
          <input
            type="text"
             value={updateSystolic}
             onKeyDown={handleKeyPress}
            className="textFormFieldGraph"
            placeholder="Systolic"
            onChange={SystolicValidator} 
            style={validationError?{borderColor:"red",marginBottom: "7px",paddingLeft: "15px",marginRight: "10px"}:{marginBottom: "7px",paddingLeft: "15px",marginRight: "10px"}}
          ></input>
          <p style={{ fontFamily: "Poppins", color: "#A6A6A6" }}>mmHg</p>
          </div>
         </div>)}
        {vitalsName === 'blood_pressure' && (<div style={{display:"flex",flexDirection:"column"}}><div style={{ display: "flex", alignItems: "center" }}>
          <input
            type="number"
            value={updateDiastolic}
            onKeyDown={handleKeyPress}
            className="textFormFieldGraph"
            placeholder="Diastolic"
            onChange={DiastolicValidator} 
            style={validationError1?{borderColor:"red",marginBottom: "7px",paddingLeft: "15px",marginRight: "10px"}:{marginBottom: "7px",paddingLeft: "15px",marginRight: "10px"}}
          ></input>
          <p style={{ fontFamily: "Poppins", color: "#A6A6A6" }}>mmHg</p>
        </div><div>{errorMsg && <p style={{color:"red",fontFamily:"Poppins"}}>Systolic levels should be between (40 - 400)</p>}
        {errorMsg1 && <p style={{color:"red",fontFamily:"Poppins"}}>Diastolic levels should be between (20 - 250)</p>}
         </div></div>)}
        {vitalsName === 'spo2' && (<div style={{display:"flex",flexDirection:"column"}}><div style={{ display: "flex", alignItems: "center" }}>

          <input
            type="number"
            value={updateSpo2}
            onKeyDown={handleKeyPress}
            className="textFormFieldGraph"
            placeholder="Enter the Spo2"
            onChange={spo2Validator} 
            style={validationError?{borderColor:"red",marginBottom: "7px",paddingLeft: "15px",marginRight: "10px"}:{marginBottom: "7px",paddingLeft: "15px",marginRight: "10px"}}
          ></input>
          <p style={{ fontFamily: "Poppins", color: "#A6A6A6" }}> %</p>
        </div><div>{errorMsg && <p style={{color:"red",fontFamily:"Poppins"}}>Spo2 levels should be between (60 - 100)</p>}
         </div></div>)}
        {vitalsName === 'pulse' && (<div style={{display:"flex",flexDirection:"column"}}>
          <div style={{ display: "flex", alignItems: "center" }}>
          <input
            type="number"
            value={updatepulse}
            onKeyDown={handleKeyPress}
            className="textFormFieldGraph"
            placeholder="Enter the Pulse"
            onChange={pulseValidator} 
            style={validationError?{borderColor:"red",marginBottom: "7px",paddingLeft: "15px",marginRight: "10px"}:{marginBottom: "7px",paddingLeft: "15px",marginRight: "10px"}}
          ></input>
          <p style={{ fontFamily: "Poppins", color: "#A6A6A6" }}> bpm</p>
        </div>
        <div>
        {errorMsg && <p style={{color:"red",fontFamily:"Poppins"}}>Pulse levels should be between (20 - 200)</p>}
         </div></div>)}
        {vitalsName === 'temperature' && ( <div style={{display:"flex",flexDirection:"column"}}> <div style={{ display: "flex", alignItems: "center" }}>
       
          <input
            type="number"
            value={updateTemp}
            onKeyDown={handleKeyPress}
            className="textFormFieldGraph"
            placeholder="Enter the Temperature"
            onChange={tempValidator} 
            style={validationError?{borderColor:"red",marginBottom: "7px",paddingLeft: "15px",marginRight: "10px"}:{marginBottom: "7px",paddingLeft: "15px",marginRight: "10px"}}
          ></input>
          <p style={{ fontFamily: "Poppins", color: "#A6A6A6" }}> &deg;C</p>
        </div>
        <div>
        {errorMsg && <p style={{color:"red",fontFamily:"Poppins"}}>Temeperature levels should be between (25 - 50)</p>}
         </div></div>
        )}
        {vitalsName === 'glucose' && (
        <div style={{display:"flex",flexDirection:"column"}}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            type="number"
            value={updateGlucose}
            onKeyDown={handleKeyPress}
            className="textFormFieldGraph"
            placeholder="Enter Blood Sugar"
            onChange={glucoseValidator} 
            style={validationError?{borderColor:"red",marginBottom: "7px",paddingLeft: "15px",marginRight: "10px"}:{marginBottom: "7px",paddingLeft: "15px",marginRight: "10px"}}
          ></input>
          <p style={{ fontFamily: "Poppins", color: "#A6A6A6" }}> mg/dL</p>
          
          </div>
          <div>
          {errorMsg && <p style={{color:"red",fontFamily:"Poppins"}}>Blood Sugar levels should be between (20 - 900)</p>}
           </div> 
        </div>
        
        )}

        {vitalsName === 'blood_pressure' ? (<button className="SaveBtn" onClick={UpdateVitalsBloodPressure}>
          Save
        </button>):(
          <button className="SaveBtn" onClick={UpdateVitals}>Save</button>
        )}
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        
      <Modal.Header closeButton>
          <Modal.Title style={{fontFamily:"Poppins"}}>Select a Date</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{height:"285px"}} className="row">
         <div style={{marginBottom:"2%"}} className="col-6">
         <b style={{fontFamily:"Poppins"}}>Select Start Date </b><br/>
         <DatePicker
                value={new Date(startDate)}
                onChange={(date) => {
                  handleFromDate(date);
                }}
                maxDate={new Date()}
              />
                    </div>
                    <div className="col-6">
                    <b style={{fontFamily:"Poppins"}}>Select End Date </b>
         
                    <DatePicker
                value={new Date(endDate)}
                onChange={(date) => {
                  handleToDate(date);
                }}
                maxDate={new Date()}
              />
        
                    </div> 
                      
         </div>
        </Modal.Body>
        <Modal.Footer>
        {/* <Button style={{background:"#007E85",fontFamily:"Poppins"}} >Close</Button> */}
          <Button style={{background:"#007E85",fontFamily:"Poppins"}} onClick={handleSetDateGraph}>Save</Button>
        </Modal.Footer>
       
      </Modal>
      </div>
      <div className="col-3 each-list-doc" style={{marginLeft:"2%"}}>
        <h3 style={{fontFamily:"Poppins"}}>Reports : </h3>
      </div>
    </div></>
  );
}

export default MyHealthVitalsGraph;
