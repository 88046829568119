import React, { useState, useEffect } from "react";
import "./cart.css";
import { Link, useNavigate, NavLink,useLocation } from "react-router-dom";
import axios from "axios";
import PatientNav from "./PatientNav";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import registerpat from "./assets/img/registerpat.png"
import horizonlogo from "./assets/img/horizonlogo.png"
import { APIURL } from "../../Global";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Modal,Form,ToggleButtonGroup,ToggleButton } from "react-bootstrap";
import { Button } from "react-bootstrap";
import DatePicker from "react-date-picker";
import LoadingOverlay from "react-loading-overlay";
import { Badge } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useAlert } from "react-alert";


 function CreateAcconuntMobile ({setShowNavbarAndFooter}){
    
    let navigate = useNavigate();
    const location = useLocation();
    const phone = location.state.phone;
    const [consent,setConsent]=useState(false);
    const otp = location.state.otp;
    const alert = useAlert();
    const [dob, setDob] = useState();
    const [,setFormattedDob] = useState("");
    let emaildata = localStorage.getItem("email");
    const [userDatas,setUserDatas]= useState({
        firstname: "",
        lastname:'',
    
        gender: "",
        dob: "",
        apt_no:"",
        country: "India",
        state: "",
     
        pincode:"",
       
        province:"",
        city:"",
        location:""

       
        // complaint:"",
        // medHistory:"",
        // famHistory:"",
      
       
       
    });

    const [error,setError]=useState({
   

      
       });

       const ConscentPopUp = (props) => {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h6 style={{ color: "green" }}>
             The Service Providers will have access to your Name,Age,Address,Mobile Number,Location <br/>
             Please click ok to confirm!

              </h6>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleConsent}>
                Ok
              </Button>
           
            </Modal.Footer>
          </Modal>
        );
      };

    const selectRegion= (val)=> {
        // let value1=e.target.value;
          setUserDatas(currentstate=>({
              ...currentstate,
                 state: val
               }));
        }

        const handleDOB=(date)=>{

            setDob(date);
          
          let dateUpdated= date;
            if(date!==null){
          const offset = date.getTimezoneOffset();
          
          dateUpdated = new Date(date.getTime() - (offset*60*1000))
          
          let DateExtracted = dateUpdated.toISOString().split('T')[0];
          
          /*let dateString= DateExtracted.toString(); */
          setFormattedDob(DateExtracted);
          
           setUserDatas(currentstate=>({
                      ...currentstate,
                      dob: DateExtracted
                    }));
          }
          }
    useEffect(() => { setShowNavbarAndFooter(false); return () => setShowNavbarAndFooter(true); }, [setShowNavbarAndFooter]);

    const handleChange=e=>{
        
        e.preventDefault();
        const val=e.target.value;
        const key=e.target.name;
        setUserDatas(currentstate=>({
          ...currentstate,
          [key]: val
        }));  
    
           
        
  }

  const handleConsent = () => {
     
    const tokenString = sessionStorage.getItem("usertoken1");
    let v2 = tokenString.replace(/["]+/g, "");
  
    axios
      .post(
        `${APIURL}/api/v1/account/patient-consent/`,
        {},
        {
          headers: {
            Authorization: "Token " + v2,
          },
        }
      )
      .then((res) => {
        
        if (res.status === 200) {
          navigate("/skippage1");
        }
        
      })
      .catch((error) => {
        // Handle error
        console.log(error);
      });
  }
  
  const handleGenderRadioButton=(e)=>{
    let genderOpted = e.target.value;
    setUserDatas(currentstate=>({
      ...currentstate,
      gender: genderOpted

    }))
   }

  const handleContinue= async (e)=>{
    

    e.preventDefault();

if(validate()){

   
  let user =  {
    first_name: userDatas.firstname,
    last_name: userDatas.lastname,
    signup_method: "mobile",
    gender: userDatas.gender,
    dob: userDatas.dob,
    country: "India",
    login_consent: true,
    is_doctor: false,
    src: "web",
    login_enabled: true,
    otp: otp,
    mobile_number: phone,
    code: '91',
    pincode:userDatas.pincode
  };
  
  if (userDatas.province) {
    user.province = userDatas.province;
  }
  if (userDatas.city) {
    user.city = userDatas.city;
  }
  if (userDatas.address) {
    user.address = userDatas.address;
  }
  if (userDatas.state) {
    user.state = userDatas.state;
  }
  if (userDatas.apt_no) {
    user.apt_no = userDatas.apt_no;
  }
  if (userDatas.location) {
    user.location = userDatas.location;
  }
  

    axios.post(`${APIURL}/api/v1/account/signup/`, user,)
       .then(res =>{
         
               
               if(res.data.status === "success"){
            
                   
        alert.success("registered successfully"  )

        sessionStorage.setItem("usertoken1", JSON.stringify(res.data.token));

        setConsent(true)
   
    // navigate("/skippage1")
               }        
               else {
   
           
   alert.error(res.data.message)
               }
                   
               })
        .catch(err =>{
   
       
     })
}


  }


    const validate= async()=>{
        let input = userDatas;
        let errors = {};
        let isValid = true;
      setError({});
    
    
        if (!input["firstname"] || input["firstname"]==="" ) {
          isValid = false;
          errors["firstname"] = "Please enter first name";
        }

        if (!input["pincode"] || input["pincode"]==="" ) {
          isValid = false;
          errors["pincode"] = "Please enter pincode";
        }

    
        if (!input["lastname"] || input["lastname"]==="" ) {
          isValid = false;
          errors["lastname"] = "Please enter last name";
        }
        
    
        if(typeof dob=="undefined" || dob==="" || dob=== null){
            isValid = false;
            errors["dob"] = "Please enter your date of birth";
          }
          //  if(typeof userDatas.gender=="undefined" ||  userDatas.gender==="" ||  userDatas.gender=== null){
          //   isValid = false;
          //   errors["gender"] = "Please select gender";
          // }
    
    
    
      
        // if (!input["state"] || input["state"]==="") {
        //   isValid = false;
        //   errors["state"] = "Please select a state";
        // }
    

        if (typeof input["firstname"] !== "undefined") {
            
          var pattern2 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/);
          if (pattern2.test(input["firstname"])) {
            isValid = false;
            errors["firstname"] = "Special Characters are not allowed";
          }
        }
    
        if (typeof input["lastname"] !== "undefined") {
            
          var pattern3 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/);
          if (pattern3.test(input["lastname"])) {
            isValid = false;
            errors["lastname"] = "Special Characters are not allowed";
          }
        }


    
    
    
         setError(currentstate=>({
              ...currentstate,
              ...errors
            }));
        
    
        return isValid;
    }
return(
 <>
   <section
        className="products-area ptb-70"
        style={{ marginTop: "-3%"  }}
      >
     <div className="container" style={{borderRadius:"2%"}}>
            <div class="row" style={{borderRadius:"2%"}}>
              <div class="col-lg-8 col-md-6" >
               < div style={{  boxShadow: "0 0 10px rgb(30%, 0, 0)"}}>
      <div className="logoalign" style={{ backgroundImage: `url(${horizonlogo})`,backgroundSize: "cover" }}>
        </div>
        <h5 className="createaccountfont" style={{marginTop:"1%"}}> Create Account</h5>

        <form className="">

        <div className="hosp-patient-form1" style={{marginTop:"-1%" ,width:"80%",boxShadow: "none",marginLeft:"-4%", }}>

        <div className='form-align'>
        <div className="item item-left-row"> 
                                        <label className="" style={{fontFamily: 'Poppins'}}>*First Name: </label>
                                        <input value={userDatas.firstname} className="inputcontrolpat" type="text"  onChange={handleChange} name="firstname"  axLength='30'
                                        />  

                                         { error.firstname ? <div className="error-validation-msg">{error.firstname}</div> : null}
                                         </div>

                                         <div className="item item-left-row"> 
                                        <label className="" style={{fontFamily: 'Poppins'}}>*Last Name: </label> <input className="inputcontrolpat" value={userDatas.lastname}  onChange={handleChange} type="text" name="lastname"  maxLength='30'
                                        />  
                                          { error.lastname ? <div className="error-validation-msg">{error.lastname}</div> : null}
                                            </div>

                                            <div className="item item-left-row"> 
                                           
                                       
                                       
                                           <label >* Date of Birth:</label> 
                                           <div style={{alignSelf:"end"}} className="form-control  inputcontrolpat">
                                           <div style={{marginTop:"2%",marginLeft:"15%"}}>  
                                              <DatePicker
                                                    onChange={date=>{handleDOB(date)}}
                                                   
                                                    maxDate = {new Date()}
                                                    value={dob}
                                                    
                                               /> 
                                                 {error.dob?  <div className="error-validation-msg"> {error.dob}</div> : null}
                                   </div>
                                   </div> 
                                   </div> 
                                   <div className="item item-left-row"> 
                                   <label >*Gender</label> 
                                   <div className="form-control  inputcontrolpat" style={{alignSelf:"end" ,marginTop:"1%"}} >
                                          <div style={{marginTop:"4%"}}>                     
                                                             
                                                                 <input 
                                                                    type="radio" 
                                                                    id="male" 
                                                                    name="gender" 
                                                                    value="male"
                                                                    checked={userDatas.gender === "male"}
                                                                    onClick={handleGenderRadioButton}
                                                                   
                                                                        /> 
                                                                 <label htmlFor="male">Male</label>
                                                                 <span>&nbsp;&nbsp;&nbsp;</span>
                                                                 <input  
                                                                   type="radio"
                                                                   id="female"
                                                                    name="gender" 
                                                                    value="female"
                                                                    checked={userDatas.gender === "female"}
                                                                   onClick={handleGenderRadioButton} />
                          
                                                                 <label htmlFor="female">Female</label>
                                                                 <span>&nbsp;&nbsp;&nbsp;</span>
                                                                 <input 
                                                                     type="radio"
                                                                     id="others" 
                                                                     name="gender"
                                                                     value="others"
                                                                     checked={userDatas.gender === "others"}
                                                                   onClick={handleGenderRadioButton} 
                                                                        />
                                                                 <label htmlFor="others">Others</label>
                          
                                                                {/* {error.gender ?  <div className="error-validation-msg"> {error.gender}</div> : null} */}
                                                               </div>
                                                               </div>
                                        </div>
                          

                                            <div className="item item-left-row"> 
                                        <label className="" style={{fontFamily: 'Poppins'}}> Door Name/No: </label>
                                        <input className="inputcontrolpat" type="text"value={userDatas.apt_no} name="apt_no"  onChange={handleChange} placeholder="door name/no"
                                        />  
                                        {/* {error.cpr ? <div className="error-validation-msg"> {error.cpr}</div>  : null} */}
                                         </div>
                                         <div className="item item-left-row"> 
                                        <label className="" style={{fontFamily: 'Poppins'}}> Address line2: </label>
                                        <input className="inputcontrolpat" type="text"value={userDatas.province} name="province"   onChange={handleChange} placeholder="province" 
                                        />  
                                        {/* {error.cpr ? <div className="error-validation-msg"> {error.cpr}</div>  : null} */}
                                         </div>
                                         <div className="item item-left-row"> 
                                        <label className="" style={{fontFamily: 'Poppins'}}> City: </label>
                                        <input className="inputcontrolpat" value={userDatas.city} type="text" name="city"  onChange={handleChange}  placeholder="city"  
                                        />  
                                        {/* {error.file ? <div className="error-validation-msg"> {error.file}</div>  : null} */}
                                         </div>
                                         <div className="region-selector item-left-row">
                                         <label className="" style={{fontFamily: 'Poppins'}}>State:  </label>
                                            <RegionDropdown
                                              country={"India"}
                                              value={userDatas.state}
                                              
                                              style={{background: "#EEEEEE",
                                                border: "1px solid #6BCBB8",
                                                borderRadius: "8.07781px"}}
                                              onChange={(val) => selectRegion(val)} 
                                              />
                                          {/* {error.state ? <div style={{zIndex:'auto'}} className="error-validation-msg"> {error.state}</div> : null} */}
                                           
                                           </div>

                                         <div className="item item-left-row"> 
                                        <label className="" style={{fontFamily: 'Poppins'}}>*Pincode: </label>
                                        <input className="inputcontrolpat"value={userDatas.pincode}type="text" name="pincode"   onChange={handleChange}  maxLength='10'
                                        />  
                                        {error.pincode ? <div className="error-validation-msg"> {error.pincode}</div>  : null}
                                         </div>

                                         <div className="country-selector item-left-row">
                                         <label className="" style={{fontFamily: 'Poppins'}}> Country: </label>
                                            <input  className="inputcontrolpat"
                                              value={"India"}
                                               disabled
                                           
                                               />
                                           
                                        </div>
                                       
                                            <button   onClick={handleContinue} className="getstart">Get Started</button>



            </div>
            </div>
            </form>
</div>
</div>
<div class="col-lg-4 col-md-6" >
<div className="rightcreate" style={{ backgroundImage: `url(${registerpat})` ,backgroundSize: "cover" ,marginTop:"25%"}}>
    </div>
    </div>
    </div>
    </div>

    {consent ? (
          <ConscentPopUp
            show={consent}
          
            onHide={() => {
              setConsent(false);
            }}
          />
        ) : null}
    </section>

    </>
)

}
export default CreateAcconuntMobile