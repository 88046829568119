import React, { useState, useContext, useEffect } from "react";
import {
  Link,
  useNavigate,
  NavLink,
  useLocation,
  Navigate,
} from "react-router-dom";
import flag from "../assets/img/flag/India.png"
import axios from "axios";
import DatePicker from 'react-date-picker';
import { APIURL } from "../../../Global";
import classes from "../favorite.module.css";
import { FaRegUser } from "react-icons/fa";
import SavedAddressPage from "./SavedAddressPage";
import PhoneInput from "react-phone-input-2";
import Addresspage from "../Addresspage";
import { useAlert } from "react-alert";
// import { SavedAddress } from "./Context";
// import UserProfile from "./UserProfile";
function MyAccountPage() {
  const alert = useAlert();
  const [accountState, setaccountState] = useState(true);
  const [profile, setProfile] = useState({})
  const [image, setImage] = useState("")
  const [contactNumber, setContactNumber] = useState("")
  const [code, setCode] = useState()
  const [countryCode, setCountryCode] = useState("in")
  const [isUpdate, setIsUpdate] = useState(false);
  const [isUpdateImg, setIsUpdateImg] = useState(false);
  const [refresh, setRefresh] = useState(false)
  const [change, setChange] = useState(false);
  useEffect(() => {
    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')



    axios.get(`${APIURL}/api/v1/account/profile/`, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {


        if (res.data.status === "success") {
          setProfile(res.data.data)
          setContactNumber(res.data.data.mobile_number + "")
          setIsUpdate(false)
          setImage(res.data.data.photo)

        } else {


        }

      })
      .catch(err => {


      })

  }, [refresh])
  const removePicPreview = (e) => {
    e.preventDefault();
    setImage("")
    setProfile((currentData) => ({
      ...currentData,
      imageUrl: "",
    }));
  };
  const handleImageUpload = (event) => {
    const selectedImage = event.target.files[0];
    const imageUrl = URL.createObjectURL(selectedImage);
    console.log("imageUrl", imageUrl)
    // Update the data state with the selected image URL
    setImage(selectedImage)
    setProfile((currentData) => ({
      ...currentData,
      imageUrl: imageUrl,
    }));
  };
  const handleTitle = (e) => {
    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;
    setProfile(current => ({
      ...current, [key]: val
    }))
  }
  const handlePhoneInput = (value, data) => {
    setChange(true)
    setCode(data.dialCode);

    setContactNumber(value);
    setCountryCode(data.countryCode)


  }
  const handleGenderRadioButton = (e) => {
    let genderOpted = e.target.value;
    setProfile(currentstate => ({
      ...currentstate,
      gender: genderOpted

    }))
  }
  const submitHandle = async () => {

    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')

    let fd = new FormData()



    if (image !== null) {
      fd.append('photo', image)
    }
    await axios.put(`${APIURL}/api/v1/account/profile/`, fd, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {



        if (res.data.status === "success") {

          alert.success("Updated Successully")
          setIsUpdateImg(false)
          setRefresh(!refresh)


        } else {

          alert.error("error in submission")

        }

      })



  }

  const Submit = (e) => {
    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')
    let data = {}
    if (change) {
      data = {
        code: code,
        mobile_number: contactNumber,
        first_name: profile.first_name,
        apt_no: profile.apt_no,
        location: profile.province,
        last_name: profile.last_nameame,
        pincode: profile.pincode,
        city: profile.city,
        state: profile.state,
        province:  profile.province,
        country: profile.country,
        gender: profile.gender,
        email: profile.email,
         dob:profile.dob
      };
    } else {
      data = {
        first_name: profile.first_name,
        apt_no: profile.apt_no,
        location: profile.province,
        last_name: profile.last_name,
        pincode: profile.pincode,
        city: profile.city,
        state: profile.state,
        province: profile.province,
        country: profile.country,
        gender: profile.gender,
        email: profile.email,
        dob:profile.dob
      }
    }
    if (profile.first_name === "") {
      alert.error("please enter first name")
    }
    else if (profile.last_name === "") {
      alert.error("please enter last name")
    }


    else if (profile.pincode === "") {
      alert.error("please enter pincode")
    }

    else if (profile.gender === "") {
      alert.error("please select gender")
    }

    else {
      axios.put(`${APIURL}/api/v1/account/profile/`, data, {
        headers: {

          "Authorization": 'Token ' + str
        }
      })
        .then(res => {


          if (res.data.status === "success") {
            alert.success("Updated Successfully")
            setRefresh(!refresh)
            setChange(false)

          } else {


          }

        })
    }

  }
  const handleDOB=(date)=>{

  let dateUpdated= date;
    if(date!==null){
  const offset = date.getTimezoneOffset();
  
  dateUpdated = new Date(date.getTime() - (offset*60*1000))
  
  let DateExtracted = dateUpdated.toISOString().split('T')[0];
  

  
   setProfile(currentstate=>({
              ...currentstate,
              dob: DateExtracted
            }));
  }
  }

  return (

    <div >
      <div style={{ marginLeft: "25%" }}>
        <button onClick={() => { setaccountState(true) }}
          className={accountState ? "book-appointment-btn" : "book-appointment-btn-n"}
          style={{ width: "25%", padding: "1%" }}>Profile</button>
        <button
          className={accountState ? "book-appointment-btn-n" : "book-appointment-btn"}
          style={{ width: "25%", padding: "1%", marginLeft: "2%" }}
          onClick={() => { setaccountState(false) }}
        >
          Saved Addresses
        </button>
      </div>
      <div style={{ marginTop: "3%" }}>
        {accountState ? <div className="row" >
          <div className="col-6">
            <div className='grid-prop'>
              {image ? (<>{isUpdateImg && <div onClick={(e) => { removePicPreview(e) }}>
                <i
                  style={{ position: "absolute" }}
                  className="fas fa-times-circle"
                ></i>
              </div>}
                <img src={image} alt="Profile Pic" style={{ width: '135px', marginTop: "3%", maxHeight: '130px', borderRadius: '5px', boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)' }} />
              </>
              ) : <div style={{ width: '140px', marginTop: "3%", maxHeight: '130px', borderRadius: '5px', boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)' }}>

                <input

                  name="img1"
                  type="file"
                  className="custom-file-input"
                  id="validatedCustomFile1"
                  onChange={handleImageUpload}
                />

                <label style={{ width: "100%" }} className="" htmlFor="validatedCustomFile1">


                  <i style={{ marginLeft: "36%", marginTop: "17%", color: "#ADADAD", }} className="fa fa-picture-o fa-2x"></i><br />
                  <span style={{ marginLeft: "35%", color: "#ADADAD", fontSize: "10px" }}></span>

                </label>

              </div>


              }
              <div style={{ marginTop: "1%" }}>
                <button style={{ width: "135px", height: "30px", borderRadius: "0px" }} className="probutton" onClick={() => { !isUpdateImg ? setIsUpdateImg(true) : submitHandle() }} >{isUpdateImg ? "Update" : "Edit"}</button></div>
            </div>

            <div className=' grid-prop'>
              <span className="atrname" style={{ marginLeft: "3%" }}>First Name*</span><br />
              <input disabled={!isUpdate} type='text' className=' prof-input' name='first_name' value={profile.first_name} onChange={handleTitle} />
            </div>
            <div className=' grid-prop'>
              <span className="atrname" style={{ marginLeft: "3%" }}>Last Name*</span><br />
              <input disabled={!isUpdate} type='text' className=' prof-input' name='last_name' value={profile.last_name} onChange={handleTitle} />
            </div>
            <div className=' grid-prop'>
              <span className="atrname" style={{ marginLeft: "3%" }}>Date of Birth*</span><br />
             {!isUpdate? <input disabled={!isUpdate} type='text' className=' prof-input' name='dob' value={profile.dob} onChange={handleTitle} />:
               <DatePicker
               onChange={date=>{handleDOB(date)}}

               maxDate = {new Date()}
               value={new Date(profile.dob)}
               
          /> }
            </div>
            <div style={{ margin: "17px" }}>
              <span className="atrname" style={{ marginRight: "17px" }}>
                <input  disabled={!isUpdate} type="radio" value="male" name="gender" checked={profile.gender === "male"}
                  onClick={handleGenderRadioButton} /> Male
              </span>
              <span className="atrname" style={{ marginRight: "17px" }}>
                <input  disabled={!isUpdate} type="radio" value="female" name="gender" checked={profile.gender === "female"}
                  onClick={handleGenderRadioButton} /> Female
              </span>
              <span className="atrname">
                <input disabled={!isUpdate} type="radio" value="other" name="gender" checked={profile.gender === "other"}
                  onClick={handleGenderRadioButton} />  Others
              </span>
            </div>
            <div className=' grid-prop'>
              <span className="atrname">Phone Number</span>
              <PhoneInput
                disabled={!isUpdate}
                // countryCodeEditable={false}
                inputProps={{}}
                style={{ width: "90%" }}
                onlyCountries={['in']}
                country={countryCode}
                onChange={handlePhoneInput}
                value={contactNumber}
                dropdownStyle={{ display: "none" }}
                disableCountryCode={true}
                disableDropdown={true}
              // onBlur={handleCheckExistingPhone}
              />


              {/* <input type='text' placeholder="MobileNumber" className='gridinputprop' name='phoneNumber' value={addresNew.phoneNumber} onChange={handleChange} /> */}

            </div>
          </div>
          <div style={{ marginTop: "3%" }} className="col-6">
            <div className=' grid-prop'>
              <span className="atrname" style={{ marginLeft: "3%" }}>Email*</span><br />
              <input disabled={!isUpdate} type='text' className=' prof-input' name='email' value={profile.email} onChange={handleTitle} />
            </div>
            <div className=' grid-prop'>
              <span style={{ marginLeft: "3%" }} className="atrname">House no/House Name</span><br />
              <input disabled={!isUpdate} type='text' className=' prof-input' name='apt_no' value={profile.apt_no} onChange={handleTitle} />
            </div>
            <div className=' grid-prop'>
              <span className="atrname" style={{ marginLeft: "3%" }}> Location</span><br />
              <input disabled={!isUpdate} type='text' className=' prof-input' name='province' value={profile.province} onChange={handleTitle} />
            </div>
            <div style={{ display: "flex" }} >
              <div className=' grid-prop col-6'>
                <span className="atrname" style={{ marginLeft: "3%" }}>City</span><br />
                <input disabled={!isUpdate} type='text' className=' prof-input' name='city' value={profile.city} onChange={handleTitle} />
              </div>
              <div className=' grid-prop col-6'>
                <span className="atrname" style={{ marginLeft: "-8px" }} >State</span><br />
                <input disabled={!isUpdate} type='text' style={{ marginLeft: "-18px" }} className=' prof-input' name='state' value={profile.state} onChange={handleTitle} />
              </div>
            </div>
            <div style={{ display: "flex" }} >
              <div className=' grid-prop col-10'>
                <span className="atrname" style={{ marginLeft: "3%" }}>Zip Code</span><br />
                <input disabled={!isUpdate} type='text' className=' prof-input' name='pincode' value={profile.pincode} onChange={handleTitle} />
              </div>
              <div className=' grid-prop col-2'>
                <span className="atrname" style={{ marginLeft: "-35px" }}>Country</span><br />
                <img className=' prof-input' style={{ marginLeft: "-32px", height: "32px" }} src={flag} alt="image" />
              </div>
            </div><br />
            <button onClick={() => { !isUpdate ? setIsUpdate(true) : Submit() }} style={{ float: "right", marginRight: "9%" }} className="probutton">{isUpdate ? "Update" : "Edit"}</button>
          </div>
        </div> : <div style={{ marginTop: "5%", marginLeft: "5%" }}> <Addresspage from={"profile"} /></div>}

      </div>

    </div>

  );


}

export default MyAccountPage;






