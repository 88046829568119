import React, { useEffect, useState } from "react";
import axios from "axios";
import { APIURL } from "../../Global";
import PhoneInput from "react-phone-input-2";
import { useAlert } from "react-alert";
import flag from "./assets/img/flag/India.png"
function Addresspage(props) {
    const alert = useAlert();
    const [change, setChange] = useState(false)
    const [id, setId] = useState()
    const [refresh, setRefresh] = useState(false)
    const [addressEdit, setAddressEdit] = useState(false);
    const [addressList, setAddressList] = useState([])
    const [addres, setAddress] = useState([])
    const [addressadd, setAddressAdd] = useState(false);
    const [isDefault, setDefault] = useState(false);
    const [contactNumber, setContactNumber] = useState("")
    const [code, setCode] = useState()
    const [countryCode, setCountryCode] = useState("in")
    const [addresNew, setAddressNew] = useState({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        landmark: "",
        pincode: "",
        state: "",
        house: "",
        city: "",
        country: "",
        province:""

    })
    const handleChange = (e) => {
        e.preventDefault();
        const val = e.target.value;
        const key = e.target.name;
        setAddressNew(current => ({
            ...current, [key]: val
        }))
    }
    const handleUpdate = () => {


        const tokenStr = sessionStorage.getItem("usertoken");

        let v2 = tokenStr.replace(/["]+/g, "");
        let dataTosend = {}
        if (change) {
            dataTosend = {
                apt_no: addresNew.house,
                code: code,
                first_name: addresNew.firstName,
                is_default: isDefault,
                landmark: addresNew.landmark,
                last_name: addresNew.lastName,
                mobile_number: contactNumber,
                pincode: addresNew.pincode,
                city: addresNew.city,
                state: addresNew.state,
                province: addresNew.province,
                country: "INDIA"

            };
        } else {
            dataTosend = {
                apt_no: addresNew.house,
                is_default: isDefault,
                first_name: addresNew.firstName,
                landmark: addresNew.landmark,
                last_name: addresNew.lastName,
                pincode: addresNew.pincode,
                city: addresNew.city,
                state: addresNew.state,
                province: addresNew.province,
                country: "INDIA"

            };
        }
            if (addresNew.firstName === ""||addresNew.firstName === null) {
                alert.error("please enter first name")
            }
            else if (addresNew.lastName === ""||addresNew.lastName === null) {
                alert.error("please enter last name")
            }
            else if (contactNumber === ""||contactNumber === null) {
                alert.error("please enter mobile number")
            }
            else if (addresNew.landmark === ""||addresNew.landmark === null) {
                alert.error("please enter landmark")
            }
            else if (addresNew.pincode === ""||addresNew.pincode === null) {
                alert.error("please enter pincode")
            }
            else if (addresNew.state === ""||addresNew.state === null) {
                alert.error("please enter state")
            }
            else if (addresNew.house === ""||addresNew.house === null) {
                alert.error("please enter house no/house name")
            }
            else if (addresNew.city === ""||addresNew.city === null) {
                alert.error("please enter city")
            }
            
      
        else {
            axios
                .put(`${APIURL}/api/v1/patient/shipping-address-detail/${id}/ `, dataTosend, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Token " + v2,
                    },
                })
                .then((res) => {
                    if (res.data.status === "success") {
                        setRefresh(!refresh);
                        setAddressAdd(false);
                        setAddressEdit(false)
                        setAddressNew({
                            firstName: "",
                            lastName: "",
                            phoneNumber: "",
                            landmark: "",
                            pincode: "",
                            state: "",
                            house: "",
                            city: "",
                            country: "",
                            province:""
                        })
                        setChange(false)
                        setCountryCode("")
                        setContactNumber("")
                        setCode('')


                    } else {
                        alert.error(res.data.message);
                    }
                })
                .catch((err) => {
                    // setErrorMsg("Error in submission");
                });
        }
    };
    const handleActions = (key) => {


        const tokenStr = sessionStorage.getItem("usertoken");

        let v2 = tokenStr.replace(/["]+/g, "");
        let dataTosend = {}
        if (key === "def") {
            dataTosend = {
                is_default: true

            };
        }
        else {
            dataTosend = {
                is_deleted: true

            }
        }
        axios
            .put(`${APIURL}/api/v1/patient/shipping-address-detail/${id}/ `, dataTosend, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Token " + v2,
                },
            })
            .then((res) => {
                if (res.data.status === "success") {
                    alert.success(res.data.message);
                    setRefresh(!refresh);
                    setAddressAdd(false);
                    setAddressNew({
                        firstName: "",
                        lastName: "",
                        phoneNumber: "",
                        landmark: "",
                        pincode: "",
                        state: "",
                        house: "",
                        city: "",
                        country: "",
                        province:""
                    })
                    setCountryCode("")
                    setContactNumber("")
                    setCode('')


                } else {
                    alert.error(res.data.message);
                }
            })
            .catch((err) => {
                // setErrorMsg("Error in submission");
            });

    };

    const handleProceed = () => {


        const tokenStr = sessionStorage.getItem("usertoken");

        let v2 = tokenStr.replace(/["]+/g, "");

        const dataTosend = {
            apt_no: addresNew.house,
            code: code,
            first_name: addresNew.firstName,
            is_default: isDefault,
            landmark: addresNew.landmark,
            last_name: addresNew.lastName,
            mobile_number: contactNumber,
            pincode: addresNew.pincode,
            city: addresNew.city,
            state: addresNew.state,
            province: addresNew.province,
            country: "INDIA"

        };
        if (addresNew.firstName === ""||addresNew.firstName === null) {
            alert.error("please enter first name")
        }
        else if (addresNew.lastName === ""||addresNew.lastName === null) {
            alert.error("please enter last name")
        }
        else if (contactNumber === ""||contactNumber === null) {
            alert.error("please enter mobile number")
        }
        else if (addresNew.landmark === ""||addresNew.landmark === null) {
            alert.error("please enter landmark")
        }
        else if (addresNew.pincode === ""||addresNew.pincode === null) {
            alert.error("please enter pincode")
        }
        else if (addresNew.state === ""||addresNew.state === null) {
            alert.error("please enter state")
        }
        else if (addresNew.house === ""||addresNew.house === null) {
            alert.error("please enter house no/house name")
        }
        else if (addresNew.city === ""||addresNew.city === null) {
            alert.error("please enter city")
        }
        
        else {
            axios
                .post(`${APIURL}/api/v1/patient/shipping-address/ `, dataTosend, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Token " + v2,
                    },
                })
                .then((res) => {
                    if (res.data.status === "success") {
                        setRefresh(!refresh);
                        setAddressAdd(false);
                        setAddressNew({
                            firstName: "",
                            lastName: "",
                            phoneNumber: "",
                            landmark: "",
                            pincode: "",
                            state: "",
                            house: "",
                            city: "",
                            country: "",
                            province:""
                        })
                        setCountryCode("")
                        setContactNumber("")
                        setCode('')


                    } else {
                        alert.error(res.data.message);
                    }
                })
                .catch((err) => {
                    // setErrorMsg("Error in submission");
                });
        }

    };

    useEffect(() => {
        const tokenString = sessionStorage.getItem("usertoken");

        let v2 = tokenString.replace(/["]+/g, "");
        axios
            .get(`${APIURL}/api/v1/patient/shipping-address/ `, {
                headers: { Authorization: "Token " + v2 },
            })
            .then((res) => {

                setAddressList(res.data.message);
                const filteredData = res.data.message.filter(x => {
                    return x.is_default === true
                })
                setAddress(filteredData)
            })
            .catch((err) => { });
    }, [refresh]);

    const handleChecked = () => {
        setDefault(!isDefault);
    };
    const handleEdit = (item) => {
        setAddressNew({
            firstName: item.first_name,
            lastName: item.last_name,
            landmark: item.landmark,
            pincode: item.pincode,
            state: item.state,
            house: item.apt_no,
            city: item.city,
            province:item.province,
            country: "INDIA",
        })
        setDefault(item.is_default)
        setId(item.id)
        setContactNumber(item.mobile_number + "");
        setAddressEdit(true)
        setAddressAdd(true)
    };
    const handlePhoneInput = (value, data) => {
        setChange(true)
        setCode(data.dialCode);

        setContactNumber(value);
        setCountryCode(data.countryCode)
        // setChangePh(true)

    }


    return (
        <>
            <div className="form-button productsQuickView" id="productsQuickView" style={{ marginTop: "-58px", }}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content ">
                        <div className="row " style={{ marginTop:"3%"}}>
                            <div className="addresspart col-5" >

                                {addres.length > 0 ? <>{!props.from && <h5> Select Address </h5>}


                                    <div
                                        className="shipping "
                                        style={{ border: "2px solid #6BCBB8",  }}
                                    >

                                        <h6
                                            style={{
                                                fontSize: "15px",

                                                color: "#6F6F6F",

                                            }}
                                        > {" "}
                                            <span 
                                            // onClick={() => { props.onSuccess(addres[0]) }}
                                            ><span> {addres[0].first_name}{" "}{addres[0].last_name}</span><br />
                                                {addres[0].apt_no !== null && <span>{addres[0].apt_no},</span>}
                                                {addres[0].landmark !== null && <span>{" "}{addres[0].landmark},</span>}
                                                {addres[0].city !== null && <span>{" "}{addres[0].city},</span>}
                                                {addres[0].province !== null && <span>{" "}{addres[0].province},</span>}
                                                {addres[0].pincode !== null && <span>{" "}{addres[0].pincode},</span>}
                                                {addres[0].state !== null && <span>{" "}{addres[0].state}</span>}<br />
                                                {addres[0].mobile_number !== null && <span>Mobile:{addres[0].mobile_number}</span>}
                                            </span>
                                            <br />

                                            <i
                                                class="fa fa-pencil-square"
                                                onClick={() => {
                                                    handleEdit(addres[0])
                                                }}

                                                style={{ color: "#008ea7a6", marginLeft: "78%" }}
                                                aria-hidden="true"
                                            ></i>
                                            <i
                                                class="fa fa-trash"
                                                onClick={() => {
                                                    setId(addres[0].id)
                                                    handleActions("del")
                                                }}
                                                style={{ color: "red", marginLeft: "2%" }}
                                                aria-hidden="true"
                                            ></i>

                                        </h6>


                                    </div></> : ""}
                                {addressList.map((item) => {
                                    return (<>
                                        {!item.is_default && <div
                                            //    onClick={() => { props.onSuccess(item) }}
                                            className="shipping"
                                            style={{  }}
                                        >

                                            <h6
                                                style={{
                                                    fontSize: "15px",

                                                    color: "#6F6F6F",
                                                }}
                                            >
                                                <span
                                                //  onClick={() => { props.onSuccess(item) }}
                                                >
                                                    <span> {item.first_name}{" "}{item.last_name}</span><br />
                                                    {item.apt_no !== null && <span>{item.apt_no},</span>}
                                                    {item.landmark !== null && <span>{" "}{item.landmark},</span>}
                                                    {item.city !== null && <span>{" "}{item.city},</span>}
                                                    {item.province !== null && <span>{" "}{item.province},</span>}
                                                    {item.pincode !== null && <span>{" "}{item.pincode},</span>}
                                                    {item.state !== null && <span>{" "}{item.state}</span>}<br />
                                                    {item.mobile_number !== null && <span>Mobile:{item.mobile_number}</span>}
                                                </span><br />
                                                <buttin
                                                    className="def-button"
                                                    onClick={() => {
                                                        setId(item.id)
                                                        handleActions("def")
                                                    }}
                                                    style={{ color: "#F0A639", marginLeft: "50%" }}>
                                                    Set as Default
                                                </buttin>
                                                <i

                                                    class="fa fa-pencil-square"
                                                    onClick={() => {
                                                        handleEdit(item)
                                                    }}
                                                    style={{ color: "#008ea7a6", marginLeft: "2%" }}
                                                    aria-hidden="true"
                                                ></i>

                                                <i
                                                    class="fa fa-trash"
                                                    onClick={() => {
                                                        setId(item.id)
                                                        handleActions("del")
                                                    }}
                                                    style={{ color: "red", marginLeft: "2%" }}
                                                    aria-hidden="true"
                                                ></i>
                                            </h6>

                                        </div>}<br /></>

                                    )
                                })}
 {!addressadd && <div><button  className={props.from ? 'probutton' : 'cons-button'} onClick={() => { setAddressNew({
                            firstName: "",
                            lastName: "",
                            phoneNumber: "",
                            landmark: "",
                            pincode: "",
                            state: "",
                            house: "",
                            city: "",
                            country: ""
                        });setAddressAdd(true)}}>Add New </button><br /></div>}
                            </div>

                           
                            <div className="col-7">
                            {addressadd ? <div className="addAddress" style={{ marginLeft: '3%' ,}}>
                                <button
                                    style={{ backgroundColor: "white", marginLeft: "90%" }}
                                    variant="secondary"
                                    onClick={() => { setAddressAdd(false) }}
                                >
                                    <i
                                        class="fa fa-times fa-lg"
                                        style={{ color: "#1269B9" }}
                                        aria-hidden="true"
                                    ></i>
                                </button>
                                <div className="row" style={{ marginTop: "2%", display: "flex" }}>
                                    <div className="col-6">
                                        <span className="atrname" >First Name</span>
                                        <input type='text' className='gridinputprop ' name='firstName' value={addresNew.firstName} onChange={handleChange} />
                                    </div>

                                    <div className="col-6"><span className="atrname">Last Name</span>
                                        <input type='text' className='gridinputprop' name='lastName' value={addresNew.lastName} onChange={handleChange} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6"><span className="atrname">House no/House Name</span><input placeholder="" type='text' className='gridinputprop' name='house' value={addresNew.house} onChange={handleChange} /></div>
                                    <div className="col-6"><span className="atrname">Landmark</span><input type='text' placeholder="" className='gridinputprop' name='landmark' value={addresNew.landmark} onChange={handleChange} /></div></div>
                                <div className="row"> 
                                <div className="col-6"><span className="atrname">Location</span><input placeholder="" type='text' className='gridinputprop' name='province' value={addresNew.province} onChange={handleChange} /></div>
                                <div className="col-6"><span className="atrname">City</span><input placeholder="" type='text' className='gridinputprop' name='city' value={addresNew.city} onChange={handleChange} /></div></div>
                                <div className="row">
                                    <div className="col-6"><span className="atrname">State</span>
                                        <input type='text' placeholder="" className='gridinputprop' name='state' value={addresNew.state} onChange={handleChange} /></div>
                                    <div className="col-6"><span className="atrname">Pincode</span><input placeholder="" type='text' className='gridinputprop' name='pincode' value={addresNew.pincode} onChange={handleChange} /></div></div>
                                <div className="row"><div className="col-2"><span className="atrname"style={{ marginLeft: "12px" }}>Country</span> <img className=' gridinputprop' style={{ marginLeft: "15px",marginTop:"10%" }} src={flag} alt="image" /></div>

                               <div style={{ marginLeft: "27px" }}className="col-9"> <span className="atrname">Phone Number</span>
                                <div>
                                    <PhoneInput
                                        inputProps={{}}
                                        onlyCountries={['in']}
                                        country={countryCode}
                                        onChange={handlePhoneInput}
                                        value={contactNumber}
                                        dropdownStyle={{ display: "none" }}
                                        disableCountryCode={true}
                                        disableDropdown={true}
                                    // onBlur={handleCheckExistingPhone}
                                    />

                                    {/* <input type='text' placeholder="MobileNumber" className='gridinputprop' name='phoneNumber' value={addresNew.phoneNumber} onChange={handleChange} /> */}
                                </div></div></div>
                                <div className="gridinputprop" style={{ border: "none" }}> {!addressEdit && <><input type="checkbox"
                                    id="returnable"
                                    name="returnable"
                                    value={isDefault}
                                    onChange={handleChecked}

                                />
                                    <label className="atrname" style={{ fontSize: "small" }}>&nbsp;Keep as default</label></>}
                                   


                            </div>
                            <button style={{ marginLeft: "62%" }} onClick={addressEdit ? handleUpdate : handleProceed} className='cons-button'>{addressEdit ? "Update" : " Save"}</button></div>
                                : ""}
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </>

    )
}
export default Addresspage