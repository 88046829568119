import React, { useContext, useState, useEffect } from "react";
import "./favorite.css";
import { FaRegUser } from "react-icons/fa";
import { BsHeartPulse, BsFillBagHeartFill, BsTruck } from "react-icons/bs";
import MyAccountPage from "./MyAccountPage";
import MyHealthPage from "./MyHealthPage";
import MyFavourites from "./MyFavourites";
import SavedAddressPage from "./SavedAddressPage";
//  import MyFavourites from "../MyFavourites";
import PatientNav from "../PatientNav";
import MyHealthVitals from "./MyHealthVitals";
import MyHealthPhysique from "./MyHealthPhysique";
import MyOrders from "./MyOrders";
import MyHealthVitalsGraph from "./MyHealthVitalsGraph";
import {MyProfileDetails} from "../Context";
function UserProfile({ setShowNavbarAndFooter }) {
  const { profileOption, setProfileOption} = useContext(MyProfileDetails);
  const [active, setActive] = useState("MyAccount");
  //const { savedAddrPage, setSavedAddrPage} = useContext(SavedAddress);
  //let navigate = useNavigate();
  useEffect(() => {
    setShowNavbarAndFooter(false);
    return () => setShowNavbarAndFooter(true);
  }, [setShowNavbarAndFooter]);
  useEffect(() =>{
    setActive(profileOption);
  },[profileOption]);
  return (
    <>
      <PatientNav />
      <br />
      <br /><br />
      <div className="row " style={{ padding: "2%" }}>
        <div className="col-3">
          <div className="each-list-doc">
            <div className="userProfileh4">
              <FaRegUser style={active === 'MyAccount' ? { fontSize: "17px", color: '#007E85' } : { fontSize: "17px", color: '#6BCBB8' }} />
              <h4 className="profileCategories" style={active === 'MyAccount' ? { color: '#007E85', fontWeight: "700" } : {}} onClick={() => { setActive("MyAccount") }}>My Account</h4>
            </div>
            <div className="userProfileh4">

              <BsHeartPulse style={active === 'MyHealth' ? { fontSize: "17px", color: '#007E85' } : { fontSize: "17px", color: '#6BCBB8' }} />
              <h4 className="profileCategories" style={active === 'MyHealth' ? { color: '#007E85', fontWeight: "700" } : {}} onClick={() => { setActive("MyHealth") }}>My Health</h4>
            </div>
            <div className="userProfileh4">
              <BsTruck style={active === 'MyOrders' ? { fontSize: "17px", color: '#007E85' } : { fontSize: "17px", color: '#6BCBB8' }} />
              <h4 className="profileCategories" style={active === 'MyOrders' ? { color: '#007E85', fontWeight: "700" } : {}} onClick={() => { setActive("MyOrders") }}>My Orders</h4>
            </div>
            <div className="userProfileh4">
              <BsFillBagHeartFill style={active === 'MyFavourites' ? { fontSize: "17px", color: '#007E85' } : { fontSize: "17px", color: '#6BCBB8' }} />
              <h4 className="profileCategories" style={active === 'MyFavourites' ? { color: '#007E85', fontWeight: "700" } : {}} onClick={() => { setActive("MyFavourites") }}>Favourites</h4>
            </div>
          </div>
        </div>
        <div className=" col-9 each-list-doc"> 
      {active === "MyAccount" && <MyAccountPage />}
      {active === "MyHealth" && <MyHealthPage /> }
      {active === "MyOrders" && <MyOrders/> }
      {active === "MyFavourites" && <MyFavourites/> }
      
     </div>
      </div>



    </>
  );
}

export default UserProfile;
