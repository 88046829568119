import React, { useState, useEffect } from "react";
import { createContext } from "react";
import { APIURL } from "../../Global";
import axios from "axios";
import { useAlert } from "react-alert";
export const Cart = createContext();
export const CartContext = createContext();
export const CartDelete = createContext();
//export const FavFromQuickView = createContext();
function AddToCartContext({ children }) {
  const [cartAdd, setCartAdd] = useState(false);
  const [distance, setDistance] = useState("");
  const [cartData, setCartData] = useState([]);
  const [cartItems1, setCartItems1] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const [addressList, setAddressList] = useState([]);
  const [address, setAddress] = useState();
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  // const [pincode, setPincode] = useState('');
  const alert = useAlert();
  //   const [cartDelete, setCartDelete] = useState(false);
  // const [favFromQuickView, setFavFromQuickView] = useState(false);
  const [cartItems, setCartItems] = useState(() => {
    const existingCartItems = sessionStorage.getItem("cartItems");
    return existingCartItems ? JSON.parse(existingCartItems) : [];
  });
  const [refresh, setRefresh] = useState(false);
  const [login_datas, setLogin_datas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas"))
      ? JSON.parse(sessionStorage.getItem("logindatas"))
      : null
  );

  useEffect(() => {
    // ...

    if (login_datas) {
      const tokenString = sessionStorage.getItem("usertoken");
      let v2 = tokenString.replace(/["]+/g, "");
      // Fetch cart items for logged-in user
      axios
        .get(`${APIURL}/api/v1/patient/cart/`, {
          headers: { Authorization: "Token " + v2 },
        })
        .then((res) => {
          console.log(res.data);
          setCartItems1(res.data.cart_items);
        })
        .catch((err) => {
          // Handle error if necessary
        });
    } else {
      // Fetch cart items from sessionStorage for non-logged-in user
      const existingCartItems =
        JSON.parse(sessionStorage.getItem("cartItems")) || [];
      setCartItems1(existingCartItems);
    }

    // ...
  }, [login_datas]);

  // const handleFormSubmit = (pincode) => {
  //   debugger;

  //   // Make a GET request to OpenStreetMap Nominatim API
  //   fetch(`https://nominatim.openstreetmap.org/search?q=${pincode}&format=json`)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.length > 0) {
  //         const { lat, lon } = data[0];

  //         setLatitude(lat);
  //         setLongitude(lon);
  //       } else {
  //         // Handle the case when pincode is not found
  //         alert.error('Pincode not found.');
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error:', error);
  //     });
  // };

  const refreshPage = () => {
    window.location.reload(false);
    window.scrollTo(0, 0);
  };

  const updateCartCount = () => {
    const tokenString = sessionStorage.getItem("usertoken");
    let v2 = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/patient/cart/`, {
        headers: { Authorization: "Token " + v2 },
      })
      .then((res) => {
        console.log(res.data);
        if (login_datas) {
          setCartItems1(res.data.cart_items);
        } else {
          setCartItems(res.data.cart_items);
        }
      })
      .catch((err) => {
        // Handle error if necessary
      });
  };

  useEffect(() => {
    if (login_datas) {
      const tokenString = sessionStorage.getItem("usertoken");

      let v2 = tokenString.replace(/["]+/g, "");
      axios
        .get(`${APIURL}/api/v1/patient/shipping-address/`, {
          headers: { Authorization: "Token " + v2 },
        })
        .then((res) => {
          setAddressList(res.data.message);
          const filteredData = res.data.message.filter((x) => {
            return x.is_default === true;
          });
          setAddress(filteredData);
          // const pincode = filteredData.length > 0 ? filteredData[0].pincode : '';
          //setPincode(pincode)
          // handleFormSubmit(pincode);
        })
        .catch((err) => {});
    }
  }, []);

  useEffect(() => {
    const existingCartItems =
      JSON.parse(sessionStorage.getItem("cartItems")) || [];
    setCartItems(existingCartItems);
  }, []);

  const calculateDistance = (lat1, lat2, lon1, lon2) => {
    debugger
    const toRadians = (degrees) => {
      return (degrees * Math.PI) / 180;
    };
  
    const R = 6371; // Radius of the earth in km
    const phi1 = toRadians(lat1);
    const phi2 = toRadians(lat2);
    const deltaPhi = toRadians(lat2 - lat1);
    const deltaLambda = toRadians(lon2 - lon1);
  
    const a =
      Math.sin(deltaPhi / 2) * Math.sin(deltaPhi / 2) +
      Math.cos(phi1) * Math.cos(phi2) * Math.sin(deltaLambda / 2) * Math.sin(deltaLambda / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in km
  
    return distance;
  };
  
  // const updateCartData = () => {
  //   const tokenString = sessionStorage.getItem("usertoken");

  //   let v2 = tokenString.replace(
  //       /["]+/g,
  //       ""
  //   );
  //   // Make API call to retrieve updated cart data
  //   axios.get(`${APIURL}/api/v1/patient/cart/`, {
  //     headers: { Authorization: "Token " + v2 },
  //   })
  //     .then((res) => {
  //       setCartData(res.data.cart_items);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  const handleDelete = (item) => {
    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");

    axios
      .delete(`${APIURL}/api/v1/patient/cart-detail/${item}/`, {
        headers: {
          Authorization: "Token " + str,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.status === 204) {
          alert.success("Deleted Successfully");
          // Fetch updated cart items and update cart count
          updateCartCount();
          window.location.reload();
        }
      })
      .catch((err) => {
        // Handle error if necessary
      });
  };

  const handleAddToCart = (item) => {

    debugger
    console.log("inside add to cart context");

    const today = new Date();
    const cartItem = {
      item: item,
      date: today.toISOString().split("T")[0], // Get today's date in YYYY-MM-DD format
    };
   
  if (login_datas) {
    const tokenString = sessionStorage.getItem("usertoken");
    let v2 = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/patient/shipping-address/`, {
        headers: { Authorization: "Token " + v2 },
      })
      .then((res) => {
        setAddressList(res.data.message);
        const filteredData = res.data.message.filter((x) => x.is_default === true);
        setAddress(filteredData);

        const pincode = address.length > 0 ? address[0].pincode : "";

        fetch(`https://nominatim.openstreetmap.org/search?q=${pincode}&format=json`)
          .then((response) => response.json())
          .then((data) => {
            if (data.length > 0) {
              const { lat, lon } = data[0];
              setLatitude(lat);
              setLongitude(lon);

              // Handle the logic for adding item to cart when the user is logged in
              axios
                .get(
                  `${APIURL}/api/v1/patient/service-provider-detail/?hospital_id=${
                    item.hospital_id ? item.hospital_id : item.hospital
                  }`,
                  {
                    headers: { Authorization: "Token " + v2 },
                  }
                )
                .then((res) => {
                  console.log(res.data);
                  let hospital = res.data.hosp_details;
                  if (hospital.latitude && hospital.longitude) {
                    console.log(
                      "YES THE COORDINATES",
                      lat,
                      hospital.latitude,
                      lon,
                      hospital.longitude
                    );
                    const distance1 = parseFloat(calculateDistance(lat, hospital.latitude, lon, hospital.longitude)).toFixed(4);
                    // setDistance(distance1);

                    const dataTosend = {
                      item_service: item.item_service ? item.item_service : item.id,
                      item_due_date: item.date
                        ? item.date
                        : new Date().toISOString().split("T")[0],
                      item_service_priority: "critical",
                      geo_distance: distance1, // Assign the calculated distance
                      item_quantity: item.item_quantity ? item.item_quantity : "1",
                    };

                    axios
                      .post(`${APIURL}/api/v1/patient/cart/`, dataTosend, {
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: "Token " + v2,
                        },
                      })
                      .then((res) => {
                        if (res.data.status === "success") {
                          if (res.data.message.toLowerCase() !== "already available in cart") {
                            alert.success(res.data.message);
                            setCartItems1((prevItems) => [...prevItems, cartItem]);
                            setCartCount((prevCount) => prevCount + 1);
                          } else {
                            alert.success(res.data.message);
                          }
                        } else {
                          alert.error(res.data.message);
                        }
                      })
                      .catch((err) => {
                        // setErrorMsg("Error in submission");
                      });
                  }
                });
            } else {
              alert.error("Pincode not found");
            }
          });
      });
  } else {
      // Handle the logic for adding item to cart when the user is not logged in
      const updatedCartItems = [...cartItems, cartItem];
      sessionStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
      setCartItems(updatedCartItems);
      setCartCount((prevCount) => prevCount + 1);
      // Show alert message
      alert.success("Added to cart");
    }
  };
  return (
    <CartContext.Provider
      value={{
        cartAdd,
        setCartAdd,
        cartItems,
        cartItems1,
        updateCartCount,
        handleAddToCart,
        handleDelete,
        cartCount,
        setCartCount,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}

export default AddToCartContext;
