import React, { useEffect, useState } from "react";
import axios from "axios";
import { APIURL } from "../../Global";
import { Badge } from 'react-bootstrap';
import DatePicker from "react-date-picker";
import { useAlert } from "react-alert";
import service1 from "../../assets/images/1.png";
import service2 from "../../assets/images/2.png";
function QuickView(item) {
  const alert = useAlert();
  const [img, setImg] = useState(item.from === "wo" ? item.item.image : "")
  const [dateval, setDateVal] = useState("");
  const [dateToday, setDateToday] = useState("");
  const [distance, setDistance] = useState(0.0);
  const [qty, setQty] = useState(1)
  const [login_datas, setLogin_datas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas"))
      ? JSON.parse(sessionStorage.getItem("logindatas"))
      : null
  );
  useEffect(() => {
    if (item.item.service_category === "master_consumable" || item.item.service_category === "master_medicine") {
      // const tokenString = sessionStorage.getItem("usertoken");

      // let str = tokenString.replace(/["]+/g, "");
      axios.get(`${APIURL}/api/v1/patient/product-images/?unified_code=${item.item.unified_code}`, 
      // {
      //   headers: {
      //     Authorization: "Token " + str,
      //   },
      // }
      )
        .then((res) => {
          if (res.data.status === "success") {
            setImg(res.data.image)

          }
        })
    }

  }, [item.item.service_category === "master_consumable" || item.item.service_category === "master_medicine"]);
  const handleChangeRadio = (event) => {


    if (event.target.value == 1) {
      setDateVal('');
      setDateToday(new Date())

    }
    if (event.target.value == 2) {
      setDateVal(event.target.value);
    }


  }
  const handleDateChange = (date) => {
    if (date !== null) {

      setDateVal(date)
    }
  };
  const handleChangeQty = (e) => {
    setQty(e.target.value)
  };
  useEffect(() => {
    

      // const tokenString = sessionStorage.getItem("usertoken");


      // let v2 = tokenString.replace(
      //   /["]+/g,
      //   ""
      // );
      axios.get(`${APIURL}/api/v1/patient/service-provider-detail/?hospital_id=${item.item.hospital}`,
      //  {
      //   headers: { Authorization: "Token " + v2 },
      // }
      )
        .then((res) => {
          console.log(res.data);
          let hospital = res.data.hosp_details;
          if (hospital.latitude && hospital.longitude) {
            console.log('YES THE COORDINATES', login_datas.latitude, hospital.latitude, login_datas.longitude, hospital.longitude);
            calculateDistance(login_datas.latitude, hospital.latitude, login_datas.longitude, hospital.longitude);
          }

        })
        .catch((err) => {

        })
    
  })
  const addCart = () => {

    
    const tokenStr = sessionStorage.getItem("usertoken");

    if (tokenStr) {
      const v = tokenStr;
      let v2 = v.replace(/["]+/g, "");

      if (dateToday === "" && dateval === "") {
        alert.error("Please select date for delivery");

      } else {
        let date = dateToday !== "" ? dateToday : dateval
        let localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
        const dataTosend = {
          item_quantity: qty,
          item_service: item.item.id,
          item_due_date:localDate.toISOString().split("T")[0],
          item_service_priority: "critical",
          geo_distance: distance
        };

        axios
          .post(`${APIURL}/api/v1/patient/cart/`, dataTosend, {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Token " + v2,
            },
          })
          .then((res) => {
            if (res.data.status === "success") {
              alert.success(res.data.message);
              item.handleRefreshCart();

            } else {
              alert.error(res.data.message);

            }
          })
          .catch((err) => {
            // setErrorMsg("Error in submission");
          });
      }
    }
  }
  const calculateDistance = (latt1, latt2, long1, long2) => {

    var R = 6371; // Radius of the earth in km
    const lat1 = parseFloat(latt1)
    const lat2 = parseFloat(latt2)
    const lon1 = parseFloat(long1)
    const lon2 = parseFloat(long2)
    var dLat = deg2rad(lat2 - lat1);  // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
      ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    // return d;
    setDistance(parseFloat(d).toFixed(4));
    console.log('THE DISTANCE', d)

  };
  const deg2rad = (deg) => {
    return deg * (Math.PI / 180)
  }
  return (
    <div className=" productsQuickView" id="productsQuickView" style={{ marginTop: "-58px" }}>
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <div className="products-image ">


                {img !== "" ? <img style={{ borderRadius: "20px" }} src={img} alt="image" />
                  : <img style={{ borderRadius: "20px" }} src={service2
                  } alt="image" />}

              </div>
            </div>

            <div className="col-lg-6 col-md-6" >
              <div className="products-content" style={{ fontSize: "12px", marginLeft: "2%" }}>
                <h6><a href="#"><b>{item.item.service}</b></a></h6>

                <div className="price" style={{ fontSize: "12px" }}>
                  <span className="old-price">${item.item.amount}</span>&nbsp;&nbsp;
                  <span className="discounted-price">${(item.item.amount * (100 - item.item.discount) / 100).toFixed(2)}</span>
                  <span>&nbsp;&nbsp;</span>
                  <Badge pill variant="success" style={{ fontSize: '12px' }}>
                    {item.item.discount}% Off!
                  </Badge>
                </div>

                <div style={{ display: "flex" }} className="products-review">
                  <div className="rating">
                    <i className='bx bxs-star'></i>
                    <i className='bx bxs-star'></i>
                    <i className='bx bxs-star'></i>
                    <i className='bx bxs-star'></i>

                  </div>
                  <a href="#" className="rating-count">4 stars</a>
                </div>

                <ul className="products-info">
                  <li><span>Vendor:</span> <a href="#">{item.item.name}</a></li>
                  <li><span>Availability:</span> <a href="#">In stock</a></li>
                  <li><span>Products Type:</span> <a href="#">{item.item.main_service} </a></li>
                </ul>




                <div className="">
                  {item.item.service_category === "master_consumable" || item.item.service_category === "master_medicine" ?
                    <div className="input-counter" style={{ display: 'flex' }}>
                      <div style={{ paddingTop: "1%", paddingLeft: "3%" }} className="pro-counter">QTY</div>
                      &nbsp;
                      <input style={{ paddingLeft: "5%" }} onChange={handleChangeQty} className="pro-counter" value={qty} type={"number"} />
                    </div>
                    : ""}<br />

                </div>
                <div className="row">
                  <div className="col-6">
                    <div>
                      <input type="radio" name="status" onChange={handleChangeRadio} value="1" />
                      {" "}
                      <span style={{ color: 'black' }}>Today</span>
                    </div>

                    <div>
                      <input type="radio" name="status" onChange={handleChangeRadio} value="2" />
                      {" "}
                      {dateval ?

                        <DatePicker
                          value={new Date(dateval)}
                          onChange={(date) => {
                            handleDateChange(date);

                          }}
                          minDate={new Date()}
                          dateFormat="dd/MM/yyyy"
                          className="custom-datepicker"

                        />

                        :
                        <span style={{ color: 'black' }}>Set Delivery Date</span>}
                    </div>

                  </div>
                  <div className="col-6" style={{ display: "flex" }}>

                    {/* <a href="#" data-toggle="modal" data-target="#shoppingWishlistModal"><i style={{ fontSize: "20px", color: "#C3B7B7", marginTop: "81%" }} className='fa fa-heart fa-lg'></i></a>&nbsp;&nbsp;&nbsp;&nbsp; */}

                    <button type="submit" style={{ marginTop: "4%", backgroundColor: "#F0A639", fontSize: "12px", marginLeft: "0px", marginRight: "0px" }} className="default-btn" onClick={() => { addCart() }}><i className="flaticon-trolley" ></i> Add to Cart</button>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div></div>
  )
}
export default QuickView;