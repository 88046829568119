import React, { useState, useEffect, useRef } from 'react'
import axios from "axios";
import FullCalendar, { formatDate } from '@fullcalendar/react'
import { APIURL } from "../../../Global";
import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";
import moment from 'moment';
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction';
import PatientNav from '../PatientNav';
import { useAlert } from "react-alert";
import PatientFooter from '../PatientFooter';
function BookingDetails({ setShowNavbarAndFooter }) {
    useEffect(() => { setShowNavbarAndFooter(false); return () => setShowNavbarAndFooter(true); }, [setShowNavbarAndFooter]);
    let navigate = useNavigate();
    const alert = useAlert();
    const location = useLocation();
    const [expand, setExpand] = useState(1);
    let slots = location.state.slots
    let doc = location.state.doc
    let date = location.state.date
    const [refresh,setRefresh]=useState(false)
    const [followup, setFollowUp] = useState({})
    const [login_datas, setLogin_datas] = useState(
        JSON.parse(sessionStorage.getItem("logindatas"))
            ? JSON.parse(sessionStorage.getItem("logindatas"))
            : null
    );
    console.log(slots)
    useEffect(() => {
    
        sessionStorage.removeItem("navigated_from");
        sessionStorage.removeItem("doc");
         sessionStorage.removeItem("date");
        sessionStorage.removeItem("slots");
        sessionStorage.removeItem("navigated");

    
 })
    useEffect(() => {
        if(login_datas){
        const tokenString = sessionStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
        );

        axios.get(`${APIURL}/api/v1/patient/appointments/`, {
            headers: { Authorization: "Token " + v2 },
        })
            .then((res) => {
                if (res.data.status === "success") {
                    setFollowUp(res.data.appointments)
                }

            })
            .catch((err) => {

            })
        }
    }, [refresh])
    const handleContinue = async (e) => {
        const tokenString = sessionStorage.getItem("usertoken");

        let v2 = tokenString.replace(
          /["]+/g,
          ""
        );
        let data = {
            doctor_id:doc.id, 
            slot_id:slots.id,
            date:date
            
        };
        axios.post(`${APIURL}/api/v1/patient/doctor-appointment/`, data, {
            headers: { Authorization: "Token " + v2 },
          })
            .then(res => {
                if (res.data.status === "success") {
                    alert.success("You have booked an appointment with "+doc.full_name.slice(0, 3) === "Dr." ? doc.full_name : "Dr." + doc.full_name+" at "+slots.from+ " on "+date)
                    setRefresh(!refresh)
                }
                else {
                    alert.error(res.data.message)
                }

            })

    }

    return (
        <>
            <PatientNav />
            <div className='container'>
                <div style={{ marginTop: "8%",padding:"1%" }} onClick={() => setExpand(1)} className='each-list-doc'><i class="fa fa-check-square"></i>{" "}<span className='day-head'>Appointments</span></div>
                {expand === 1 ? (
                    <>
                        <div className='row' style={{ marginTop: "2%" }}>
                            <div className='col-6'>
                                <div style={{padding:"2%" }}className='each-list-doc'>
                                    <div className='row'>
                                        <div className='col-3'>
                                            <div className="flex align-items-center" style={{ padding: "6%" }}>
                                                <img style={{ borderRadius: "50%", width: "80px", height: "80px" }} src={doc.photo} alt="image" />

                                            </div>
                                        </div>
                                        <div style={{paddingTop:"1%"}} className='col-9'>
                                            <span className='doc-name'>{doc.full_name.slice(0, 3) === "Dr." ? doc.full_name : "Dr." + doc.full_name}</span> <span style={{ fontSize: "10px" }} className='doc-details'>⭐️ 4.3 (130 reviews)</span><br />

                                            <span className='doc-details'>{doc.specialization},{doc.experiance}{" "}Years experiance</span><br />
                                            <span className='doc-details'>{doc.hospital},{" "}{doc.location}</span><br />


                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className='col-6'>
                                <div className='each-list-doc'style={{padding:"1%" }}>
                                    <div className='row' style={{ paddingTop: "1%" }}>
                                        <span className='col-5 doc-details'><b>Date</b></span>
                                        <span className='col-7 doc-details'><b>{date}</b></span>
                                    </div>
                                    <div className='row' style={{ marginTop: "2%" }}>
                                        <span className='col-5 doc-details'><b>Time</b></span>
                                        <span className='col-7 doc-details'><b>{slots.from.slice(0,5)}-{slots.to.slice(0,5)}</b></span>
                                    </div>
                                    <div className='row' style={{ marginTop: "2%" }}>
                                        <span className='col-5 doc-details'><b>Fees</b></span>
                                        <span style={{fontSize:"20px"}} className='col-7 doc-details'><b>${slots.fees}</b></span>
                                    </div><br/>
                                    <div>
                                    <span style={{ marginLeft:"17.5%" }} className='doc-details'><b>Fees are collected at the clinic.</b></span>
                                    </div>
                                </div>
                               
                            </div>
                            <div style={{ marginTop: "2%",  }}>
                            {!(login_datas) ? <span onClick={() =>{ navigate("/loginpatient?booking-details" ,{
                                state: {
                                 slots:slots,
                                 doc:doc,
                                 date:date
                                }
                              })}} style={{ marginLeft:"45%"}} className='doc-details'><b>Please <a style={{fontSize:"13px"}} href="/loginpatient?booking-details">Login</a> to Continue.</b></span> : ""}<br/>
                                <button onClick={() => handleContinue()} disabled={!login_datas} style={{ marginLeft:"31%", width: "37%", padding: "1%" }} className='book-appointment-btn'>Book Appointment</button>
                        </div></div>
                    </>
                ) : null}
                <div style={{ marginTop: "1%" ,padding:"1%"}} onClick={() =>!(login_datas) ? alert.error("please Login to View the summery"): setExpand(2)} className='each-list-doc'><i class="fa fa-check-square"></i>{" "}<span className='day-head'>Summary</span></div>
                {expand === 2 ? (
                    <div className='container'> 
                  { followup.length > 0 && followup.map((item, index) => {
                    return (
                       <div style={{padding:"1%", marginTop:"2%",width:"80%", marginLeft:"10%"}} className=" each-list-doc row">
                           <div className="col-3 flex align-items-center" style={{ }}>
                                                <img style={{ borderRadius: "50%", width: "80px", height: "80px" }} src={item.doc_photo} alt="image" />

                                            </div>
                            <div className='col-5'>
                                <span className='doc-name'>{item.doctor_name.slice(0, 3) === "Dr." ? item.doctor_name : "Dr." + item.doctor_name}</span> <br/>
                                <span className='doc-details ' >{item.specialization}</span> <br/>
                                
    
                            </div>
                            <div className='col-4'><div className='' style={{marginLeft:"5%"}}>
                            {item.is_online?<span className='doc-details'><i style={{color:"#007E85", fontSize:"small"}} class="fa fa-video-camera" aria-hidden="true"></i>&nbsp;<b>Video Consultation</b></span>:
                                   <span className='doc-details'><i style={{color:"#007E85", fontSize:"small"}} class="fa fa-user-md" aria-hidden="true"></i>&nbsp;<b> Hospital Visit</b></span>}
                            <span  style={{marginLeft:"1%"}} className='col-5 doc-details'><b>Token-{item.token}</b></span>
                            </div>
                            
                            <div className='row' style={{ paddingTop: "5%" }}>
                           
                                        <span className='col-5 doc-details'><b>Date</b></span>
                                        <span className='col-7 doc-details'><b>{item.date}</b></span>
                                    </div>
                                    <div className='row' style={{ marginTop: "2%" }}>
                                        <span className='col-5 doc-details'><b>Time</b></span>
                                        <span className='col-7 doc-details'><b>{item.slot}</b></span>
                                    </div>
                                  

                            
    
                            </div>
                         
                        </div>)
                })
            }
            </div>
                ) : null}
            </div><br/>
            <PatientFooter/>
        </>
        
    )
}
export default BookingDetails