import React, { useState } from 'react';
import classes from "../favorite.module.css";
import { FaRegUser,FaRegSun } from "react-icons/fa";
import { BsHeartPulse} from "react-icons/bs";
import MyHealthVitals from './MyHealthVitals';
function MyHealthPage() {
  const [acceptAck ,setAcceptAck] = useState(false);
  const acceptHealthAck = () =>{
    console.log("inside acceptHealthAck");
    setAcceptAck(true);
  }
  return (
    <div className={classes.ProfilePagecontainer} style={{}}>
      {acceptAck?(
      <div className={classes.healthPageAck}style={{marginLeft:"12%",}}>
      <div>
        <h4 style={{textAlign:"center",color: "#F0A639",fontSize:"18px",fontFamily:"poppins",fontWeight:"600"}}>HorizonCommerce</h4>
        <div style={{background:"#EFEFEF",marginTop:"10px",marginBottom:'10px',padding:"10px"}}>
          <p style={{textAlign:"center",color:"#7B7B7B",fontFamily:"poppins"}}>Share the following data from your account <br></br> with our cloud and respected healthcare<br/> service providers</p>
        </div>
        <div style={{display:"flex",justifyContent:"center",padding:"10px"}}>
          <FaRegUser style={{fontSize:"20px",marginRight:"10px"}}/><br/>
          <b style={{fontFamily:"poppins",fontSize:"18px"}}>Personal Information</b>
         
        </div>
        <div style={{display:"flex",justifyContent:"center",fontFamily:"poppins",marginLeft:"20px"}}>
          <div>
            <ul>
            <li >First Name</li>
            <li >Last Name</li>
            <li >Gender</li>
            </ul>
          </div>
          <div>
            <ul>
          <li >Address</li>
            <li >Email ID</li>
            <li >Phone Number</li>
            </ul>
          </div>
       
        </div>
        <div style={{display:"flex",justifyContent:"center",padding:"10px"}}>
          <BsHeartPulse style={{fontSize:"23px",marginRight:"10px"}}/><br/>
          <b style={{fontFamily:"poppins",fontSize:"18px"}}>Health Information</b>
         
        </div>
        <div style={{display:"flex",fontFamily:"poppins",marginLeft:"25%"}}>
        <div style={{paddingLeft:"30%"}}>
          <ul>
            <li>Vitals</li>
          </ul>
        </div>
        <div style={{paddingLeft:"6%"}}>
          <ul>
            <li>Physique</li>
          </ul>
        </div>
        </div>
        <div style={{display:"flex",justifyContent:"center",padding:"10px"}}>
          <FaRegSun style={{fontSize:"20px",marginRight:"10px"}}/><br/>
          <b style={{fontFamily:"poppins",fontSize:"18px"}}>Profile Information</b>
         
        </div>
        </div>
        <div style={{display:"flex",fontFamily:"poppins",marginLeft:"20px"}}>
        <div style={{paddingLeft:"30%"}}>
          <ul >
            <li>User ID</li>
            <li>Preferences</li>
          </ul>
        </div>
        <div>
          <ul>
            <li>Location</li>
          </ul>
        </div>
        </div>
        <p style={{textAlign:"center",fontFamily:"poppins"}}>I have read and accepted the terms and conditions</p>
        <div style={{background:"#007E85",display:"flex",height:"70px",justifyContent:"center",alignItems:"center"}}
        onClick={acceptHealthAck}>
          <b style={{fontFamily:"poppins",fontSize:"20px",color:"white"}}>I Accept</b>
        </div>
        <p style={{fontFamily:"poppins",textAlign:"center",padding:"10px",textDecoration:'underline'}}>Cancel</p>
      
      </div>
      ) : (<MyHealthVitals/>) }
    </div>
  )
}

export default MyHealthPage
