import React,{useState,useEffect,useRef} from 'react'
import axios from "axios";
import {APIURL} from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import { ToggleButtonGroup } from "react-bootstrap";
import { ClearButton, Typeahead } from "react-bootstrap-typeahead";
import {  Dropdown, ToggleButton, Tab, Tabs,  ButtonGroup } from "react-bootstrap";
import { Spinner } from 'react-bootstrap';


function ProcedurePage(props) {

const[pageShow,setPageShow]=useState(true)
const[pageShow1,setPageShow1]=useState(true)
const [detailData, setDetailData] = useState([]);
const SubjectTypeahead = useRef(null)
const [nameError, setNameError] = useState("")
const [dataList, setDataList] = useState([])
const [serviceList, SetServiceList] = useState([]);
let placeholder = "Please select procedure name"
const [selectedItems, setSelectedItems] = useState([]);
const [id, setId] = useState("")
const [sub_service, setServiceName] = useState("")
const [vat, setVat] = useState("")
const [discount, setDiscount] = useState("")
const [amount, setAmount] = useState("")
const [main_service_name, setMainService] = useState("Hospital procedures")

let url = ""
let name = ""

 if (pageShow===true) {
    name = "service_name"
    placeholder = "Search procedure"
    url =  "/api/v1/service-provider/hospital-services/?type=master_procedure"
}
else {
    placeholder = "Please Chooce a procedure"

}
const isValid = dataList.filter((option) => !!option[name]).length === dataList.length;

const options = isValid ?  dataList && dataList.map((option) => {
    return {
        label: option[name],
        main_service_name:"Hospital procedures",
        //unified_code: option["unified_code"],
        id: option["id"],
        amount:option["amount"],
        vat:option["vat"],
        discount:option["discount"]
    }

}) : [];

useEffect(() => {

    
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(/["]+/g, "");

 axios
      .get(
        `${APIURL}/api/v1/service-provider/careplan-schedule/?patient_id=${props.patientID}&type=procedure`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + v2,
          },
        }
      )
      .then((res) => {
       
          if (res.data.status === "success") {
            const datas = res.data.details;
          
          
           setDetailData(datas);
           // setHasNext(() => res.data.has_next);
         
           // setDisplay1(true);
       
        
        }  
    
      })
      .catch((err) => {
      
      });

}, []);

const handleAdd =() =>{
    

    props.dataHandle(serviceList)
    props.modalClose()
}

const groupedDetails = detailData.reduce((acc, detail) => {
 

    let latestDate = null;


    
    const key = `${detail.doctor_name}`;
    if (!acc[key]) {
      acc[key] = {
        doctor_name: detail.doctor_name,
        schedules: [],
        date:detail.date
      };
    }
    acc[key].schedules.push({ hosp_service_id: detail.hosp_service_id, schedule_name: detail.schedule_name, amount: detail.amount });
    for (let i = 0; i < detailData.length; i++) {
        const detail = detailData[i];
        const detailDate = new Date(detail.date);
        if (latestDate === null || detailDate > latestDate) {
          latestDate = detailDate;
        }
      }
    return acc;
  }, {});
  
  console.log(groupedDetails);

const handleRemoveItem = idx => {
    // assigning the list to temp variable
    const temp = [...serviceList];

    // removing the element using splice
    temp.splice(idx, 1);

    // updating the list
    SetServiceList(temp);
}

const handlePage =()=>
{
    setPageShow(true);
    setPageShow1(false);
}
const handleRegPage =()=>
{
    setPageShow(false);
    setPageShow1(true);
}
const handleItemSelect = (index) => {
  if (selectedItems.includes(index)) {
    setSelectedItems(selectedItems.filter((item) => item !== index));
  } else {
    setSelectedItems([...selectedItems, index]);
  }
  console.log(selectedItems)
};


const handleProcedure =() =>{

  

  // create a new array of only the selected details
  const selectedDetails = Object.values(groupedDetails)
    .filter((doctor, index) => selectedItems.includes(index))
    .map((doctor) => ({ doctor_name: doctor.doctor_name, schedules: doctor.schedules }));

  // filter the detailData array based on the selectedDetails array
  const filteredDetailData = detailData.filter((detail) =>
    selectedDetails.some((selected) => selected.doctor_name === detail.doctor_name)
  );
  


    props.dataPro(filteredDetailData)
    props.modalClose()
}




return(

    <div className="paymentconfiginner">

 
        <button className="medicinebuttons1" onClick={()=>handleRegPage(true)}>Prescriptions</button>
        <button className="medicinebuttons2" onClick={() => handlePage()}>Procedures</button><br/><br/>
       
{pageShow ? 

<div className="" style={{marginRight:"4%",marginTop:"-6%" ,alignItems:"center"}} >
<div style={{ display: "flex", marginTop:"7%", width:"50%" ,marginLeft:"25%"}}>
    
<Typeahead
                            ref={SubjectTypeahead}
                            placeholder={placeholder}
                            style={{width:"100%"}}
                            onFocus={() => {
                                setNameError("")
                                const tokenString = sessionStorage.getItem("usertoken");

                                let v2 = tokenString.replace(
                                    /["]+/g,
                                    ""
                                ); /* REGEX To remove double quotes from tokenstring */
                                
                                if (!url) {
                                    return;
                                }
                                axios
                                    .get(`${APIURL}${url}`, {
                                        headers: { Authorization: "Token " + v2 },
                                    })
                                    .then((res) => {
                                      
                                        //console.log(res);
                                        if (res.status === 200) {

                                            const datas = res.data;

                                            setDataList(datas);
                                            //console.log(featureList)
                                        }
                                    })
                            }}

                            options={options}
                            onChange={(data) => {
                              
                              
                                if (data.length) {
                                    setServiceName(data[0].label);
                                    //setUnicode(data[0].unified_code);
                                    setId(data[0].id)
                                    setAmount(data[0].amount)
                                    setVat(data[0].vat)
                                    setDiscount(data[0].discount)
                                    // setSerCategory(data[0].service_category)
                                }
                            }
                            }

                        >
                            {({ onClear, selected }) => (
                                <div className="rbt-aux" style={{ width:"5%"}}>
                                    {!!selected.length && <ClearButton onClick={onClear} />}
                                    {!selected.length && <Spinner animation="grow" size="sm" />}
                                </div>
                            )}
                        </Typeahead>
                        <button onClick={() =>{
                            serviceList.filter(value =>
                                value.sub_service === sub_service).length > 0 ?
                                setNameError(sub_service + " already there in your list!") :sub_service&&
                                SetServiceList(prevArray => [...prevArray, { sub_service, id,amount,vat,discount,main_service_name }]);
                        }}
                            className="btn btn-primary btn-col search-btn">
                            <i class="fa fa-arrow-right" aria-hidden="true"></i>
                           
                        </button></div>
                        {nameError ? <span className="error-validation-msg" style={{marginTop:"10%", width:"10%",height:"10%",color:"red"}}>{nameError}</span>:null}
                       
               
                    <div className="formField">
                        {serviceList.filter(value => Object.keys(value).length > 0).map((list, idx) => {
                            const value = list.sub_service;
                            return <>
                                <div key={idx} className='list-medicines' style={{ padding: "1px", marginTop: "20px" }}>
                                  
                                    <label style={{ color: "#000000" ,marginLeft:"30%",marginTop:"2%" }} className='list-names'><b>{value}</b>  </label>
                                    {/* <i
                                        class="fa fa-trash-o"
                                        onClick={() => handleRemoveItem(idx)}
                                        style={{ float: "right", color: "red", marginLeft:"60%",marginTop:"-15%" }}
                                    ></i> */}
                                </div>
                               
                            </>

                        })}<br />
             
                       


                    </div>
                  
                    <br/>
        <p className='text-center'>
          <button onClick={handleAdd} disabled={serviceList.length < 1} className='btn btn-primary btn-col'>Add</button>
        </p>
</div>


:

pageShow1 ?



<div className="formField">
{detailData.length > 0 ? (
  Object.values(groupedDetails).map((doctor, index) => (
    <div key={index} >
    <div className="checkbox-container">
  <input type="checkbox" style={{marginTop:"1%" ,marginLeft:"5%",width:"20px",height:"20px"}} checked={selectedItems.includes(index)} onChange={() => handleItemSelect(index)} />
    <div  className="list-medicines" style={{ padding: "1px", marginTop: "20px", height: "auto", display: "grid", gridTemplateColumns: "1fr 1fr"}}>
      <div className="doctor-name" style={{ color: "#000000", marginLeft: "10%", marginTop: "2%" }}>
        <h6><b style={{ color: "#585858" }}>{doctor.doctor_name}</b></h6>
        <h6>Last prescribed on:{doctor.date}</h6>
      </div>
      <div className="medicine-details" style={{ display: "grid", gridTemplateColumns: "1fr", marginTop: "2%",marginLeft:"-3%" }}>
        {doctor.schedules.map((schedule, i) => (
         
             <h6><i class="fa fa-circle" style={{color:" #086073"}} aria-hidden="true"></i> <span></span><b>{schedule.schedule_name} <span>&nbsp;&nbsp;</span><i class="fa fa-inr" aria-hidden="true"></i>{schedule.amount} </b>   {schedule.hosp_service_id === null ? (
                                    <i class="fa fa-times-circle" aria-hidden="true" style={{ color: "red" }}></i>
                                ) : (
                                    <i class="fa fa-check-square" aria-hidden="true" style={{ color: "green" }}></i>
                                )}</h6>
        
        
        ))}
      </div>
    </div>
 </div></div> ))
) : (
  <h4 className="text-center" style={{ color: "red" }}>
    No Prescriptions !!
  </h4>
)}


<br/>
        <p className='text-center'>
          <button onClick={handleProcedure}  disabled={selectedItems.length < 1} className='btn btn-primary btn-col'>Add</button>
        </p>


</div>





:""}





</div>

)


}

export default ProcedurePage