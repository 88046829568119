import React, { useState, useEffect } from "react";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import axios from "axios";
import { APIURL } from "../../../Global";
import { useNavigate, Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import EditSelectedService from "./EditSelectedService";
import "./selectedservices.css";
import BdoNavbar from "../BdoNavbar";
//import LoadingOverlay from "react-loading-overlay";
import ShipCharge from "./ShipCharge";
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";

let SelectedServices = () => {
  const [deleteShow, setDeleteShow] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState();
  const [editShow, setEditShow] = useState(false);
  const [editIndex, setEditIndex] = useState("");
  const [infoShow, setinfoShow] = useState(false);
  const [infoData, setInfoData] = useState("");
  // const [amount, setAmount] = useState(0);
  // const [vat, setVat] = useState(0);
  const [editdata, setEditData] = useState([]);
  const [shipData, setShipData] = useState([]);
  const [shipShow, setShipShow] = useState(false);
  const [errorShow, setErrorShow] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [shipIndex, setShipIndex] = useState("");
  const [subServiceList, setSubServiceList] = useState([]);
 
  const [serviceName, setServiceName] = useState("");
  let navigate = useNavigate();
  const [filteredList, setFilteredList] = useState([])
  const aggr_type=sessionStorage.getItem("aggr_type")
  const loadData = () => {

    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(/["]+/g, "");
    axios
      .get(`${APIURL}/api/v1/service-provider/hospital-services/`, {
        headers: { Authorization: "Token " + v2 },
      })
      .then((res) => {
        if (res.status === 200) {
          const datas = res.data;
          setFilteredList(datas.filter((item, index) => {
            return (item.is_popular === true)
          }))
          
          var arrSortunshiftByDate = datas.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          );

          setSubServiceList(arrSortunshiftByDate);

        } else {
          //  setErrorShow(true)
        }
      })
      .catch((err) => {
        // setErrorShow(true)
      });
  };
  useEffect(() => {
    loadData();
  }, [refresh]);
  const onSuccess = () => {
    loadData();
    setEditShow(false);
  };
  const EditPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-medium"
      >
        {" "}
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"><h5>Edit Service</h5>
</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditSelectedService onSuccess={onSuccess} editdata={editdata} />
        </Modal.Body>
      </Modal>
    );
  };
  const ShipPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-medium"
      >
        {" "}
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <ShipCharge
            onSuccess={onSuccess}
            shipdata={shipData}
            data1={subServiceList}
            shipid={shipData.shipping_charge_id}
            modalClose={modalClose}
            ID={shipData.id}
          />
        </Modal.Body>
      </Modal>
    );
  };
  const renderTooltip1 = (props) => (
    <Tooltip className="my-tooltip" id="button-tooltip" {...props}>
      Add/View Shipping Charge
    </Tooltip>
  );


  const ErrorPopup = (props) => {
    return (
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body style={{ display: "flex" }}>

          <h6 className="">
            {" "}
            Only 8 popular Services!!!
          </h6>



          <button
            style={{ marginLeft: "20px", fontSize: "12px", backgroundColor: "var(--theme-blue)" }}
            type="button"
            className="btn-primary btn-square"
            onClick={props.onHide}
          >
            {" "}
            Ok{" "}
          </button>


        </Modal.Body>
      </Modal>
    );
  };


  const DeleteConfirmPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <h4 className="title-of-page"> Delete Service!! </h4>
          <h5 className="title-of-page">
            {" "}
            Please Confirm to Delete the Service {serviceName}
          </h5>
          <br />

          <div className="form-group " style={{ marginLeft: "300px" }}>
            <button
              type="button"
              className="btn btn-danger btn-cancel"
              onClick={props.onHide}
            >
              {" "}
              Cancel{" "}
            </button>
            <button
              type="submit"
              className="btn btn-primary btn-col"
              onClick={() => {
                handleDelete(deleteIndex);
              }}
            >
              Confirm{" "}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };
 
  const InfoPopup = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div>
            <h4 className="title-of-page"> {infoData.service_name}</h4>
            <fieldset className="field_set">
              <legend style={{ size: "5" }}>
                <b>More Info:</b>
                <br />
                <br />

                <div className="row-of-features">
                  <div className="col-of-features">
                    <h6>
                      Discount<small>(%)</small> : {infoData.discount}
                    </h6>
                    <h6>
                      Waiting Time Period<small>(Days)</small>:{" "}
                      {infoData.delivery_time_period}
                    </h6>
                    <h6>
                      Return Period<small>(Days)</small>:{" "}
                      {infoData.return_period}
                    </h6>
                    <h6>Description: {infoData.description}</h6>

                    <div className="form-heading title-of-tasks">
                      <button
                        type="button"
                        className="btn formFieldButton"
                        onClick={props.onHide}
                      >
                        {" "}
                        Close{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </legend>
            </fieldset>
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  const handleDelete = (item) => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");
    axios
      .delete(
        `${APIURL}/api/v1/service-provider/hospital-service-detail/${deleteIndex}/`,
        {
          headers: {
            Authorization: "Token " + str,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          loadData();
        } else {
          console.log("else called ");
        }

        setDeleteIndex("");
        setDeleteShow(false);
      })
      .catch((err) => {
        console.log("catch called");
      });
  };
 
  const handleDeletePopUp = (id, name) => {
    setDeleteIndex(id);
    setServiceName(name);
    setDeleteShow(true);
  };
  const handleInfoPopUp = (item) => {
    setInfoData(item);
    setinfoShow(true);
  };
  const handleEditPopUp = (item) => {
    setEditIndex(item.id);
    setEditData(item);
    console.log(item)
    setEditShow(true);
  };
  const modalClose = () => {
    setShipShow(false);
    loadData();
    setRefresh(true);
  };

  const handleShipCharge = (item) => {
    setShipIndex(item.id);
    setShipData(item);
    setShipShow(true);
  };
  const columns = [
    {
      Header: "Service",
      accessor: "services",
      style: { whiteSpace: "unset" },
      minWidth: 80,
    },
    {
      Header: "Main Service",
      accessor: "mainservices",
      style: { whiteSpace: "unset" },
      minWidth: 80,
    },
    {
      Header: "Amount",
      accessor: "amount",
      minWidth: 30,
    },
    {
      Header: "VAT",
      accessor: "vat",
      minWidth: 20,
    },
    {
      Header: "",
      accessor: "is_active",
      minWidth: 60,
    },
  ];

  const getServiceCol = (subServiceList) => {
    return subServiceList.map((item) => {
      return {
        services: (
          <h6 style={{ color: item.is_active ? "#259BE9" : "silver" }}>
            {item.service_name}<span>&nbsp;</span> <b style={{color:"#757575"}}>{item.unified_code_group}</b>
            <i
              style={{ color: "#75759c", paddingLeft: "5%" }}
              className="fas fa-info-circle fa-sm"
              onClick={() => {
                handleInfoPopUp(item);
              }}
            ></i>{" "}<span>&nbsp;&nbsp;&nbsp;</span>
             {item.service_category==="master_consumable"&&<span className='attrstyle'>{item.consumable_detail.color!==""&&item.consumable_detail.color!==null&&item.consumable_detail.color!=="null"?<><i style={{color:item.consumable_detail.color, border:"1px solid black",borderRadius:"50%"}} class="fa fa-circle" aria-hidden="true"></i><span>&nbsp;&nbsp;</span><span>&nbsp;&nbsp;</span></>:""}
                    {item.consumable_detail.size!==""&&item.consumable_detail.size!==null&&item.consumable_detail.size!=="null"?<><span style={{border:"1px solid black"}}  aria-hidden="true">{item.consumable_detail.size}</span><span>&nbsp;&nbsp;</span><span>&nbsp;&nbsp;</span></>:""}
                    {item.consumable_detail.weight!==""&&item.weight!==null&&item.consumable_detail.weight!=="null"&&item.consumable_detail.weight!=="0"?<><span style={{border:"1px solid black"}}  aria-hidden="true">{item.consumable_detail.weight}Kg</span><span>&nbsp;&nbsp;</span><span>&nbsp;&nbsp;</span></>:""}
                    {item.consumable_detail.volume!==""&&item.volume!==null&&item.consumable_detail.volume!=="null"&&item.consumable_detail.volume!=="0"?<><span style={{border:"1px solid black"}}  aria-hidden="true">{item.consumable_detail.volume}L</span><span>&nbsp;&nbsp;</span><span>&nbsp;&nbsp;</span></>:""}</span>}
          </h6>
        ),
        mainservices: (
          <h6 style={{ color: item.is_active ? "#259BE9" : "silver" }}>
            {item.main_service_name}
          </h6>
        ),
        amount: (
          <h6 style={{ color: item.is_active ? "#259BE9" : "silver" }}>
            {item.amount}
          </h6>
        ),
        vat: (
          <h6 style={{ color: item.is_active ? "#259BE9" : "silver" }}>
            {item.vat}
          </h6>
        ),
        is_active: (
          <div>
            <Link 
              style={{
                pointerEvents:item.is_popular?"none":"",
                marginLeft: "25px",
                color: item.is_active ? "green" : "red",
              }}
              to="#"
              onClick={() => {
                const tokenString = sessionStorage.getItem("usertoken");

                let v2 = tokenString.replace(
                  /["]+/g,
                  ""
                ); /* REGEX To remove double quotes from tokenstring */

                const dataToSend = {
                  is_active: !item.is_active,
                };

                axios
                  .put(
                    `${APIURL}/api/v1/service-provider/hospital-service-detail/${item.id}/`,
                    dataToSend,
                    {
                      headers: { Authorization: "Token " + v2 },
                    }
                  )
                  .then((res) => {
                    if (res.data.status === "success") {
                      onSuccess();
                    }
                  })
              }}
            >
             <OverlayTrigger trigger="hover" rootClose placement="top" overlay={<Tooltip className='my-tooltip' id="button-tooltip">

{item.is_active?"Active" :"Deactive"}

</Tooltip>}>
<i class={item.is_active ?"fa fa-toggle-on":"fa fa-toggle-on fa-rotate-180 "} aria-hidden="true"></i>

</OverlayTrigger>
              
             
            </Link>
            <i
              class="fa fa-pencil "
              aria-hidden="true"
              style={{
                marginLeft: "25px",
                cursor: "pointer",
                color: "#5a9569",
              }}
              onClick={() => handleEditPopUp(item)}
            ></i>
            <span>&nbsp;&nbsp;</span>
            <i
              class="fa fa-trash-o "
              style={{ cursor: "pointer", color: "red", marginLeft: "25px" }}
              onClick={() => {
                handleDeletePopUp(item.id, item.service_name);
              }}
            ></i>

            <i
              class="fa fa fa-truck "
              style={{ cursor: "pointer", color: "blue", marginLeft: "25px" }}
              onClick={() => {
                handleShipCharge(item);
              }}
            ></i>
           {aggr_type==="non-aggregator"? <Link
              style={{
                pointerEvents:item.is_active?"":"none",
                marginLeft: "25px",
                color: item.is_popular ? "gold" : "silver"
              }}
              to="#"
              onClick={() => {

                const tokenString = sessionStorage.getItem("usertoken");

                let v2 = tokenString.replace(
                  /["]+/g,
                  ""
                ); /* REGEX To remove double quotes from tokenstring */

                const dataToSend = {
                  is_popular: !item.is_popular,
                };
                if (filteredList.length < 8 || item.is_popular===true) {
                  axios
                    .put(
                      `${APIURL}/api/v1/service-provider/hospital-service-detail/${item.id}/`,
                      dataToSend,
                      {
                        headers: { Authorization: "Token " + v2 },
                      }
                    )
                    .then((res) => {
                      if (res.data.status === "success") {
                        onSuccess();

                      }
                    })

                }
                else {
                  setErrorShow(true)
                }
              }}
            >
              <OverlayTrigger trigger="hover" rootClose placement="top" overlay={<Tooltip className='my-tooltip' id="button-tooltip">

                {item.is_popular?"Popular" :"Not Popular"}

              </Tooltip>}>
                <i
                  class="fa fa-star" aria-hidden="true"

                  style={{ cursor: "pointer" }}
                ></i>

              </OverlayTrigger>

            </Link>:""}

          </div>
        ),
      };
    });
  };

  const data = getServiceCol(subServiceList);

  return (
    <>

      <div className="patient-presc-sections patient-presc-sections2">
        <h5 className="text-center"><b>
          Services
        </b> <button  onClick={() => navigate("/promotions")}  className="web">Web
          </button></h5>
        <div className="table-holder padding1">

          <div className="service-table-holder" style={{ backgroundColor: "#efff001a", height: "34px" }}>

            <div class="row" style={{ textAlign: "left", paddingTop: "4px" }}>
              <div class="col-5">
                {/* <h5 className="" style={{ color: "var(--theme-blue)" }}><b> <i class="fa fa-th-large" aria-hidden="true"></i>{" "}Services</b></h5> */}
              </div>


              <div class="col">

              </div>
              <div class="col">
                <button onClick={() => navigate("/master-services")} type="button" style={{ border: "none", float: "right", textDecoration: "none" }} class=" btn-link"> Add New Services <i className="fa fa-plus"></i></button>

              </div>

            </div>
          </div>
          <ReactTable
            style={{
              color: "#259BE9",
              fontSize: "15px",

            }}
            minRows={10}
            data={data}
            columns={columns}
            defaultPageSize={10}
            className="service_table"
          />
        </div>
      </div>


      {deleteShow ? (
        <DeleteConfirmPopup
          show={deleteShow}
          onHide={() => {
            setDeleteShow(false);
            setDeleteIndex("");
          }}
        />
      ) : (
        ""
      )}
      {infoShow ? (
        <InfoPopup
          show={infoShow}
          onHide={() => {
            setinfoShow(false);
          }}
        />
      ) : (
        ""
      )}
      {errorShow ? (
        <ErrorPopup
          show={errorShow}
          onHide={() => {
            setErrorShow(false);
          }}
        />
      ) : (
        ""
      )}
      {editShow ? (
        <EditPopup
          show={editShow}
          onHide={() => {
            setEditShow(false);
            setEditIndex("");
          }}
        />
      ) : (
        ""
      )}
      {shipShow ? (
        <ShipPopup
          show={shipShow}
          onHide={() => {
            setShipShow(false);
            setShipIndex("");
          }}
        />
      ) : (
        ""
      )}
     
      {/* </LoadingOverlay> */}
    </>
  );
};

export default SelectedServices;
