import React, { useState, useEffect,useContext } from "react";
import service2 from "../../../assets/images/2.png";
import classes from "../favorite.module.css";
import {FaCartPlus } from "react-icons/fa";
import axios from "axios";
import { CartContext } from "../AddToCartContext";
import { FavDelete } from "../Context";  
import { AiOutlineCloseCircle } from "react-icons/ai";


function MyFavourites() {
  const { favDeletedList, setFavDeletedList } = useContext(FavDelete);
  const { handleAddToCart } = useContext(CartContext);
  const [favorite, setFavorite] = useState([]);
  const [search, setSearch] = useState('');
  const [errorMsg, setErrorMsg] = useState(false);
  const [logindatas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas"))
  );
  let favLength = 0;
  useEffect(() => {
    
    if (logindatas) {
      const userId = sessionStorage.getItem("userid");
      console.log("userid", userId);
      const localStorageKey = `myFavorite_${userId}`;
      const StoredValues = localStorage.getItem(localStorageKey);
      if (StoredValues) {
        const favorites = JSON.parse(StoredValues);
        setFavorite(favorites);
        favLength = favorites.length;
        console.log("fav items in health profile", favorites,favLength);
      } else {
        setFavorite(null);
      }
      setFavDeletedList(false);
     
    }
  }, [favDeletedList]);

  const addToCart = (item) => {
    // debugger;
    console.log("inside add to card p:id is ",item.p_id);
     axios
       .get(
         `https://uniapi.cianlogic.com/api/v1/patient/hospital-services-detail/${item.p_id}/`
         
       )
       .then((res) => {
         if (res.status === 200) {
          console.log("api response data",res.data.data);
      
        handleAddToCart(res.data.data);
        console.log("after fucnction call");
         } else {
         }
       })
       .catch((error) => {
         
       });
    // handleAddToCart(item);
     
   };
  return (
   
    <div>
      <div style={{ display: "flex", marginLeft:"23%" }}>
      <>
                          <div
                            className="search-webordering"
                            style={{
                              height: "45px",
                              marginTop: "-1px",
                              border: "1px solid #948686",
                              borderRadius: "40px",
                              backgroundColor: "white",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                marginTop: "1.2%",
                                marginLeft: "2%",
                                paddingRight: "1%",
                              }}
                            >
                              <input
                                type="text"
                                className=""
                                value={search}
                                placeholder="Search"
                                onChange={(e) => {setSearch(e.target.value)}}
                                style={{
                                  width: "500px",
                                  paddingLeft: "10px",
                                  border: "none",
                                  fontSize: "15px",
                                }}
                              />
                              <div
                                style={{
                                  paddingTop: "1.2%",
                                  paddingLeft: "0.2%",
                                }}
                                className="search-btn1"
                              >
                                {" "}
                                <AiOutlineCloseCircle style={{height:"25px",width:"25px",paddingBottom:"5px",paddingRight:"5px"}} onClick={() =>{setSearch('')}}/>
                            
                              </div>
                            </div>
                          </div>
                        </>
        
      </div>
      {favorite ? (
        <div className="row">
          {favorite.filter ((item) => {
            return search.toLowerCase() === '' ? item : item.p_name.toLowerCase().includes(search);
           })
         .map((favlist, index) => (
            <div
            className='col-6'
              style={{
                // height: "115px",
                width: "385px",
                border: "1px solid #007E85",
                borderRadius: "30px",
                padding: "20px",
                display: "flex",
                margin: "20px",
              }}
            >
              
              <div
                style={{ width: "55px", height: "55px", overflow: "hidden" }}
              >
                
                {favlist.p_category === "master_scan" || favlist.p_category === "master_procedure" || favlist.p_category === "master_labtest" || favlist.p_category === "misc" || favlist.service_category === "master_scan" || favlist.service_category === "master_procedure" || favlist.service_category === "master_labtest" || favlist.service_category === "misc" ? (
                    <img
                      src={service2}
                      alt="image"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <img
                      src={favlist.p_img || favlist.image}
                      alt="image"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  )}
              </div>
              <div className='col-6'>
                <b
                  style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: "600px",
                   
                  }}
                >
                  {favlist.p_name}
                </b>
                <p
                  style={{
                    fontFamily: "poppins",
                    fontSize: "12px",
                    fontWeight: "400px",
                  }}
                >
                  Amount: ${favlist.p_amount}
                </p>
                <div
                  style={{ display: "flex", color: "gold", fontSize: "12px" }}
                  className="products-review"
                >
                  <div className="rating">
                    <i className="bx bxs-star bx-lg"></i>
                    <i className="bx bxs-star"></i>
                    <i className="bx bxs-star"></i>
                    <i className="bx bxs-star"></i>
                  </div>
                  <a href="#" className="rating-count">
                    <small>4 stars</small>
                  </a>
                </div>
              </div>
              <div className='col-2' style={{ marginLeft: "65px"}}>
                <FaCartPlus
                  style={{
                    fontSize: "45px",
                    paddingTop: "15px",
                    color: "#007E85",
                  }}
                  onClick={() => {addToCart(favlist)}}
                />
              </div> 
            </div>
          ))}
          {favorite.filter((item) => {
      return search.toLowerCase() === '' ? item : item.p_name.toLowerCase().includes(search);
    }).length === 0 && <div style={{margin:"3% 0% 0% 23%"}}><p style={{fontSize:"24px",fontFamily:"Poppins"}}>No items found..!</p></div>}
        </div>
      
      ) : (
        <div>
          <p>No Favourite Items Listed!.</p>
        </div>
      )}
     
    </div>
    
  );
}

export default MyFavourites;
