import React, { useEffect, useState,useContext } from "react";
import axios from "axios";

import { Link, useNavigate, NavLink, useLocation,useParams  } from "react-router-dom";
import { APIURL } from "../../Global";
import useRazorpay from "react-razorpay";
import { Badge } from "react-bootstrap";
import { CartContext } from "./AddToCartContext";
import flag from "./assets/img/flag/us-flag.png";
import logo1 from "../../assets/images/felixa_logo.png";
import logo2 from "../../assets/images/logo_name.png";
import prod1 from "./assets/img/products-img1.jpg";
import prod2 from "./assets/img/products-img2.jpg";
import contact from "./assets/img/contact-1.png";
import PatientNav from "./PatientNav";
import PatientFooter from "./PatientFooter";
import prod5 from "./assets/img/products-img5.jpg";
import cat1 from "./assets/img/categories-img1.png";
import cat2 from "./assets/img/categories-img2.png";
import cat3 from "./assets/img/categories-img3.png";
import cat4 from "./assets/img/categories-img4.png";
import cat5 from "./assets/img/categories-img5.png";
import cat6 from "./assets/img/categories-img6.png";
import ban1 from "./assets/img/banner-img1.png";
import ban2 from "./assets/img/banner-img2.png";
import ban3 from "./assets/img/banner-img3.png";
import ban4 from "./assets/img/banner-categories-img1.jpg";
import ban5 from "./assets/img/banner-categories-img2.jpg";
import { useAlert } from "react-alert";

import logo3 from "./assets/img/intpurple.png";

function Checklist(props) {
  
  // const Razorpay = require('razorpay');
  const  checkoutCartId  =sessionStorage.getItem("checkout");
  const [loading, setLoading] = useState(false);
  const Razorpay = useRazorpay();
  const [cartItems, setCatItems] = useState([]);
  const [login_datas, setLogin_datas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas"))
      ? JSON.parse(sessionStorage.getItem("logindatas"))
      : null
  );
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);
  const [flag,setFlag]=useState(false)
  const [cartdetails, setCartDetails] = useState({});
  const alert = useAlert();
  const navigate = useNavigate();
  let CartIDs = [];
 // const checkoutCartId1 = checkoutCartId;
  const [checkId, setCheckId] = useState("");
  // const location = useLocation();
  // const checkoutCartId = new URLSearchParams(location.search).get(
  //   "checkout_cart_id"
  // );
  const { updateCartCount } = useContext(CartContext);
  const { checkout_cart_id } = useParams();
  const [selectedOption, setSelectedOption] = useState("");
  const [subtotal, setSubtotal] = useState(0);
  
  const getSubtotal = (items) => {
    return items.reduce(
      (acc, item) => acc + item.net_total_item_amount,
      0
    );
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handlePaymentGateway = (e) => {
    
    e.preventDefault();

    if (!selectedOption) {
      alert.error("Please select a payment option.");

    }
   else if (selectedOption === "delivery") {
      PayDelivery();
    
    } else if (selectedOption === "gateway") {
      Gateway();
    
    }
  };


  useEffect(() => {

      Cartpage();
    
  }, [checkoutCartId]);
  
  

  const Cartpage = () => {

    setLoading(true); // Show the loader
    
    const tokenString = sessionStorage.getItem("usertoken");
    let v2 = tokenString.replace(/["]+/g, "");
    axios
      .get(
        `${APIURL}/api/v1/patient/cart-checkout/?checkoutcart_id=${checkoutCartId}`,
        {
          headers: { Authorization: "Token " + v2 },
        }
      )
      .then((res) => {
        console.log(res.data);
        setCatItems(res.data.cart_orders);
        setCartDetails(res.data.cart_details);
        const subtotal = getSubtotal(res.data.cart_orders);
        setSubtotal(subtotal);
       
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false); // Hide the loader
      });
  };
  

  const Gateway = () => {
    
    const tokenString = sessionStorage.getItem("usertoken");
    let v2 = tokenString.replace(/["]+/g, "");
  
    const dataToSend = {
      checkoutcart_id: checkoutCartId,
    };
  
    axios
      .post(
        `${APIURL}/api/v1/payment/create-order/`,
        dataToSend,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + v2,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          const orderId = res.data.order_id;
  
          const options: RazorpayOptions = {
            key: "rzp_test_zFH7qgljaz9SDv",
            amount: subtotal,
            currency: "INR",
            name: "HorizonCommerce",
            image: logo2,
            order_id: orderId,
            handler: (res) => {
              const razorpayOrderId = res.razorpay_order_id;
              const razorpayPaymentId = res.razorpay_payment_id;
              const razorpaySignature = res.razorpay_signature;
              const receipt = null;
  
              const transactionData = {
                razorpay_order_id: razorpayOrderId,
                razorpay_payment_id: razorpayPaymentId,
                razorpay_signature: razorpaySignature,
                receipt: receipt,
              };
  
              axios
                .post(
                  `${APIURL}/api/v1/payment/razorpay-transaction/`,
                  transactionData,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: "Token " + v2,
                    },
                  }
                )
                .then((response) => {



                  const dataTosend = {
                    paid_amount: subtotal,
                     paid_status: "not_paid",
                     payment_mode: "cash",
                     checkoutcart_id: checkoutCartId,
                   };
               
                   axios
                     .post(
                       `${APIURL}/api/v1/patient/service-request-generation/`,
                       dataTosend,
                       {
                         headers: {
                           "Content-Type": "application/json",
                           Authorization: "Token " + v2,
                         },
                       }
                     )
                     .then((res) => {
                       if (res.data.status === "success") {
                        
                        cartItems.push({ checkoutcart_id: checkoutCartId });
                        sessionStorage.setItem("summarydetails", JSON.stringify(cartItems));
                        sessionStorage.removeItem("checkout");
                       // sessionStorage.setItem("addressdetails", JSON.stringify(cartdetails));
                        props.handleExpand(3)
                         alert.success("Thank You For Your Order");
                         updateCartCount();
                  
                        // sessionStorage.removeItem("checkout");
                       } else {
                         alert.error("Error");
                       }
                     })
                  // Handle the response from the razorpay-transaction API call
                  console.log(response);
                })
                .catch((error) => {
                  console.error(
                    "Failed to make razorpay-transaction API call:",
                    error
                  );
                });
            },
            prefill: {
              // name: "Piyush Garg",
              // email: "youremail@example.com",
              // contact: "9999999999",
            },
            notes: {
              // address: "Razorpay Corporate Office",
            },
            theme: {
              color: "#3399cc",
            },
          };
  
          const rzpay = new Razorpay(options);
          rzpay.open();
        }
      })
      .catch((error) => {
        console.error("Failed to fetch order ID:", error);
      });
  
    sessionStorage.setItem("summarydetails", JSON.stringify(cartItems));
  };
  
  


  const PayDelivery = () => {


    const tokenString = sessionStorage.getItem("usertoken");
    let v2 = tokenString.replace(/["]+/g, "");
    const dataTosend = {
     paid_amount: subtotal,
      paid_status: "not_paid",
      payment_mode: "cod",
      checkoutcart_id: checkoutCartId,
    };

    axios
      .post(
        `${APIURL}/api/v1/patient/service-request-generation/`,
        dataTosend,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + v2,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {

          
         
          cartItems.push({ checkoutcart_id: checkoutCartId });
          sessionStorage.setItem("summarydetails", JSON.stringify(cartItems));
          //sessionStorage.setItem("addressdetails", JSON.stringify(cartdetails));
        props.onSuccessSummery(true)
          console.log("summarydetails",cartItems)
         sessionStorage.removeItem("checkout");
          
          props.handleExpand(3)
          alert.success("Thank You For Your Order");
       
          updateCartCount();
          

          console.log("cartitems",cartItems)
         // sessionStorage.removeItem("checkout");
        } else {
          alert.error("Error");
        }
      })
      .catch((err) => {});
  };

  const handleDelete = (e) => {
    
    e.preventDefault();
 
      if (checkoutCartId) {
        const tokenString = sessionStorage.getItem("usertoken");
        let v2 = tokenString.replace(/["]+/g, "");
        axios
          .put(
            `${APIURL}/api/v1/patient/cart-checkout/?checkoutcart_id=${checkoutCartId}`,{},
            {
              headers: { Authorization: "Token " + v2 },
            }
          )
          .then((response) => {
            alert.error("Checkout cancelled");
            
            sessionStorage.removeItem("checkout");
            window.location.reload();
          })
          .catch((error) => {
            console.error("Error cancelling checkout", error);
          });
     
    };
  }

  // const subtotal = cartItems.reduce(
  //   (acc, item) => acc + item.net_total_item_amount,
  //   0
  // );
  // const shipping = cartItems.reduce(
  //   (acc, item) => acc + item.shipping_charge,
  //   0
  // );
  // const total = subtotal + shipping;


  return (
    <>
   
      <br />
      <br />

  
     

     <h3 style={{color:"#6F6F6F",marginTop:"1%"}}>Payment</h3>
   


      {/* <!-- Start Categories Area --> */}

      <section class="checkout-area ptb-70" style={{marginTop:"-5%"}}>
        <div class="container">
        
    {cartItems && cartItems.length >0 &&        
            
        <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "5%",
      }}
    >
      <label>
        <input
          type="radio"
          value="gateway"
          checked={selectedOption === "gateway"}
          onChange={handleOptionChange}
          style={{color:"#F0A639"}}
        /> &nbsp;&nbsp;
       <b> Payment Gateway </b> &nbsp;&nbsp;  <i class="fa fa-credit-card" aria-hidden="true" style={{fontSize:"20px",color:"#F0A639"}}></i>
        <h6 style={{fontSize:"12px",marginLeft:"15px"}}> Payment through Razorpay</h6>
      </label>
      
      <label>
        <input
          type="radio"
          value="delivery"
          checked={selectedOption === "delivery"}
          onChange={handleOptionChange}
          style={{color:"#F0A639"}}
        />  &nbsp;&nbsp; 
       <b> Cash on Delivery </b> &nbsp;&nbsp;  <i class="fa fa-handshake-o" aria-hidden="true" style={{fontSize:"20px",color:"#F0A639"}}></i>
        <h6 style={{fontSize:"12px",marginLeft:"15px"}}>Pay with cash on delivery</h6>
      </label>
      <br/>
      <div>
     {loading &&  <div>Loading...</div>} <button className="default-btn" style={{fontSize:"20px"}}   onClick={handlePaymentGateway}>
        Pay ${checkoutCartId ? subtotal.toFixed(2):""}
      </button> <button className="default-btn" style={{fontSize:"20px", backgroundColor:"#6C6C6C"}}  onClick={handleDelete} >Cancel</button>
   </div>
    </div>
              
       
           
           
   }
        
        </div>
      </section>
    </>
  );
}

export default Checklist;
