import React, { useEffect, useState,useContext } from "react";
import axios from "axios";
import { useAlert } from "react-alert";
import { Link, useNavigate, NavLink ,useLocation } from "react-router-dom";
import { APIURL } from "../../Global";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Modal } from "react-bootstrap";
import { Carousel } from 'react-bootstrap';
import NewArrivalProducrs from './NewArrivalProducrs';
import NewArrivals from "./NewArrivals";
import NewArrivalsDefault from "./NewArrivalsDefault";
import BestSelling from "./BestSelling";
import BestSellingProducts from "./BestSellingProducts";
import DealPromotions from "./Dealpromotions";
import DealPromotions1 from "./DealPromotions1";
import DealPromotions2 from "./DealPromotions2";
import { CartContext  } from "./AddToCartContext";
import "./assets/css/flaticon.css";
import "./assets/css/animate.min.css";
// import "./assets/css/owl.carousel.min.css";
import "./assets/css/boxicons.min.css";
import "./assets/css/meanmenu.min.css";
import "./assets/css/nice-select.min.css";
import "./assets/css/fancybox.min.css";
import "./assets/css/rangeSlider.min.css";
import "./assets/css/magnific-popup.min.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import san from "./assets/img/san.jfif";
import medicine from "./assets/img/medicine1.jfif";
import san2 from "./assets/img/san2.jfif";
import flag from "./assets/img/flag/us-flag.png";
import ban1 from "./assets/img/banner-img1.png"
import ban2 from "./assets/img/banner-img2.png"
import ban3 from "./assets/img/banner-img3.png"
import ban4 from "./assets/img/banner-categories-img1.jpg"
import ban5 from "./assets/img/banner-categories-img2.jpg"
import scan from "./assets/img/scanimage.png"
import cat2 from "./assets/img/categories-img2.png"
import cat3 from "./assets/img/categories-img3.png"
import cat4 from "./assets/img/categories-img4.png"
import cat5 from "./assets/img/categories-img5.png"
import procedure from "./assets/img/procedureimage.png"

import prod1 from "./assets/img/products-img1.jpg"
import prod2 from "./assets/img/products-img2.jpg"
import prod7 from "./assets/img/products-img7.jpg"
import prod8 from "./assets/img/products-img8.jpg"
import prod5 from "./assets/img/products-img5.jpg"
import prod6 from "./assets/img/products-img6.jpg"
import prod10 from "./assets/img/products-img10.jpg"
import prod11 from "./assets/img/products-img11.jpg"

import promo1 from "./assets/img/promotions-img1.jpg"
import promo2 from "./assets/img/promotions-img2.jpg"
import promo3 from "./assets/img/promotions-img3.jpg"

import nav1 from "./assets/img/navbar-img1.jpg"
import nav2 from "./assets/img/navbar-img1.jpg"

import bg from "./assets/img/bg2.jpg";
import PatientNav from "./PatientNav";
import PatientFooter from "./PatientFooter";
import brand1 from "./assets/img/brands-img1.png";
import brand2 from "./assets/img/brands-img2.png";
import brand3 from "./assets/img/brands-img3.png";
import brand4 from "./assets/img/brands-img4.png";
import brand5 from "./assets/img/brands-img5.png";
import brand6 from "./assets/img/brands-img6.png";

import blog1 from "./assets/img/blog-img1.jpg";
import blog2 from "./assets/img/blog-img2.jpg";
import blog3 from "./assets/img/blog-img3.jpg";

import user1 from "./assets/img/user-1.jpg";
import user2 from "./assets/img/user-2.jpg";
import user3 from "./assets/img/user-3.jpg";

import steth from "./assets/img/stethpng.png";

import logo1 from "../../assets/images/felixa_logo.png";
import logo2 from "../../assets/images/logo_name.png";


function WebOrdering({ setShowNavbarAndFooter }) {

    const [login_datas, setLogin_datas] = useState(
        JSON.parse(sessionStorage.getItem("logindatas"))
          ? JSON.parse(sessionStorage.getItem("logindatas"))
          : null
      );
    const navigate = useNavigate();

    const alert = useAlert();
    const {cartItems1} = useContext(CartContext);
    const [orgData, setOrgData] = useState({})
    const [featureList, setFeatureList] = useState([]);
    const [showCart, setShowCart] = useState(false)
    const [showWishList, setShowWishList] = useState(false)
    const [showDetails, setShowDetails] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [promotions, setPromotions] = React.useState([]);
  
    const [defaultBanner, setDefaultBanner] = React.useState([]);
    const [promotionDetails, setPromotionDetails] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [cartData, setCartData] = useState([]);
   



       const CartIDs = cartItems1.map((item) => {
            return item.id;
          });
 

          useEffect(() => {
  
            const summaryDetails = sessionStorage.getItem("summarydetails");
            const  checkoutCartId  =sessionStorage.getItem("checkout");
            //const addressDetails = sessionStorage.getItem("addressdetails");
        
            if (summaryDetails) {
              sessionStorage.removeItem("summarydetails");
              //sessionStorage.removeItem("addressdetails");
            }
     if(checkoutCartId){
            sessionStorage.removeItem("checkout");
     }
          }, []);


    const [logoutConfirm, setLogoutConfirm] = useState(false);
    useEffect(() => { setShowNavbarAndFooter(false); return () => setShowNavbarAndFooter(true); }, [setShowNavbarAndFooter]);

    
  

    useEffect(() => {

        
        const storedCartItems = JSON.parse(sessionStorage.getItem("cartItems")) || [];
        const storedValue = sessionStorage.getItem("myVariable");
        if (login_datas && storedCartItems.length > 0 && storedValue) {
          const formattedCartItems = storedCartItems.map(item => ({
            item_service: item.item.item_service ? item.item.item_service : item.item.id,
            item_due_date: item.date,
            item_service_priority: "high",
            item_quantity: item.item_quantity || "1"
          }));
      
          const tokenStr = sessionStorage.getItem("usertoken");
          const v2 = tokenStr.replace(/["]+/g, "");
      
          const dataToSend = {
            cart_items: formattedCartItems
          };
      
          axios
            .post(`${APIURL}/api/v1/patient/add-to-cart/`, dataToSend, {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + v2
              }
            })
            .then(res => {
              if (res.data.status === "success") {  

                navigate("/webordering")
                sessionStorage.removeItem("cartItems");
                sessionStorage.removeItem("myVariable");

                
                // const dataTosend = {
                //     cart_ids: CartIDs.length !== 0 ? [...res.data.cart_ids, ...CartIDs] : res.data.cart_ids
                //   };

                //  const dataTosend = {
                //    cart_ids:  res.data.cart_ids
                //   };
                  
                // axios
                //   .post(`${APIURL}/api/v1/patient/cart-checkout/`, dataTosend, {
                //     headers: {
                //       "Content-Type": "application/json",
                //       Authorization: "Token " + v2,
                //     },
                //   })
                //   .then((res) => {
                //     // if (res.data.status === "success") {
                      
      
                //     // //   navigate(
                //     // //     `/checklist?checkout_cart_id=${res.data.checkout_cart_id}`
                //     // //   );

                //     //   alert.show("Please do the Payments to complete the order!!!");

                //     //   // Clear cartItems from session storage
                //     //   sessionStorage.removeItem("cartItems");
                      
                //     // } else {
                //       alert.error(res.data.message);
                //     }
                //   })
                //   .catch((err) => {
                //     // setErrorMsg("Error in submission");
                //   });
              } else {
                alert.error(res.data.message);
              }
            })
            .catch(err => {
              // Handle any errors that occur during the API request
            });
        }
      }, [login_datas]);
      

      
    const updateCartData = () => {
        const tokenString = sessionStorage.getItem("usertoken");


        let v2 = tokenString.replace(
            /["]+/g,
            ""
        );
        // Make API call to retrieve updated cart data
        axios.get(`${APIURL}/api/v1/patient/cart/`, 
        {
          headers: { Authorization: "Token " + v2 },
        }
        )
          .then((res) => {
            setCartData(res.data.cart_items);
          })
          .catch((err) => {
            console.log(err);
          });
      }

    useEffect(() => {
   
        // const tokenString = sessionStorage.getItem("usertoken");
    
        // let v2 = tokenString.replace(
        //   /["]+/g,
        //   ""
        // ); /* REGEX To remove double quotes from tokenstring */
    
        axios
          .get(`${APIURL}/api/v1/patient/hospital-services/`,
        //    {
        //     headers: { Authorization: "Token " + v2 },
        //   }
          )
          .then((res) => {
            //console.log(res);
            if (res.status == 200) {
            
              const datas = res.data.message;
              console.log(datas);
              setFeatureList(datas);
              //console.log(featureList)
            } else {
              //setLoading(false);
            }
          })
          .catch((err) => {
           // setLoading(false);
          });
        }, []);

        useEffect(() => {
   
            // const tokenString = sessionStorage.getItem("usertoken");
        
            // let v2 = tokenString.replace(
            //   /["]+/g,
            //   ""
            // ); /* REGEX To remove double quotes from tokenstring */
        
            axios
              .get(`${APIURL}/api/v1/patient/banner-view/`,
            //    {
            //     headers: { Authorization: "Token " + v2 },
            //   }
              )
              .then((res) => {
                //console.log(res);
                if (res.status == 200) {
                
                    // const dealPromotions = res.data.details.filter(
                    //     (promo) => promo.promo_type === "new_arrival"
                    //   );
                 // console.log(datas);
                  setDefaultBanner(res.data.data);
                  //console.log(featureList)
                } else {
                  //setLoading(false);
                }
              })
              .catch((err) => {
               // setLoading(false);
              });
            }, []);


        useEffect(() => {
   
            // const tokenString = sessionStorage.getItem("usertoken");
        
            // let v2 = tokenString.replace(
            //   /["]+/g,
            //   ""
            // ); /* REGEX To remove double quotes from tokenstring */
        
            axios
              .get(`${APIURL}/api/v1/patient/list-promotions/?promo_type=new_arrival`,
            //    {
            //     headers: { Authorization: "Token " + v2 },
            //   }
              )
              .then((res) => {
                //console.log(res);
                if (res.status == 200) {
                
                    // const dealPromotions = res.data.details.filter(
                    //     (promo) => promo.promo_type === "new_arrival"
                    //   );
                 // console.log(datas);
                  setPromotions(res.data.details);
                  //console.log(featureList)
                } else {
                  //setLoading(false);
                }
              })
              .catch((err) => {
               // setLoading(false);
              });
            }, []);

            useEffect(() => {
                const fetchData = async () => {
                //   const tokenString = sessionStorage.getItem("usertoken");
                //   let v2 = tokenString.replace(/["]+/g, "");
              
                  const promises = promotions.map((item) => {
                    return axios.get(
                      `${APIURL}/api/v1/patient/list-promotions/?promotion=${item.promotion_id}`,
                    //   {
                    //     headers: { Authorization: "Token " + v2 },
                    //   }
                    );
                  });
              
                  const responses = await Promise.all(promises);
              
                  const details = {};
                  responses.forEach((res, index) => {
                    if (res.status === 200) {
                      const datas = res.data.items;
                      details[promotions[index].promotion_id] = datas[0];
                    }
                  });
              
                  setPromotionDetails(details);
                  setIsLoading(false); // Set isLoading to false when both promotions and promotionDetails are loaded
                };
              
                if (promotions.length > 0) {
                    fetchData();
                  }
              }, [promotions]); // Add promotions as a dependency to re-run the effect when promotions changes
              
              

// const newArrivalPromotions = promotions.filter(p => p['promo_type'] === 'new_arrival');
// const bestsellingitems = promotions.filter(p => p['promo_type'] === 'best_selling');

        const handleAddToCart =() =>{

        }

        const handleQuickView = (item) => {
            setSelectedItem(item);
            setShowDetails(true);
          };

    const DetailPopup = (props) => {
        return (
            <Modal
                {...props}
                aria-labelledby="example-custom-modal-styling-title"
                backdrop="static"
                keyboard={false}
                dialogClassName="modal-80w"
            >
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body style={{ width: "96%" }}>
                    <div className=" productsQuickView" id="productsQuickView" >
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">


                                <div className="row align-items-center">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="products-image">
                                            <img src={props.data.image} alt="image" />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="products-content">
                                            <h6><a href="#"><b>{props.data.item_service_name}</b></a></h6><br />

                                            <div className="price">
                                                <span className="old-price">{props.data.amount}</span>
                                                <span className="new-price">{props.data.amount}</span>
                                            </div>

                                            <div style={{ display: "flex" }} className="products-review">
                                                <div className="rating">
                                                    <i className='bx bxs-star'></i>
                                                    <i className='bx bxs-star'></i>
                                                    <i className='bx bxs-star'></i>
                                                    <i className='bx bxs-star'></i>
                                                    
                                                </div>
                                                <a href="#" className="rating-count">4 stars</a>
                                            </div>

                                            <ul className="products-info">
                                                <li><span>Vendor:</span> <a href="#">Hospital name</a></li>
                                                <li><span>Availability:</span> <a href="#">In stock</a></li>
                                                <li><span>Products Type:</span> <a href="#">Product type</a></li>
                                            </ul>

                                       

                                 

                                            <div className="products-add-to-cart">
                                                <div className="input-counter">
                                                    <span className="minus-btn"><i className='bx bx-minus'></i></span>
                                                    <input type="text" min="1" value="1" />
                                                    <span className="plus-btn"><i className='bx bx-plus'></i></span>
                                                </div>

                                                <button type="submit" className="default-btn"><i className="flaticon-trolley"></i> Add to Cart</button>
                                            </div>

                                         
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div></div>
                </Modal.Body>
            </Modal>

        )
    }
  
    return (
        
        <div className="web-ordering">
            <PatientNav  cartData={cartData}/>
           
          <br/>
          <br/>
          <br/>
        
          
          
     <section style={{ display: "contents"}} className="home-slides owl-carousel owl-theme">
     {promotions.length > 0 ? (
                <OwlCarousel items={1}
                    className="owl-theme"
                    loop
                    nav
                    dots={false}
                    margin={8}
                    autoplay={true}
            navigation={true}
                 
                    autoplayTimeout={5000} // set autoplay timeout to 5 seconds
                >
                          {promotions.map((item) => {
        const description =
          promotionDetails?.[item.promotion_id]?.item_name;
        const price = promotionDetails?.[item.promotion_id]?.amount;
        const id = promotionDetails?.[item.promotion_id]?.amount
        const discount = promotionDetails?.[item.promotion_id]?.discount
        // Wait for promotionDetails to be fetched before rendering NewArrivals
        if (description  && price ) {
          return (
            <NewArrivals
              key={item.promotion_id}
              bannerUrl={item["promo_banner"]}
              data={item}
              description={description}
              price={price}
              discount={discount}s
              updateCartData={updateCartData}
              text={item.overlay_text}
              size={item.overlay_size}
              color={item.overlay_colour}
            />
          );
        } else {
          return null;
        }
      })}
                </OwlCarousel>
     ) :  <OwlCarousel items={1}
     className="owl-theme"
     loop
     nav
     margin={8}
  
     autoplayTimeout={1000} // set autoplay timeout to 5 seconds
 >
           {defaultBanner.map((item) => {
// const description =
// promotionDetails?.[item.promotion_id]?.item_name;
// const price = promotionDetails?.[item.promotion_id]?.amount;
// const id = promotionDetails?.[item.promotion_id]?.amount
// const discount = promotionDetails?.[item.promotion_id]?.discount
// Wait for promotionDetails to be fetched before rendering NewArrivals

return (
<NewArrivalsDefault
key={item.id}
bannerUrl={item["images"]}
data={item}
description={item.description}
price={0}
discount={0}
/>
);

})}
 </OwlCarousel>
    }
      
     </section>
     <br/>
    
      <DealPromotions2  setShowNavbarAndFooter={setShowNavbarAndFooter}/>

            <section className="categories-area pb-40" style={{marginTop:"-4%"}}>
                <div className="container">
                    <div className="section-title">
                        <h3>Categories</h3>
                    </div>

                    <div className="row">
                        <div className="col-lg-2 col-sm-4 col-md-4">
                            <div className="single-categories-box">
                                <img src={scan} alt="image" style={{height:"85px"}} />
                                <h3>Scan</h3>
                                <a href="/categories?categorynew=Scan"  className="link-btn"></a>
                            </div>
                        </div>

                       

                        <div className="col-lg-2 col-sm-4 col-md-4">
                            <div className="single-categories-box">
                                <img src={cat3} alt="image" />
                                <h3>Laboratory</h3>
                                <a href="/categories?categorynew=Lab" className="link-btn"></a>
                            </div>
                        </div>

                        <div className="col-lg-2 col-sm-4 col-md-4">
                            <div className="single-categories-box">
                                <img src={cat5} alt="image" />
                                <h3>Miscellaneous</h3>
                                <a href="/categories?categorynew=Misc" className="link-btn" ></a>
                            </div>
                        </div>
                        <div className="col-lg-2 col-sm-4 col-md-4">
                            <div className="single-categories-box">
                                <img src={procedure} alt="image" style={{height:"85px"}} />
                                <h3>Procedures</h3>
                                <a href="/categories?categorynew=Procedure" className="link-btn"  ></a>
                            </div>
                        </div>

                        <div className="col-lg-2 col-sm-4 col-md-4">
                            <div className="single-categories-box">
                                <img src={cat2} alt="image" />
                                <h3>Consumables</h3>
                                <a href="/categories?categorynew=Consumable" className="link-btn" ></a>
                            </div>
                        </div>

                        <div className="col-lg-2 col-sm-4 col-md-4">
                            <div className="single-categories-box">
                                <img src={cat4} alt="image" />
                                <h3>Pharmacy</h3>
                                <a href="/categories?categorynew=Medicine" className="link-btn" ></a>
                            </div>
                        </div>

                     

                      
                    </div>
                </div>
            </section>

            <section className="products-area pb-40" style={{marginTop:"-2%"}}>
     

              
                            
                        <BestSelling updateCartData={updateCartData}/>   
                   
            
    

            </section>


            <section className="banner-categories-area pt-70 pb-40" style={{marginTop:"-4%"}}>
       <DealPromotions/>
            </section>

            <section className="products-area pb-40" style={{marginTop:"-6%"}}>
<NewArrivalProducrs  updateCartData={updateCartData}/>
</section>


         <DealPromotions1/>

         <section className="products-area pb-40" style={{marginTop:"2%"}}>
     

              
                            
     <BestSellingProducts updateCartData={updateCartData}/>   




</section>

            <section className="brands-area pt-70 pb-40" style={{marginTop:"-6%"}}>
                <div className="container">
                    <div className="section-title">
                        <h3>Selling Brands</h3>
                    </div>

                    <div className="row align-items-center">
                        <div className="col-lg-2 col-sm-4 col-md-2 col-6">
                            <div className="single-brands-item">
                                <a href="#" className="d-block"><img src={brand1} alt="image"  /></a>
                            </div>
                        </div>

                        <div className="col-lg-2 col-sm-4 col-md-2 col-6">
                            <div className="single-brands-item">
                                <a href="#" className="d-block"><img src={brand2} alt="image" /></a>
                            </div>
                        </div>

                        <div className="col-lg-2 col-sm-4 col-md-2 col-6">
                            <div className="single-brands-item">
                                <a href="#" className="d-block"><img src={brand3} alt="image" /></a>
                            </div>
                        </div>

                        <div className="col-lg-2 col-sm-4 col-md-2 col-6">
                            <div className="single-brands-item">
                                <a href="#" className="d-block"><img src={brand4} alt="image" /></a>
                            </div>
                        </div>

                        <div className="col-lg-2 col-sm-4 col-md-2 col-6">
                            <div className="single-brands-item">
                                <a href="#" className="d-block"><img src={brand5} alt="image" /></a>
                            </div>
                        </div>

                        <div className="col-lg-2 col-sm-4 col-md-2 col-6">
                            <div className="single-brands-item">
                                <a href="#" className="d-block"><img src={brand6} alt="image" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="blog-area pb-40">
                <div className="container">
                    <div className="section-title">
                        <h3>Our Blog</h3>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="single-blog-post">
                                <div className="post-image">
                                    <a href="single-blog-1.html" className="d-block"><img src={blog1} alt="image" /></a>
                                </div>

                                <div className="post-content">
                                    <h3><a href="single-blog-1.html">A researcher is conducting research on coronavirus in the lab</a></h3>
                                    <ul className="post-meta align-items-center d-flex">
                                        <li>
                                            <div className="flex align-items-center">
                                                <img src={user1} alt="image" />
                                                <a href="#">Nathan Oritz</a>
                                            </div>
                                        </li>
                                        <li>06-06-2020</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="single-blog-post">
                                <div className="post-image">
                                    <a href="single-blog-1.html" className="d-block"><img src={blog2} alt="image" /></a>
                                </div>

                                <div className="post-content">
                                    <h3><a href="single-blog-1.html">You have to wash your hands for 20 seconds to keep yourself free</a></h3>
                                    <ul className="post-meta align-items-center d-flex">
                                        <li>
                                            <div className="flex align-items-center">
                                                <img src={user2} alt="image" />
                                                <a href="#">Randy Osborne</a>
                                            </div>
                                        </li>
                                        <li>05-06-2020</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                            <div className="single-blog-post">
                                <div className="post-image">
                                    <a href="single-blog-1.html" className="d-block"><img src={blog3} alt="image" /></a>
                                </div>

                                <div className="post-content">
                                    <h3><a href="single-blog-1.html">It is very important to wear proper clothing to keep yourself free</a></h3>
                                    <ul className="post-meta align-items-center d-flex">
                                        <li>
                                            <div className="flex align-items-center">
                                                <img src={user3} alt="image" />
                                                <a href="#">Patricia Marcus</a>
                                            </div>
                                        </li>
                                        <li>04-06-2020</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="facility-area bg-f7f8fa pt-70 pb-40">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 col-md-3 col-6">
                            <div className="single-facility-box">
                                <div className="icon">
                                    <i className="flaticon-free-shipping"></i>
                                </div>
                                <h3>Free Shipping</h3>
                                <p>Free shipping world wide</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6 col-md-3 col-6">
                            <div className="single-facility-box">
                                <div className="icon">
                                    <i className="flaticon-headset"></i>
                                </div>
                                <h3>Support 24/7</h3>
                                <p>Contact us 24 hours a day</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6 col-md-3 col-6">
                            <div className="single-facility-box">
                                <div className="icon">
                                    <i className="flaticon-secure-payment"></i>
                                </div>
                                <h3>Secure Payments</h3>
                                <p>100% payment protection</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6 col-md-3 col-6">
                            <div className="single-facility-box">
                                <div className="icon">
                                    <i className="flaticon-return-box"></i>
                                </div>
                                <h3>Easy Return</h3>
                                <p>Simple returns policy</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <br/>
<PatientFooter/>
          
          
           
          
            {showDetails ? (
                <DetailPopup
                    show={showDetails}
                    data={selectedItem}
                    onHide={() => {
                        setShowDetails(false);

                    }}
                />
            ) : (
                ""
            )}
        </div>
    )
} export default WebOrdering;