import React,{useState,useEffect,useContext} from 'react';
import "./HealthProfile.css";
import Card from 'react-bootstrap/Card';
import MyHealthPhysique from './MyHealthPhysique';
import axios from 'axios';
import { useNavigate,Link } from "react-router-dom";
import MyHealthVitalsGraph from './MyHealthVitalsGraph';
import {VitalDetails} from '../Context';
function MyHealthVitals() {
  const { vitalsName, setVitalsName } = useContext(VitalDetails);
  const navigate = useNavigate();
  const [accountState,setaccountState] = useState(true);
  const [vitalsData,  setVitalsData] = useState([]);
  const [showGraph, setShowGraph] = useState(false);
  let blood_sugar = 'Fasting';
  
  useEffect(() => {
    console.log("inside useeffect vitals");
    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')
    axios
      .get(
        `https://uniapi.cianlogic.com/api/v1/patient/recent-vitals`,
        {
          headers: {
            Authorization: 'Token ' + str
        
          },
        }
      )
      .then(function (response) {
        console.log("api response", response.data);
        if (response.status === 200) {
          console.log("success to retrive data");
          setVitalsData(response.data);
        }
      })
      .catch(function (error) {
        console.log("inside catch funv");
        if (error.response.status === 404) {
          console.log(error.response.data);
          setVitalsData(null);
        }
      });
   
  }, []);
  const navigateto = () =>{
     navigate("/vitals-Graph");
     setVitalsName('blood_pressure');
  }
  const navigatetoSpo2 = () =>{
    navigate("/vitals-Graph");
    setVitalsName('spo2');
 }
 const navigatetoTemperature = () =>{
  navigate("/vitals-Graph");
  setVitalsName('temperature');
}
const navigatetoPulse = () =>{
  navigate("/vitals-Graph");
  setVitalsName('pulse');
}
const navigatetoGlucose = () =>{
  navigate("/vitals-Graph");
  setVitalsName('glucose');
}
  const data = 'sample data';
  return (
    <div >
      <div style={{ marginLeft: "25%" }}>
        <button onClick={() => { setaccountState(true) }}
          className={accountState ? "book-appointment-btn" : "book-appointment-btn-n"}
          style={{ width: "25%", padding: "1%" }}>Vitals</button>
        <button
          className={accountState ? "book-appointment-btn-n" : "book-appointment-btn"}
          style={{ width: "25%", padding: "1%", marginLeft: "2%" }}
          onClick={() => { setaccountState(false) }}
        >
          Physique
        </button>
      </div>
        <br/>
      {accountState? (
        <div >
        <div className="row">
       <div className="col-md-4 col-sm-6" style={{padding:"2%",cursor:"pointer"}} onClick={navigatetoGlucose}>
       {/* <Link to={`/vitals-Graph/${data}`}> */}
            <Card  style={{ width: '100%',textAlign:"center",paddingTop:'8%',background:"#ff9701",border: "1px solid #fed699",borderRadius:"18px",boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)" }}>
      <Card.Body style={{background:"#fed699",margin:'0',borderBottomLeftRadius:"15px",borderBottomRightRadius:"15px"}}>
        <br/>
        <Card.Title style={{fontFamily:"poppins",fontWeight:"bold"}}>Blood Sugar</Card.Title>
       
        <Card.Text>
        {vitalsData && vitalsData.glucose && (
  <span>
    {(() => {
      if (vitalsData.glucose.test__test_name === 'glucose_fasting') {
        blood_sugar = 'Fasting:';
      } else if (vitalsData.glucose.test__test_name === 'glucose') {
        blood_sugar = 'Non-fasting:';
      } else {
        blood_sugar = 'Random:';
      }
    })()}
    <b style={{ fontFamily: "poppins" }}>{blood_sugar}</b>
  </span>
)}
          {vitalsData && vitalsData.glucose ?(<span><b style={{fontFamily:"poppins"}}>{vitalsData.glucose.result} mg/dL</b></span>):<span>---</span>}
          {vitalsData && vitalsData.glucose && (<p style={{fontFamily:"poppins"}}>{new Date(vitalsData.glucose.date_time).toLocaleString('en-US',{year:"numeric",month:"long",day:"numeric"})}</p>)}
        </Card.Text>
       
      </Card.Body>
    </Card>
        </div>
        <div className="col-md-4 col-sm-6" style={{padding:"2%",cursor:"pointer"}} onClick={navigateto}>
            <Card style={{ width: '100%',textAlign:"center",paddingTop:'8%',background:"#80d8fe",border: "1px solid #cbefff",borderRadius:"18px",boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)" }}>
      <Card.Body style={{background:"#cbefff",margin:'0',borderBottomLeftRadius:"15px",borderBottomRightRadius:"15px"}}>
        <br/>
        <Card.Title style={{fontFamily:"poppins",fontWeight:"bold"}}>Blood Pressure</Card.Title>
       
        <Card.Text>
        {vitalsData && vitalsData.blood_pressure ?(<span><b style={{fontFamily:"poppins"}}>{vitalsData.blood_pressure.result} mmHg</b></span>):<span>---</span>}
          {vitalsData && vitalsData.blood_pressure && (<p style={{fontFamily:"poppins"}}>{new Date(vitalsData.blood_pressure.date_time).toLocaleString('en-US',{year:"numeric",month:"long",day:"numeric"})}</p>)}
        </Card.Text>
       
      </Card.Body>
    </Card>
        </div>
        <div className="col-md-4 col-sm-6" style={{padding:"2%",cursor:"pointer"}} onClick={navigatetoPulse}>
            <Card style={{ width: '100%',textAlign:"center",paddingTop:'8%',background:"#e57373",border: "1px solid #f5c7c7",borderRadius:"18px",boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)" }}>
      <Card.Body style={{background:"#f5c7c7",margin:'0',borderBottomLeftRadius:"15px",borderBottomRightRadius:"15px"}}>
        <br/>
        <Card.Title style={{fontFamily:"poppins",fontWeight:"bold"}}>Pulse</Card.Title>
       
        <Card.Text>
        {vitalsData && vitalsData.pulse ?(<span><b style={{fontFamily:"poppins"}}>{vitalsData.pulse.result} bpm</b></span>):<span>---</span>}
          {vitalsData && vitalsData.pulse && (<p style={{fontFamily:"poppins"}}>{new Date(vitalsData.pulse.date_time).toLocaleString('en-US',{year:"numeric",month:"long",day:"numeric"})}</p>)}
        </Card.Text>
       
      </Card.Body>
    </Card>
        </div>
        </div>
        
        {/* second row */}
        <div className='row' style={{justifyContent:"center"}}>
        <div className='col-4' style={{padding:"2%",cursor:"pointer"}} onClick={navigatetoTemperature}>
            <Card style={{ width: '100%',textAlign:"center",paddingTop:'8%',background:"#8ac34a",border: "1px solid #d1e7b8",borderRadius:"18px",boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)" }}>
      <Card.Body style={{background:"#d1e7b8",margin:'0',borderBottomLeftRadius:"15px",borderBottomRightRadius:"15px"}}>
        <br/>
        <Card.Title style={{fontFamily:"poppins",fontWeight:"bold"}}>Temperature</Card.Title>
       
        <Card.Text>
        {vitalsData && vitalsData.temperature ?(<span><b style={{fontFamily:"poppins"}}>{vitalsData.temperature.result} &deg;C</b></span>):<span>---</span>}
          {vitalsData && vitalsData.temperature && (<p style={{fontFamily:"poppins"}}>{new Date(vitalsData.temperature.date_time).toLocaleString('en-US',{year:"numeric",month:"long",day:"numeric"})}</p>)}
        </Card.Text>
       
      </Card.Body>
    </Card>
        </div>
        <div className='col-4' style={{padding:"2%",cursor:"pointer"}} onClick={navigatetoSpo2}>
            <Card style={{ width: '100%',textAlign:"center",paddingTop:'8%',background:"#3f51b5",border: "1px solid #b1b9e0",borderRadius:"18px",boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)" }}>
      <Card.Body style={{background:"#b1b9e0",margin:'0',borderBottomLeftRadius:"15px",borderBottomRightRadius:"15px"}}>
        <br/>
        <Card.Title style={{fontFamily:"poppins",fontWeight:"bold"}}>SpO2</Card.Title>
       
        <Card.Text>
        {vitalsData && vitalsData.spo2 ?(<span><b style={{fontFamily:"poppins"}}>{vitalsData.spo2.result} %</b></span>):<span>---</span>}
          {vitalsData && vitalsData.spo2 && (<p style={{fontFamily:"poppins"}}>{new Date(vitalsData.spo2.date_time).toLocaleString('en-US',{year:"numeric",month:"long",day:"numeric"})}</p>)}
        </Card.Text>
       
      </Card.Body>
    </Card>
        </div>
        </div>
        </div>
       ) : (<MyHealthPhysique/>)} 
       {showGraph && <MyHealthVitalsGraph/>}
    </div>
  )
}

export default MyHealthVitals
