import React, { useState, useEffect,useContext } from "react";
import "./cart.css";
import { Link, useNavigate, NavLink } from "react-router-dom";
import axios from "axios";
import PatientNav from "./PatientNav";
import { APIURL } from "../../Global";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import DatePicker from "react-date-picker";
import LoadingOverlay from "react-loading-overlay";
import { MdFavorite } from "react-icons/md";
import { Badge } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useAlert } from "react-alert";
import cartempty from "./assets/img/cart.jpeg";
import SimpleBar from "simplebar-react";
import service2 from "../../assets/images/2.png";
import "simplebar-react/dist/simplebar.min.css";
import CartPage from "./CartPage";
import Checklist from "./Checklist";
import Addresspage from "./Addresspage";
import Summarypage from "./Summarypage";

function ProceedPage ({ setShowNavbarAndFooter }) {
  const [login_datas, setLogin_datas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas"))
      ? JSON.parse(sessionStorage.getItem("logindatas"))
      : null
  );
  const [isCartPageVisible, setCartPageVisible] = useState(true);
  const [isPaymentPageVisible, setPaymentPageVisible] = useState(true);
  const [isSummaryPageVisible, setSummaryPageVisible] = useState(true);
  const [checkoutCartId, setCheckoutCartId] = useState(null);
  const [summerydet, setSummeryDet] = useState(false);

    const [expand, setExpand] = useState(1);

    useEffect(() => {
        setShowNavbarAndFooter(false);
        return () => setShowNavbarAndFooter(true);
      }, [setShowNavbarAndFooter]);
      const onSuccess = (id) => {
        setCheckoutCartId(id)
      }
      const onSuccessSummery = (data) => {
        setSummeryDet(data)
      }
      const handleDetail = (index) => {
        setExpand(index);
        if (index === 1) {
          setCartPageVisible(!isCartPageVisible); // Toggle the visibility of cart page
          setPaymentPageVisible(false);
          setSummaryPageVisible(false);
        } else if (index === 2) {
          setCartPageVisible(false);
          setSummaryPageVisible(false);
          setPaymentPageVisible(!isPaymentPageVisible); // Toggle the visibility of payment page
        } else if (index === 3) {
          setSummaryPageVisible(!isSummaryPageVisible);
          setCartPageVisible(false);
          setPaymentPageVisible(false);
        } else {
          setCartPageVisible(true);
          setPaymentPageVisible(false);
          setSummaryPageVisible(false);
        }
      };
      
  
    return (
        <>
      <PatientNav />
      <br/>
      <br/>
        <section className="page-title-area" style={{ height: "100%", marginTop: "1%" }}>
        <div
          className="tabcart"
          style={{ cursor: "pointer", borderColor: "black" }}
          onClick={() =>checkoutCartId===null&& handleDetail(1)} // Pass the index 1 for Cart tab
        >
          <div className="feature-data">
          
          <h5 className="feature-title">
              <b>
                <h5 style={{ cursor:checkoutCartId===null? "pointer":"not-allowed",fontSize:"20px",textAlign:"left",marginTop:"0.5%" }}>
                  <b> <i class="fa fa-check-circle-o" aria-hidden="true" style={{backgroundColor:checkoutCartId===null?"#11C057":"gray",color:"white",marginLeft:"1px",borderRadius: "50%",padding:"5px"}}></i> &nbsp;&nbsp;Cart</b>
                  {/* <i
                  className={
                    expand === 1 ? "fa fa-angle-up fa-fw" : "fa fa-angle-down fa-fw"
                  }
                  style={{ color: "#6788bc",marginLeft:"88%"}}
                ></i> */}
                
                </h5>
               </b>
              
              </h5>
 
          </div>
        </div>
        {expand === 1 && (
       
            
          <>    
       {isCartPageVisible && (
        <CartPage handleExpand={handleDetail} onSuccess={onSuccess}  />
      )}
    
  
</>
)}
        <br />
        <br />

    

       { login_datas && (  <div
          className="tabcart"
          style={{ cursor: "pointer" }}
          onClick={() =>{checkoutCartId!==null&&!summerydet&& handleDetail(2)} }// Pass the index 2 for Payment tab
        >
          <div className="feature-data">
            <h5 className="feature-title">
              <b>
              <h5 style={{ cursor:checkoutCartId!==null&&!summerydet? "pointer":"not-allowed",fontSize:"20px",textAlign:"left",marginTop:"0.5%"}}>
                  <b> <i class="fa fa-check-circle-o" aria-hidden="true" style={{backgroundColor:checkoutCartId!==null&&!summerydet?"#11C057":"gray",color:"white",marginLeft:"1px",borderRadius: "50%",padding:"5px"}}></i> &nbsp;&nbsp;Payment</b>
               
                  {/* <i
                  className={
                    expand === 2 ? "fa fa-angle-up fa-fw" : "fa fa-angle-down fa-fw"
                  }
                  style={{ color: "#6788bc",marginLeft:"85%" }}
                ></i> */}
                </h5>
               
              </b>
            </h5>
          </div>
        </div>
    )}
        {expand === 2 && (
       
            <>
       {isPaymentPageVisible && (       
       <Checklist  handleExpand={handleDetail} onSuccessSummery={onSuccessSummery} />

    
      )}
     </>

)}


        <br />
        <br />

        { login_datas && ( 

        <div
          className="tabcart"
          style={{ cursor: "pointer" }}
          onClick={() =>summerydet&& handleDetail(3)} // Pass the index 3 for Summary tab
        >
          <div className="feature-data">
            <h5 className="feature-title">
              <b>
              <h5 style={{ cursor:summerydet? "pointer":"not-allowed",fontSize:"20px",textAlign:"left",marginTop:"0.5%"}}>
                  <b> <i class="fa fa-check-circle-o" aria-hidden="true" style={{backgroundColor:summerydet?"#11C057":"gray",color:"white",marginLeft:"1px",borderRadius: "50%",padding:"5px"}}></i> &nbsp;&nbsp;Summary</b>
               
                  {/* <i
                  className={
                    expand === 3 ? "fa fa-angle-up fa-fw" : "fa fa-angle-down fa-fw"
                  }
                  style={{ color: "#6788bc",marginLeft:"85%" }}
                ></i> */}
                </h5>
              
              </b>
            </h5>
          </div>
        </div>
        )}
        {expand === 3 && (
       
          <>  
              
      {  isSummaryPageVisible && ( <Summarypage   handleExpand={handleDetail}/>
    
      )}</>

)}
       
      

              
        </section>
  
     </>
     
    );
  }

export default ProceedPage