import { useState, useEffect } from "react"
import axios from "axios";
import { APIURL } from "../../../Global";
import { Button } from 'react-bootstrap';
function EditSelectedService({ onSuccess,editdata }) {
    
    
    const [amount, setAmount] = useState(editdata.amount)
    const [vat, setVAT] = useState(editdata.vat)
    const [discount, setDiscount] = useState(editdata.discount)
    const [description, setDescription] = useState(editdata.description)
    const [returnPeriod,setReturnPeriod]=useState(editdata.return_period)
    const [returnable, setReturnable] = useState(editdata.is_returnable)
    const [deliveryTime,setDeliveryTime]=useState(editdata.delivery_time_period)
    const [error, setError] = useState("")

  


    
    const handleChange = (e) => {
        e.preventDefault();
        setError("")
        const val = e.target.value;
        const key = e.target.name;
        if (key === "amount") {
            if(val>=10000000000){
                setError("Please enter valid amount")
            }else
            setAmount(val);
        }
        if (key === "vat") {
            if(val>100){
                setError("Please enter valid amount")
            }else
            setVAT(val);
        }
        if (key === "discount") {
            if(val>100){
                setError("Please enter valid amount")
            }else
                setDiscount(val);
        }
        if (key === "description") {
            setDescription(val);
        }
        if(key==="returnPeriod"){
            setReturnPeriod(val)
        }
        if(key==="deliveryTime"){
            setDeliveryTime(val)
        }
      
 
 
    };
    const handleChecked = () => {
        setReturnable(!returnable);
        if(returnable===false){
            setReturnPeriod(0)
        }
      
      };

    const editService = (e) => {
        e.preventDefault();


        const tokenString = sessionStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
        ); /* REGEX To remove double quotes from tokenstring */

        const dataToSend = {
            amount: amount,
            vat: vat,
            discount: discount,
            description: description,
            is_returnable:returnable,
            return_period:returnPeriod,
            delivery_time_period:deliveryTime

        }
        if(deliveryTime<1){
            setError("Please Enter Waiting Time Period")
          }
          else{
        axios
            .put(`${APIURL}/api/v1/service-provider/hospital-service-detail/${editdata.id}/`, dataToSend, {
                headers: { Authorization: "Token " + v2 },
            })
            .then((res) => {
                if (res.data.status === "success") {

                    onSuccess();
                    //setRefresh(true)
                    //setCompleteShow(true)
                    //setSuccessShow(false)
                } else {
                    setError(res.data.message)
                }
            })
            .catch((err) => {
                //setErrorShow(true)
            });
        // setSuccessShow(false)


        }



    }

    return (
        <div className="" >
            <div className="">

            <div className="title-of-tasks" style={{}}><h5>{editdata. service_name}</h5></div><br/>
                <form className="formFields" onSubmit={editService}>

                <div className="formField" style={{display:"flex"}}>
                        <label className="col-sm-4 service-label">*Amount<small>(Rs)</small>: </label>
                        <input
                            type="number"
                            name="amount"
                            min={0}
                            style={{width:"32%"}}
                            value={amount}
                            onChange={handleChange}
                            required
                        ></input>
                    </div>
                    <div className="formField" style={{display:"flex"}}>
                        <label className="col-sm-4 service-label">VAT<small>(%)</small>: </label>
                        <input
                            type="number"
                            min={0}
                            style={{width:"32%"}}
                            name="vat"
                            value={vat}
                            onChange={handleChange}
                        ></input></div>
                    <div className="formField" style={{display:"flex"}}>

                        <label className="col-sm-4 service-label">Discount<small>(%)</small>: </label>
                        <input
                            type="number"
                            min={0}
                            style={{width:"32%"}}
                            name="discount"
                            value={discount}
                            onChange={handleChange}
                        ></input></div>
                    {editdata.service_type !== "general_services" ?
                        <div className="formField " style={{ width: "95%" ,paddingLeft:"12px" }}>

                            <input type="checkbox"
                                id="returnable"
                                name="returnable"
                                value={returnable}
                                onChange={handleChecked}
                                checked={returnable}
                                style={{marginLeft:"6%"}}
                            />
                            <label style={{fontSize:"small"}}> Returnable</label>
                            
                            {returnable?<><label className=" service-label">Return Period<small>(Days)</small> :</label>
                            <input
                                type="text"
                                size="5"
                                name="returnPeriod"
                                value={returnPeriod}
                                onChange={handleChange}
                            ></input></>:null}
                        </div>:" "
                    }

                    <div className="formField" style={{display:"flex"}}>

                        <label className="col-sm-4 service-label">*Waiting Time Period<small>(Days)</small>: </label>
                        <input
                            type="number"
                            min={0}
                            style={{width:"32%",height:"32px"}}
                            name="deliveryTime"
                            value={deliveryTime}
                            onChange={handleChange}
                        ></input></div>
                    <div className="formField" style={{display:"flex"}}>
                        <label className="col-sm-4 service-label">Description: </label>
                        <textarea
                            type="text"
                            style={{width:"39%",height:"55px"}}
                            name="description"
                            value={description}
                            onChange={handleChange}
                        
                        ></textarea></div>




                 
                    <div className="form-heading title-of-tasks" >
                        <button className=" btn formFieldButton">Save Changes</button>
                        {error ? (
                            <div className="error-validation-msg">
                                {error}
                            </div>
                        ) : null}




                    </div>


                </form>

            </div>


        </div>

    )
}
export default EditSelectedService