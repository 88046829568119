import React, { useState, useEffect, useContext } from "react";
import "./cart.css";
import { Link, useNavigate, NavLink, withRouter } from "react-router-dom";
import axios from "axios";
import PatientNav from "./PatientNav";
import { APIURL } from "../../Global";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import DatePicker from "react-date-picker";
import LoadingOverlay from "react-loading-overlay";
import { MdFavorite } from "react-icons/md";
import { Badge } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useAlert } from "react-alert";
import cartempty from "./assets/img/cart.jpeg";
import SimpleBar from "simplebar-react";
import service2 from "../../assets/images/2.png";
import "simplebar-react/dist/simplebar.min.css";
import Addresspage from "./Addresspage";
import Checklist from "./Checklist";
import { CartContext } from "./AddToCartContext";
function CartPage(props) {
  const { handleDelete } = useContext(CartContext);
  const [cartItems, setCatItems] = useState([]);
  const [clickedItemId, setClickedItemId] = useState(null);
  const [storedCartItems, setStoredCartItems] = useState(() => {
    const cartItems = sessionStorage.getItem("cartItems");
    if (cartItems) {
      const parsedCartItems = JSON.parse(cartItems);
      const updatedCartItems = parsedCartItems.map((cartItem) => {
        if (!cartItem.item_quantity) {
          return { ...cartItem, item_quantity: "1" };
        }
        return cartItem;
      });
      return updatedCartItems;
    } else {
      return [];
    }
  });
  const [cartItems2, setCatItems2] = useState([]);
  const [deliveryDate, setDeliveryDate] = useState(new Date());
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [quantityChanged, setQuantityChanged] = useState(false);
  const [addressview, setAddressView] = useState(false);
  const [checkId, setCheckId] = useState("");
  const [addressList, setAddressList] = useState([]);
  const [addres, setAddress] = useState();

  let CartIDs = [];
  const todayDate1 = new Date();
  const navigate = useNavigate();
  const [updatedQuantity, setUpdatedQuantity] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const alert = useAlert();
  const [clickedItemIndex, setClickedItemIndex] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [carddata, setCartData] = useState({
    DueDate: "",
    quantity: "",
  });
  const [login_datas, setLogin_datas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas"))
      ? JSON.parse(sessionStorage.getItem("logindatas"))
      : null
  );

  const refreshPage = () => {
    window.location.reload(false);
  };
  // useEffect(() => {
  //   setShowNavbarAndFooter(false);
  //   return () => setShowNavbarAndFooter(true);
  // }, [setShowNavbarAndFooter]);

  const handleDeliveryClick = (index) => {
    
    setDatePickerOpen(true);
    setClickedItemIndex(index);
  };

  const DeleteItem = (item) => {
    handleDelete(item);
    // Additional logic or actions
  };
  const handleQuantityChange = (event, item) => {
    const newQuantity = event.target.value;
    setCatItems((prevCartItems) =>
      prevCartItems.map((cartItem) =>
        cartItem.id === item.id
          ? { ...cartItem, item_quantity: newQuantity }
          : cartItem
      )
    );
    event.target.value = newQuantity;
    setQuantityChanged(true);
    setSelectedItemId(item.id); // set the selected item's ID
  };

  const handleQuantityChangenew = (event, item) => {
    const newQuantity = event.target.value;
    setStoredCartItems((prevCartItems) =>
      prevCartItems.map((cartItem) =>
        cartItem.item.id === item.item.id ||
        cartItem.item.item_service === item.item.item_service
          ? { ...cartItem, item_quantity: newQuantity }
          : cartItem
      )
    );
    event.target.value = newQuantity;
    setQuantityChanged(true);
    setSelectedItemId(item.item.id); // set the selected item's ID
  };

  const handleApplyClickNew = (item) => {
    
    const updatedCartItems = storedCartItems.map((cartItem) => {
      if (
        cartItem.item.id === item.item.id ||
        cartItem.item.item_service === item.item.item_service
      ) {
        // Check if item_quantity exists in the cart item

        // Add the new item_quantity field and set the default quantity as 1
        return { ...cartItem, item_quantity: item.item_quantity };
      }
      return cartItem;
    });

    // Update sessionStorage with the updated cart items
    sessionStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
    alert.success("quantity added");
    // Reset the quantity changed state and selected item ID
    setQuantityChanged(false);
    setSelectedItemId(null);
  };

  const handleDateChange = (date, item) => {
    setCatItems((prevCartItems) =>
      prevCartItems.map((cartItem) =>
        cartItem.id === item.id
          ? { ...cartItem, item_due_date: date }
          : cartItem
      )
    );
    setDatePickerOpen(false);

    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");

    const data = {
      item_due_date: new Date(date).toISOString().split("T")[0],
    };

    axios
      .put(`${APIURL}/api/v1/patient/cart-detail/${item.id}/`, data, {
        headers: {
          Authorization: "Token " + str,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          alert.success("Date updated successfully!");
        } else {
          alert.error("Something went wrong. Please try again later.");
        }
      })
      .catch((err) => {
        // alert.error("");
      });
  };

  const handleLogin = (e) => {
    debugger
    
    sessionStorage.setItem("myVariable", "exampleValue");
    navigate("/loginpatient");
  };

  const DeleteCartData = (itemId) => {
    
    let storedCartItems = JSON.parse(sessionStorage.getItem("cartItems")) || [];

    // Find the index of the item with the matching ID
    const itemIndex = storedCartItems.findIndex(
      (item) => item.item.id === itemId || item.item.item_service === itemId
    );

    if (itemIndex !== -1) {
      // Remove the item from the array
      storedCartItems.splice(itemIndex, 1);

      // Update the cartItems in sessionStorage
      sessionStorage.setItem("cartItems", JSON.stringify(storedCartItems));
    }
    window.location.reload();
  };

  const handleApplyClick = (item) => {
    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");

    const data = {
      item_quantity: item.item_quantity,
    };

    axios
      .put(`${APIURL}/api/v1/patient/cart-detail/${item.id}/`, data, {
        headers: {
          Authorization: "Token " + str,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          alert.success("Quantity updated successfully!");
          setCatItems((prevCartItems) =>
            prevCartItems.map((cartItem) =>
              cartItem.id === item.id
                ? { ...cartItem, item_quantity: item.quantity }
                : cartItem
            )
          );
          Cartpage();
          setQuantityChanged(false);
        } else {
          alert.error("Something went wrong. Please try again later.");
        }
      })
      .catch((err) => {});
  };

  CartIDs = cartItems.map((item) => {
    return item.id;
  });

  const handleProceed = (item) => {
    

    if (item.length > 0 && addres.length > 0) {
      const tokenStr = sessionStorage.getItem("usertoken");

      let v2 = tokenStr.replace(/["]+/g, "");

      const dataTosend = {
        cart_ids: CartIDs,
        shipping_address: addres[0].id,
      };

      axios
        .post(`${APIURL}/api/v1/patient/cart-checkout/`, dataTosend, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + v2,
          },
        })
        .then((res) => {
          if (res.data.status === "success") {

           props.handleExpand(2); 
            alert.show("Please do the Payments to complete the order!!!");

            setCheckId(res.data.checkout_cart_id);
           // Pass checkoutCartId to handleExpand function
            //setCheckoutCartId(res.data.checkout_cart_id);
            props.onSuccess(res.data.checkout_cart_id)
            sessionStorage.setItem("checkout", res.data.checkout_cart_id);
            //setCheckoutCartId={checkId}
            // checkoutCartId=res.data.checkout_cart_id;
            // navigate(
            //   `/checklist?checkout_cart_id=${res.data.checkout_cart_id}`
            // );
            // const checkoutCartId = res.data.checkout_cart_id;
            // history.push(`/checklist/${checkoutCartId}`);

            // setModalShow(true);
          } else {
            alert.error(res.data.message);
          }
        })
        .catch((err) => {
          // setErrorMsg("Error in submission");
        });
    } else {
      alert.error("Purchase Items and Add Address before Place Order");
    }
  };
  const onSuccess = (data) => {
    setAddress([data])
    setAddressView(false)
   };
  function AddressPopUp(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          style={{ borderBottom: "none", height: "30px", marginTop: "2%" }}
        >
          <button
            style={{ backgroundColor: "white", marginLeft: "95%" }}
            variant="secondary"
            onClick={(props) => {
              setAddressView(false);
              setRefresh(!refresh);
            }}
          >
            <i
              class="fa fa-times-circle fa-2x"
              style={{ color: "#F0A639" }}
              aria-hidden="true"
            ></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Addresspage addList={addressList} address={addres} onSuccess={onSuccess} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }

  const handleCloseDatePicker = (item) => {
    // handleDateChange(item, item);

    setDatePickerOpen(false);
  };

  // const handleDelete = (item) => {

  //   const tokenString = sessionStorage.getItem("usertoken");

  //   let str = tokenString.replace(/["]+/g, "");

  //   axios
  //     .delete(`${APIURL}/api/v1/patient/cart-detail/${item}/`, {
  //       headers: {
  //         Authorization: "Token " + str,
  //         "Content-Type": "application/json",
  //       },
  //     })

  //     .then((res) => {
  //       if (res.status === 204) {
  //         alert.success("Deleted Successfully");
  //         // setModalShow(true);

  //         // setRefresh(!refresh)
  //         Cartpage();
  //       } else {
  //       }
  //     })
  //     .catch((err) => { });
  // };

  useEffect(() => {
    if (login_datas) {
      Cartpage();
    }
  }, [login_datas]);

  const Cartpage = () => {
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(/["]+/g, "");
    axios
      .get(`${APIURL}/api/v1/patient/cart/`, {
        headers: { Authorization: "Token " + v2 },
      })
      .then((res) => {
        const mergedItems = res.data.cart_items;

        setCatItems(mergedItems);
        console.log("cartitems", cartItems);
      })
      .catch((err) => {});
  };

  const handleDateChangenew = (date, item) => {
    
    const updatedCartItems = storedCartItems.map((cartItem) =>
      cartItem.item.id === item.item.id
        ? {
            ...cartItem,
            date: new Date(date.getTime() - date.getTimezoneOffset() * 60000)
              .toISOString()
              .split("T")[0],
          }
        : cartItem
    );

    setStoredCartItems(updatedCartItems);
    sessionStorage.setItem("cartItems", JSON.stringify(updatedCartItems));

    setDatePickerOpen(false);
    alert.success("Delivery date set successfully");
  };

  
  const calculateDistance = (lat1, lat2, lon1, lon2) => {
    debugger
    const toRadians = (degrees) => {
      return (degrees * Math.PI) / 180;
    };
  
    const R = 6371; // Radius of the earth in km
    const phi1 = toRadians(lat1);
    const phi2 = toRadians(lat2);
    const deltaPhi = toRadians(lat2 - lat1);
    const deltaLambda = toRadians(lon2 - lon1);
  
    const a =
      Math.sin(deltaPhi / 2) * Math.sin(deltaPhi / 2) +
      Math.cos(phi1) * Math.cos(phi2) * Math.sin(deltaLambda / 2) * Math.sin(deltaLambda / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in km
  
    return distance;
  };

  useEffect(() => {
    if (login_datas) {
      const tokenString = sessionStorage.getItem("usertoken");
      let v2 = tokenString.replace(/["]+/g, "");
  
      axios
        .get(`${APIURL}/api/v1/patient/shipping-address/`, {
          headers: { Authorization: "Token " + v2 },
        })
        .then((res) => {
          setAddressList(res.data.message);
          const filteredData = res.data.message.filter((x) => x.is_default === true);
          setAddress(filteredData);
  
          fetch(`https://nominatim.openstreetmap.org/search?q=${filteredData[0].pincode}&format=json`)
            .then((response) => response.json())
            .then((data) => {
              if (data.length > 0) {
                const { lat, lon } = data[0];
  
                cartItems.forEach((item) => {
                  console.log("YES THE COORDINATES", lat, item.sp_lat, lon, item.sp_lon);
                  const distance1 = parseFloat(calculateDistance(lat, item.sp_lat, lon, item.sp_lon)).toFixed(4);
  
                  const cart_items = [{
                    item_service: item.id,
                    item_quantity: item.item_quantity,
                    cart_id: item.id,
                    geo_distance: distance1,
                  }];
  
                  const dataToSend = {
                    cart_items: cart_items,
                  };
  
                  axios
                    .put(`${APIURL}/api/v1/patient/cart-updation/`, dataToSend, {
                      headers: { Authorization: "Token " + v2 },
                    })
                    .then((res) => {

                      refreshPage();
                      // Handle success response
                    })
                    .catch((err) => {
                      // Handle error
                    });
                });
              }
            })
            .catch((err) => {
              // Handle error
            });
        })
        .catch((err) => {
          // Handle error
        });
    }
  }, [refresh]);
  

  let price = cartItems.reduce((acc, item) => acc + item.total_item_amount, 0);
  let discount = cartItems.reduce((acc, item) => acc + item.discount_amount, 0);
  let deliveryCharge = cartItems.reduce(
    (acc, item) => acc + item.shipping_charge,
    0
  );
  let vat = cartItems.reduce((acc, item) => acc + item.vat_amount, 0);
  let total = price - discount + deliveryCharge + vat;
  let today = new Date();
  let year = today.getFullYear();
  let month = String(today.getMonth() + 1).padStart(2, "0");
  let day = String(today.getDate()).padStart(2, "0");
  let todayDate = `${year}-${month}-${day}`;

  let price1 = storedCartItems.reduce((acc, item) => {
    const quantity = parseInt(item.item_quantity, 10);
    if (!isNaN(quantity) && typeof item.item.amount === "number") {
      return acc + item.item.amount * quantity;
    }
    return acc;
  }, 0);

  let price2 = storedCartItems.reduce((acc, item) => {
    const quantity = parseInt(item.item_quantity, 10);
    if (!isNaN(quantity) && typeof item.item.amount === "number") {
      const itemPrice = item.item.amount * quantity;
      const discount = item.item.discount || 0; // Assuming the discount field is available on the item object
      const discountedPrice = itemPrice - (itemPrice * discount) / 100;
      return acc + discountedPrice;
    }
    return acc;
  }, 0);

  let discount1 = storedCartItems.reduce(
    (acc, item) => acc + item.item.discount,
    0
  );
  let vat1 = storedCartItems.reduce((acc, item) => {
    if (item.item && typeof item.item.vat === "number") {
      return acc + item.item.vat;
    }
    return acc;
  }, 0);

  const totalAmount = price2 + vat;

  return (
    <>
      <br />
      <br />
      <br />
      <br />

      {/* <section
        className="page-title-area"
        style={{ height: "20px", marginTop: "-7%" }}
      >
        <h1 style={{ color: "#6F6F6F", marginTop: "1%" }}>Cart</h1>
      </section> */}

      <section
        className="products-area ptb-70"
        style={{ marginTop: "-3%", backgroundColor: "#f7f8fa" }}
      >
        <div className="container">
          <div class="row">
            <div class="col-lg-8 col-md-6">
              <SimpleBar style={{ maxHeight: "700px", width: "750px" }}>
                {(!login_datas && storedCartItems.length === 0) ||
                (login_datas && cartItems.length === 0) ? (
                  <div
                    style={{
                      backgroundColor: "#f7f8fa",
                      marginTop: "1%",
                      color: "red",
                    }}
                    className="text-center"
                  >
                    {" "}
                    <img
                      src={cartempty}
                      alt="image"
                      className="cartimages"
                      style={{
                        backgroundColor: "#f7f8fa",
                        marginTop: "1%",
                        width: "500px",
                        height: "500px",
                        flex: "1 1 30%",
                        marginLeft: "4%",
                        borderColor: "white",
                      }}
                    />{" "}
                  </div>
                ) : (
                  <>
                    {login_datas &&
                      cartItems.map((item) => (
                        <>
                          <div className="cartcards">
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <img
                                src={
                                  item.order_type === "master_labtest"
                                    ? service2
                                    : item.order_type === "master_scan"
                                    ? service2
                                    : item.order_type === "master_medicine"
                                    ? item.image
                                    : item.order_type === "misc"
                                    ? service2
                                    : item.order_type === "master_procedure"
                                    ? service2
                                    : item.order_type === "master_consumable"
                                    ? item.image
                                    : ""
                                }
                                alt="image"
                                className="cartimages"
                                style={{
                                  backgroundColor: "#f7f8fa",
                                  marginTop: "4%",
                                  flex: "1 1 30%",
                                  marginLeft: "7%",
                                  height: "200px",
                                }}
                              />
                              <div
                                style={{ flex: "2 2 70%", marginLeft: "20px" }}
                              >
                                <div style={{ marginTop: "30px" }}>
                                  <h4 style={{ textAlign: "left" }}>
                                    {item.item_service_name}{" "}
                                  </h4>
                                </div>
                                <div style={{ marginTop: "5px" }}>
                                  <h6
                                    style={{
                                      fontSize: "11px",
                                      color: "#ACACAC",
                                      textAlign: "left",
                                    }}
                                  >
                                    {" "}
                                    {item.service_type}{" "}
                                    {item &&
                                      item.consumable_detail &&
                                      item.consumable_detail.model && (
                                        <>
                                          Model: {item.consumable_detail.model}
                                        </>
                                      )}
                                  </h6>
                                </div>
                                <div style={{ marginTop: "5px" }}>
                                  <h6
                                    style={{
                                      fontSize: "13px",
                                      color: "rgb(104 81 81)",
                                      textAlign: "left",
                                    }}
                                  >
                                    Seller: {item.hospital_name}{" "}
                                    {item.consumable_detail !== null && (
                                      <>
                                        <span>&nbsp;&nbsp;</span>{" "}
                                        <span>
                                          {item.consumable_detail.brand && (
                                            <>
                                              Brand:{" "}
                                              {item.consumable_detail.brand}
                                            </>
                                          )}
                                        </span>
                                      </>
                                    )}
                                  </h6>
                                </div>
                                <div style={{ marginTop: "5px" }}>
                                  <h6 style={{ textAlign: "left" }}>
                                    {item.total_item_amount !==
                                    item.total_item_amt_disc ? (
                                      <span className="price">
                                        <span
                                          className="old-price"
                                          style={{
                                            textDecoration: "line-through",
                                            color: "grey",
                                            fontWeight: "normal",
                                            fontFamily: "Inter",
                                            fontSize: "20px",
                                          }}
                                        >
                                          <i
                                            class="fa fa-usd"
                                            aria-hidden="true"
                                          ></i>
                                          {item.total_item_amount}
                                        </span>
                                        <span>&nbsp;</span>
                                        <span className="discounted-price">
                                          <b style={{ fontSize: "20px" }}>
                                            {" "}
                                            {item.total_item_amt_disc}
                                          </b>
                                        </span>
                                      </span>
                                    ) : (
                                      <>
                                        <span className="price">
                                          {" "}
                                          <i
                                            class="fa fa-usd"
                                            aria-hidden="true"
                                          ></i>
                                          <b style={{ fontSize: "20px" }}>
                                            {" "}
                                            {item.total_item_amount}{" "}
                                          </b>
                                        </span>
                                      </>
                                    )}{" "}
                                    <span>&nbsp;&nbsp;&nbsp;</span>
                                    <span style={{ color: "#33A20D" }}>
                                      {" "}
                                      <Badge
                                        pill
                                        variant="success"
                                        style={{ fontSize: "14px" }}
                                      >
                                        {item.discount_percent}% OFF!
                                      </Badge>{" "}
                                      <span>&nbsp;</span>
                                      <Badge
                                        pill
                                        variant="primary"
                                        style={{ fontSize: "14px" }}
                                      >
                                        {item.vat_percent}% VAT!
                                      </Badge>
                                    </span>
                                  </h6>
                                </div>

                                <h6 style={{ textAlign: "left" }}>
                                  {" "}
                                  {((item &&
                                    item.consumable_detail &&
                                    item.consumable_detail.color !== "null" &&
                                    item.consumable_detail.color !== "") ||
                                    (item &&
                                      item.consumable_detail &&
                                      item.consumable_detail.size !== "null" &&
                                      item.consumable_detail.size !== "") ||
                                    (item &&
                                      item.consumable_detail &&
                                      item.consumable_detail.volume !== null) ||
                                    (item &&
                                      item.consumable_detail &&
                                      item.consumable_detail.weight !==
                                        null)) && (
                                    <div>
                                      {item.consumable_detail.color !==
                                        "null" &&
                                        item.consumable_detail.color !== "" && ( // Check if color is not "null" or ""
                                          <>
                                            <b>Color:</b>{" "}
                                            <span
                                              style={{
                                                backgroundColor:
                                                  item.consumable_detail.color,
                                                width: "15px",
                                                height: "15px",
                                                border: "1px solid black",
                                                borderRadius: "50%",
                                                display: "inline-block",
                                              }}
                                            ></span>
                                            &nbsp;&nbsp;
                                          </>
                                        )}
                                      {item.consumable_detail.size !== "null" &&
                                        item.consumable_detail.size !== "" && ( // Check if size is not "null" or ""
                                          <>
                                            <b>Size:</b>{" "}
                                            {item.consumable_detail.size ===
                                            "small"
                                              ? "S"
                                              : item.consumable_detail.size ===
                                                "large"
                                              ? "L"
                                              : item.consumable_detail.size}
                                            &nbsp;&nbsp;
                                          </>
                                        )}
                                      <span>
                                        {item.consumable_detail.volume !==
                                          undefined &&
                                          item.consumable_detail.volume !==
                                            null &&
                                          item.consumable_detail.volume !==
                                            "" &&
                                          item.consumable_detail.volume !==
                                            "null" && (
                                            <>
                                              <b>Volume:</b>{" "}
                                              {item.consumable_detail.volume} L
                                            </>
                                          )}
                                        &nbsp;&nbsp;
                                        {item.consumable_detail.weight !==
                                          undefined &&
                                          item.consumable_detail.weight !==
                                            null &&
                                          item.consumable_detail.weight !==
                                            "" &&
                                          item.consumable_detail.weight !==
                                            "null" && (
                                            <>
                                              {item.consumable_detail.volume &&
                                                " "}
                                              <b>Weight:</b>{" "}
                                              {item.consumable_detail.weight} Kg
                                            </>
                                          )}
                                      </span>
                                    </div>
                                  )}{" "}
                                </h6>

                                <div style={{ marginTop: "6px" }}>
                                  <h6
                                    style={{
                                      fontSize: "16px",
                                      color: "#0876F7",
                                      textAlign: "left",
                                    }}
                                  >
                                    <i
                                      class="fa fa-truck"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    {item.item_due_date === todayDate ? (
                                      <a
                                        href="#"
                                        onClick={() =>
                                          handleDeliveryClick(item.id)
                                        }
                                      >
                                        Today
                                      </a>
                                    ) : (
                                      <span>
                                        <a
                                          href="#"
                                          onClick={() =>
                                            handleDeliveryClick(item.id)
                                          }
                                        >
                                          {new Date(
                                            item.item_due_date
                                          ).toLocaleDateString()}
                                        </a>
                                      </span>
                                    )}
                                    {datePickerOpen &&
                                      clickedItemIndex === item.id && (
                                        <DatePicker
                                          selected={item.item_due_date}
                                          onChange={(date) =>
                                            handleDateChange(date, item)
                                          }
                                          minDate={new Date()}
                                          dateFormat="dd/MM/yyyy"
                                          className="custom-datepicker"
                                          inline
                                          clearIcon={null}
                                        />
                                      )}
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                marginLeft: "45%",
                                marginTop: "-4%",
                              }}
                            >
                              <i
                                className="fa fa-trash"
                                aria-hidden="true"
                                onClick={() => DeleteItem(item.id)}
                                style={{
                                  marginTop: "1%",
                                  fontSize: "24px",
                                  color: "red",
                                  cursor: "pointer",
                                  marginLeft: "-12%",
                                }}
                              ></i>
                              <span>&nbsp;</span> <span>&nbsp;&nbsp;</span>
                              <div className="cartquantity">
                                {" "}
                                <h6
                                  style={{
                                    marginTop: "12%",
                                    marginLeft: "20%",
                                  }}
                                >
                                  <b style={{ fontFamily: "Inter" }}>Qty</b>
                                </h6>
                              </div>
                              <span>&nbsp;&nbsp;</span>
                              {item.order_type === "master_consumable" ||
                              item.order_type === "master_medicine" ? (
                                <>
                                  <input
                                    type="number"
                                    min="1"
                                    className="cartquantittyadd"
                                    value={item.item_quantity} // use the quantity property of the cart item
                                    onChange={(event) =>
                                      handleQuantityChange(event, item)
                                    } // pass the cart item to handleQuantityChange function
                                  />{" "}
                                </>
                              ) : (
                                <input
                                  type="number"
                                  min="1"
                                  disabled={true}
                                  className="cartquantittyadd"
                                  value={item.item_quantity} // use the quantity property of the cart item
                                  // pass the cart item to handleQuantityChange function
                                />
                              )}
                              {quantityChanged &&
                                selectedItemId === item.id &&
                                item.item_quantity >= "1" && (
                                  <a
                                    href="#"
                                    onClick={() => handleApplyClick(item)}
                                    style={{
                                      marginTop: "1%",
                                      marginLeft: "2%",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {" "}
                                    <b
                                      style={{
                                        fontSize: "15px",
                                        color: "blue",
                                        fontFamily: "Inter",
                                      }}
                                    >
                                      Apply
                                    </b>
                                  </a>
                                )}
                              <a
                                data-toggle="modal"
                                style={{
                                  display: "flex",
                                }}
                              >
                                <i
                                  style={{
                                    fontSize: "25px",
                                    color: "grey",
                                    marginLeft: "35%",
                                    marginTop: "18%",
                                  }}
                                  className="bx bx-heart"
                                ></i>
                              </a>
                            </div>
                            {/* <div style={{ marginTop: "20px" }}>
                                {" "}
                                <h6 style={{ fontSize: "14px" }}>
                              
                                 
                          
                                </h6>
                              </div> */}
                          </div>
                        </>
                      ))}
                    {!login_datas &&
                      storedCartItems.map((item) => (
                        <div className="cartcards">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginTop: "2%",
                            }}
                          >
                            <img
                              src={
                                item.item.service_category === "master_labtest"
                                  ? service2
                                  : item.item.service_category === "master_scan"
                                  ? service2
                                  : item.item.service_category ===
                                    "master_medicine"
                                  ? item.item.image
                                  : item.item.service_category === "misc"
                                  ? service2
                                  : item.item.service_category ===
                                    "master_procedure"
                                  ? service2
                                  : item.item.service_category ===
                                    "master_consumable"
                                  ? item.item.image
                                  : ""
                              }
                              alt="image"
                              className="cartimages"
                              style={{
                                backgroundColor: "#f7f8fa",
                                marginTop: "3%",
                                flex: "1 1 30%",
                                marginLeft: "5%",
                                height: "200px",
                              }}
                            />
                            <div
                              style={{ flex: "2 2 70%", marginLeft: "20px" }}
                            >
                              <div style={{ marginTop: "30px" }}>
                                <h4 style={{ textAlign: "left" }}>
                                  {" "}
                                  {item.item.service_name
                                    ? item.item.service_name
                                    : item.item.item_name
                                    ? item.item.item_name
                                    : item.item.item_service_name}
                                </h4>
                              </div>
                              <div style={{ marginTop: "5px" }}>
                                <h6
                                  style={{
                                    fontSize: "11px",
                                    color: "#ACACAC",
                                    textAlign: "left",
                                  }}
                                >
                                  {" "}
                                  {item.item.service_type
                                    ? item.item.service_type
                                    : item.item.service_category}


                                </h6>
                              </div>
                              <div style={{ marginTop: "5px" }}>
                                <h6
                                  style={{
                                    fontSize: "13px",
                                    color: "rgb(104 81 81)",
                                    textAlign: "left",
                                  }}
                                >
                                  Seller: {item.item.hospital_name}
                                </h6>
                              </div>
                              <div style={{ marginTop: "5px" }}>
                                <h6 style={{ textAlign: "left" }}>
                                  {item.item.amount !==
                                  item.item.amount - item.item.discount ? (
                                    <span className="price">
                                      <span
                                        className="old-price"
                                        style={{
                                          textDecoration: "line-through",
                                          color: "grey",
                                          fontWeight: "normal",

                                          fontSize: "20px",
                                        }}
                                      >
                                        <i
                                          class="fa fa-usd"
                                          aria-hidden="true"
                                        ></i>
                                        {item.item.amount}
                                      </span>
                                      <span>&nbsp;</span>
                                      <span className="discounted-price">
                                        <b style={{ fontSize: "20px" }}>
                                          {" "}
                                          {price2}
                                        </b>
                                      </span>
                                    </span>
                                  ) : (
                                    <>
                                      <span className="price">
                                        {" "}
                                        <i
                                          class="fa fa-usd"
                                          aria-hidden="true"
                                        ></i>
                                        <b style={{ fontSize: "20px" }}>
                                          {" "}
                                          {item.item.amount}{" "}
                                        </b>
                                      </span>
                                    </>
                                  )}{" "}
                                  <span>&nbsp;&nbsp;&nbsp;</span>
                                  <span style={{ color: "#33A20D" }}>
                                    {" "}
                                    <Badge
                                      pill
                                      variant="success"
                                      style={{ fontSize: "14px" }}
                                    >
                                      {item.item.discount}% OFF!
                                    </Badge>{" "}
                                    <span>&nbsp;</span>
                                    <Badge
                                      pill
                                      variant="primary"
                                      style={{ fontSize: "14px" }}
                                    >
                                      {item.item.vat}% VAT!
                                    </Badge>
                                  </span>
                                </h6>
                              </div>
                              {item.item.color || item.item.size || item.item.weight || item.item.volume ? (
  <span style={{ marginLeft: "-40%" }}>
    {item.item.color && (
      <span>
        <b>Color:</b> {item.item.color[0]} &nbsp;&nbsp;
      </span>
    )}
    {item.item.size && (
      <span>
        <b>Size:</b> {item.item.size[0]} &nbsp;&nbsp;
      </span>
    )}
    {item.item.weight && (
      <span>
        <b>Weight:</b> {item.item.weight[0]} kg &nbsp;&nbsp;
      </span>
    )}
    {item.item.volume && (
      <span>
        <b>Volume:</b> {item.item.volume[0]} L &nbsp;&nbsp;
      </span>
    )}
  </span>
) : null}


                              <div style={{ marginTop: "2px" }}>
                                <h6
                                  style={{
                                    fontSize: "15px",
                                    color: "#0876F7",
                                    textAlign: "left",
                                  }}
                                >
                                  <i class="fa fa-truck" aria-hidden="true"></i>{" "}
                                  {item.date === todayDate ? (
                                    <a
                                      href="#"
                                      onClick={() =>
                                        handleDeliveryClick(
                                          item.item.id
                                            ? item.item.id
                                            : item.item.item_service
                                        )
                                      }
                                    >
                                      Today
                                    </a>
                                  ) : (
                                    <span>
                                      <a
                                        href="#"
                                        onClick={() =>
                                          handleDeliveryClick(
                                            item.item.id
                                              ? item.item.id
                                              : item.item.item_service
                                          )
                                        }
                                      >
                                        {new Date(
                                          item.date
                                        ).toLocaleDateString()}
                                      </a>
                                    </span>
                                  )}
                                  {datePickerOpen &&
                                    (clickedItemIndex === item.item.id ||
                                      clickedItemIndex ===
                                        item.item.item_service) && (
                                      <DatePicker
                                        selected={item.date}
                                        onChange={(date) =>
                                          handleDateChangenew(date, item)
                                        }
                                        minDate={new Date()}
                                        dateFormat="dd/MM/yyyy"
                                        className="custom-datepicker"
                                        inline
                                        clearIcon={null}
                                      />
                                    )}
                                </h6>
                              </div>
                             
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              marginLeft: "38%",
                              marginTop: "-4%",
                            }}
                          >
                            <i
                              className="fa fa-trash"
                              aria-hidden="true"
                              onClick={() =>
                                DeleteCartData(
                                  item.item.id || item.item.item_service
                                )
                              }
                              style={{
                                marginTop: "1%",
                                fontSize: "24px",
                                color: "red",
                                cursor: "pointer",
                                marginLeft: "-3%",
                              }}
                            ></i>
                            <span>&nbsp;&nbsp;</span> <span>&nbsp;&nbsp;</span>
                            <div className="cartquantity">
                              {" "}
                              <h6
                                style={{ marginTop: "12%", marginLeft: "20%" }}
                              >
                                <b style={{ fontFamily: "Inter" }}>QTY</b>
                              </h6>
                            </div>
                            <span>&nbsp;&nbsp;</span>
                            {item.item.service_category ===
                              "master_consumable" ||
                            item.item.service_category === "master_medicine" ? (
                              <>
                                {" "}
                                <input
                                  type="number"
                                  min="1"
                                  className="cartquantittyadd"
                                  value={item.item_quantity} // use the quantity property of the cart item
                                  onChange={(event) =>
                                    handleQuantityChangenew(event, item)
                                  } // pass the cart item to handleQuantityChange function
                                />{" "}
                              </>
                            ) : (
                              <input
                                type="number"
                                min="1"
                                disabled={true}
                                className="cartquantittyadd"
                                value={item.item_quantity} // use the quantity property of the cart item
                                onChange={(event) =>
                                  handleQuantityChangenew(event, item)
                                }
                                // pass the cart item to handleQuantityChange function
                              />
                            )}
                            {quantityChanged &&
                              (item.item.id === selectedItemId ||
                                item.item.item_service === selectedItemId) &&
                              item.item_quantity >= "1" && (
                                <a
                                  href="#"
                                  onClick={() => handleApplyClickNew(item)}
                                  style={{
                                    marginTop: "1%",
                                    marginLeft: "2%",
                                    cursor: "pointer",
                                  }}
                                >
                                  {" "}
                                  <b
                                    style={{
                                      fontSize: "15px",
                                      color: "blue",
                                      fontFamily: "Inter",
                                    }}
                                  >
                                    Apply
                                  </b>
                                </a>
                              )}
                          </div>
                        </div>
                      ))}
                  </>
                )}
              </SimpleBar>
            </div>

            <div class="col-lg-4 col-md-6">
              {(!login_datas && storedCartItems.length === 0) ||
              (login_datas && cartItems.length > 0) ? (
                <div className="shipping" style={{ flex: "2 2 70%" }}>
                  <h6
                    style={{
                      color: "#000000",
                      marginLeft: "4%",
                      textDecoration: "underline",
                      textAlign: "left",
                    }}
                  >
                    <b>Shipping Address</b>
                  </h6>
                  <h6
                    style={{
                      fontSize: "15px",
                      marginLeft: "5%",
                      color: "#6F6F6F",
                      textAlign: "left",
                    }}
                  >
                    {addres && addres.length > 0 ? (
                      <>
                        <span>
                          {" "}
                          {addres[0].first_name} {addres[0].last_name}
                        </span>
                        <br />
                        {addres[0].apt_no !== null && (
                          <span>{addres[0].apt_no},</span>
                        )}
                        {addres[0].landmark !== null && (
                          <span> {addres[0].landmark},</span>
                        )}
                        {addres[0].city !== null && (
                          <span> {addres[0].city},</span>
                        )}
                        {addres[0].province !== null && (
                          <span> {addres[0].province},</span>
                        )}
                        {addres[0].pincode !== null && (
                          <span> {addres[0].pincode},</span>
                        )}
                        {addres[0].state !== null && (
                          <span> {addres[0].state}</span>
                        )}
                        <br />
                        {addres[0].mobile_number !== null && (
                          <span>Mobile:{addres[0].mobile_number}</span>
                        )}
                      </>
                    ) : (
                      "Please Add Address"
                    )}
                    <i
                      class="fa fa-pencil-square"
                      onClick={() => {
                        setAddressView(true);
                      }}
                      style={{ color: "#F0A639", marginLeft: "90%" }}
                      aria-hidden="true"
                    ></i>
                  </h6>
                </div>
              ) : (
                ""
              )}

              {login_datas && cartItems.length !== 0 ? (
                <div
                  className="placeorder"
                  style={{
                    flex: "2 2 70%",

                    marginTop: "5%",
                  }}
                >
                  <div style={{ marginLeft: "10%", marginTop: "10%" }}>
                    {" "}
                    <b>Price ({cartItems.length}-items) :</b>{" "}
                    <span style={{ marginLeft: "35%" }}>
                      <b>{price.toFixed(2)}</b>
                    </span>
                  </div>
                  <div style={{ marginLeft: "10%", marginTop: "5%" }}>
                    {" "}
                    <b>Discount :</b>{" "}
                    <span style={{ marginLeft: "50%" }}>
                      <b>{discount.toFixed(2)}</b>
                    </span>
                  </div>
                  <div style={{ marginLeft: "10%", marginTop: "5%" }}>
                    {" "}
                    <b>VAT :</b>{" "}
                    <span style={{ marginLeft: "60%" }}>
                      <b>{vat.toFixed(2)}</b>
                    </span>
                  </div>
                  <div style={{ marginLeft: "10%", marginTop: "5%" }}>
                    {" "}
                    <b>Shipping Charge :</b>{" "}
                    <span style={{ marginLeft: "35%" }}>
                      <b>{deliveryCharge.toFixed(2)}</b>
                    </span>
                  </div>
                  <div style={{ marginLeft: "10%" }}>
                    {" "}
                    ----------------------------------------------------------------------
                  </div>
                  <div style={{ marginLeft: "10%", marginTop: "15%" }}>
                    {" "}
                    <b>Total :</b>{" "}
                    <span style={{ marginLeft: "55%" }}>
                      <i class="fa fa-usd" aria-hidden="true"></i>
                      <b>{total.toFixed(2)}</b>
                    </span>
                  </div>
                </div>
              ) : (
                <div
                  className="placeorder"
                  style={{
                    flex: "2 2 70%",

                    marginTop: "5%",
                  }}
                >
                  <div style={{ marginLeft: "10%", marginTop: "10%" }}>
                    {" "}
                    <b>Price ({storedCartItems.length}-items) :</b>{" "}
                    <span style={{ marginLeft: "35%" }}>
                      <b>{price1.toFixed(2)}</b>
                    </span>
                  </div>
                  <div style={{ marginLeft: "11%", marginTop: "5%" }}>
                    {" "}
                    <b>Discount :</b>{" "}
                    <span style={{ marginLeft: "50%" }}>
                      <b>{discount1.toFixed(2)}</b>
                    </span>
                  </div>
                  <div style={{ marginLeft: "11%", marginTop: "5%" }}>
                    {" "}
                    <b>VAT :</b>{" "}
                    <span style={{ marginLeft: "60%" }}>
                      <b>{vat1.toFixed(2)}</b>
                    </span>
                  </div>
                  {/* <div style={{ marginLeft: "10%", marginTop: "5%" }}>
                  {" "}
                  <b>Shipping Charge :</b>{" "}
                  <span style={{ marginLeft: "35%" }}>
                    <b>{deliveryCharge.toFixed(2)}</b>
                  </span>
                </div> */}
                  <div style={{ marginLeft: "10%" }}>
                    {" "}
                    ----------------------------------------------------------------------
                  </div>
                  <div style={{ marginLeft: "10%", marginTop: "15%" }}>
                    {" "}
                    <b>Total :</b>{" "}
                    <span style={{ marginLeft: "55%" }}>
                      <i class="fa fa-usd" aria-hidden="true"></i>
                      <b>{totalAmount.toFixed(2)}</b>
                    </span>
                  </div>
                </div>
              )}
              {login_datas ? (
                <button
                  className="placeorderbutton"
                  onClick={() => handleProceed(cartItems)}
                >
                  Place Order
                </button>
              ) : (
                <button
                  className="placeorderbutton"
                  onClick={() =>handleLogin()}
                  //onClick={() => navigate("/loginpatient")}
                >
                  Login/SignUp to Proceed
                </button>
              )}
            </div>
          </div>
        </div>
      </section>

      {addressview ? (
        <AddressPopUp
          show={addressview}
          onHide={() => {
            setAddressView(false);
          }}
        />
      ) : null}
      {/* {checkId && (
        <Checklist checkoutCartId={checkId} />
      )} */}
    </>
  );
}
export default CartPage;
