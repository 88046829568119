import React, { useState, useEffect } from "react"; import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { APIURL } from "../../Global";
import axios from 'axios';
function VerifyMobile(props) {
    
    
    const [otp, setOtp] = useState("")
    const [error, setError] = useState(false)
    const [method, setMethod] = useState("")
    const [login_datas, setLogin_datas] = useState(
        JSON.parse(sessionStorage.getItem("logindatas"))
            ? JSON.parse(sessionStorage.getItem("logindatas"))
            : null
    );
    const email = props.email;
    const phone = props.phone;
    const handleotp = (e) => {

        const val = e.target.value;
        const key = e.target.name;

        setOtp(val)
    };
    const handleChange = (e) => {
        e.preventDefault();
        const val = e.target.value;

        setMethod(val)



        /*Handle SENDOTP */


        let data = {};
        if (e.target.value === "Phone Number") {
            
         
            // let phoneNumber = props.phone;
            // console.log(phoneNumber)
            // let countryCodeLength = phoneNumber.length - 10;
            data = {
                code:"91",
               

                mobile_number:phone 
                
            };
        }

        axios
            .post(
                `${APIURL}/api/v1/account/send-otp/`,

                data,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((res) => {
                if (res.data.status === "success") {

                } else {


                }
            })
            .catch((err) => { });

    };
    const submitOTP = () => {
        

        let data = {}
        if (method === "Phone Number") {
            // let phoneNumber = props.phone;
            // console.log(phoneNumber)
            // let countryCodeLength = phoneNumber.length - 10;
            data = {
                code:"91",
               

                mobile_number:phone,
                otp:otp
            };
        }
        axios
            .post(
                `${APIURL}/api/v1/account/verify-otp/`,
                data,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((res) => {
                if (res.data.status === "success") {
                    props.onSuccess1(data);
                }
                else {

                    setError(true);
                }

            })

    };



    return (
        <div style={{backgroundColor:"aliceblue",marginRight:"4%"}} ><br/>
            <h6 className="title_config">Verify OTP </h6>
            <div style={{ justifyContent: "center" }}>
                <span style={{ marginLeft: "30%" }} className="text-style-here">Please select mobile to send otp{"  "}  </span>
                <select
                    className="boxpwdverify"
                    value={method}
                    onChange={handleChange}>
                    <option value="" selected hidden  >*Select</option>
                   
                    <option value="Phone Number">Phone</option>
                </select>
                {method !== "" ? <small style={{ marginLeft: "32%" }}>OTP has been shared to your registered mobile number</small> : ""}
            </div><br />

            <div className="" style={{ marginLeft: "35%" }} >
                <input value={otp} name="otp" type="text" className="boxpwd " onChange={handleotp}></input>
                {error? (
                                        <div
                                            style={{ zIndex: "auto", width:"237px" }}
                                            className="error-validation-msg"
                                        >
                                          Invalid OTP
                                        </div>
                                    ) : null}
            </div>
            <br />
            <div className="" style={{ display: "flex", justifyContent: "center" }}>

                <button onClick={() => submitOTP()} className="save">SUBMIT </button>
                <span>&nbsp;&nbsp;&nbsp;</span>
                <button onClick={() => props.onCancel1()} className="edit">CANCEL</button>

            </div><br /><br />

        </div>)
}
export default VerifyMobile;