import React, { useState } from 'react'

import Select from 'react-select';
import axios from "axios";
import { APIURL } from "../../../Global";
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import ReactTable from "react-table-v6";
import { useAlert } from 'react-alert'
function AddConsumable(props) {
  
  const alert = useAlert();
  const [color,setColor]=useState({ value:'', label:'' })
  const [size,setSize]=useState({ value:'', label:'' })
  const group3 = [
  { value:'red', label:'Red' },
  { value:'pink', label:'Pink' },
  {value:'orange', label:'Orange'},
  { value:'yellow', label:'Yellow'},
  { value:'blue', label:'Blue'},
 {value:'lightblue', label:'Light Blue' },
  { value:'darkblue', label:'Dark Blue'},
 {value:'violet', label:'Violet'},
 { value:'purple', label:'Purple' },
 {value:'green', label:'Green'},
 {value:'darkgreen', label:'Dark Green'},
 {value:'lightgreen', label:'Light Green'},
 { value:'black', label:'Black' },
  { value:'white', label:'White'},
 {value:'brown', label:'Brown'},
]
const group = [
  { value:'small', label:'Small' },
  { value:'medium', label:'Medium' },
  {value:'large', label:'Large'},
  { value:'xlarge', label:'X-Large'},
  
]
  let formData = new FormData();

  const [data, setData] = useState({
    consumablename: '',
    consumabletype: '',
    manufacturer: '',
    description: '',
    brand: "",
    volume:"",
    color: "",
    model: "",
    weight:"",
    size: "",
    imageUrl: null, // Store the selected file here
  })
  const [dataArray, setDataArray] = useState([])
  const [error, setError] = useState({
    consumablename: '',
    consumabletype: ''

  });
 
  const [image, setImage] = useState("")
  const [imageArray, setImageArray] = useState([])
const [show,setShow]=useState(false);
const [info,setInfo]=useState("")
const[isImg,setIsImg]=useState(false)

  

  const handleTitle = (e) => {
    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;
    setData(current => ({
      ...current, [key]: val
    }))
  }

 


  const validate = () => {
    let input = data;
    let errors = {};
    let isValid = true;
    setError({});


    if (!input["consumablename"] || input["consumablename"] === "") {
      isValid = false;
      errors["consumablename"] = "Please enter Consumable Name";
    }
    if (!input["consumabletype"] || input["consumabletype"].value === "") {
      isValid = false;
      errors["consumabletype"] = "Please enter Consumable Type";
    }


    setError(currentstate => ({
      ...currentstate,
      ...errors
    }));


    return isValid;
  }
  const renderTable = (cellInfo) => {
    return (
      <div style={{ overflow: "wrap" }}>{dataArray[cellInfo.index][cellInfo.column.id]}</div>
    )
  }
  const renderImage = (cellInfo) => {
    return (
      <div>{dataArray[cellInfo.index][cellInfo.column.id]!==null?<i  onClick={() => {
       setInfo(dataArray[cellInfo.index][cellInfo.column.id]);
       setIsImg(true)
       setShow(true)
       }}class="fa fa-picture-o" aria-hidden="true"></i>:""}</div>
    )
  }
  const renderDesc = (cellInfo) => {
    return (
      <div>{dataArray[cellInfo.index][cellInfo.column.id]!==""?<i onClick={() => {
        setInfo(dataArray[cellInfo.index][cellInfo.column.id]);
        setIsImg(false);
       setShow(true)
       }} class="fa fa-info-circle" aria-hidden="true"></i>:""}</div>
    )
  }

  const saveChanges = (e) => {
    e.preventDefault();


    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); /* REGEX To remove double quotes from tokenstring */
    console.log("formData", formData)
    dataArray.map((item, index) => {
      if(imageArray[index]!=="noimg"){
        formData.append(`consumables[${index}]image`, imageArray[index])
      }
      formData.append(`consumables[${index}]consumable_name`, item.consumablename.slice(0, 1).toUpperCase() + item.consumablename.slice(1, item.consumablename.length))
      formData.append(`consumables[${index}]type`, item.consumabletype)
      formData.append(`consumables[${index}]description`, item.description)
      formData.append(`consumables[${index}]size`, item.size)
      formData.append(`consumables[${index}]manufacturer`, item.manufacturer)
      formData.append(`consumables[${index}]brand`, item.brand)
      formData.append(`consumables[${index}]model`, item.model)
      formData.append(`consumables[${index}]weight`, item.weight)
      formData.append(`consumables[${index}]color`, item.color)
      formData.append(`consumables[${index}]volume`, item.volume)
    })
    console.log("formDataee", formData)
    // Object.keys(dataToSend).forEach(key => formData.append(key, dataToSend[key]));

    axios
      .post(`${APIURL}/api/v1/staff/master-consumables/`, formData, {
        headers: { Authorization: "Token " + v2 },
      })
      .then((res) => {
        if (res.data.status === "success") {
          alert.success("Added Successfully")
          props.modalClose()
        } else {
         alert.error("Error in submission")
        }
      })
      .catch((err) => {
        alert.error("Error in submission")
      });
  


  }
  const InfoPopup = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
         <Modal.Header style={{ borderBottom: "none", height: "30px", marginTop: "2%" }}>
          <button style={{ backgroundColor: "white", marginLeft: "95%" }} variant="secondary" onClick={props.onHide}>
            <i class="fa fa-times-circle fa-2x" style={{ color: "#F0A639" }} aria-hidden="true"></i>
          </button>
        </Modal.Header>
        <Modal.Body>
        <div className='text-area prod-div'>
         {isImg?<img src={info} alt="Image" style={{ maxWidth: "100%", maxHeight: "100%" }} />:info} 
        </div>
        </Modal.Body>
      </Modal>
    );
  };
  const deleteRowScanPre = (index) => {

    imageArray.splice(index, 1); 
    const data = dataArray;

    const filteredIndex = data.filter((item, pos) => {
      return pos !== index;
    });

    // setConfig(filtered)
    setDataArray(filteredIndex);
    
  };
  const handleImageUpload = (event) => {
    const selectedImage = event.target.files[0];
    const imageUrl = URL.createObjectURL(selectedImage);
    console.log("imageUrl", imageUrl)
    // Update the data state with the selected image URL
    setImage(selectedImage)
    setData((currentData) => ({
      ...currentData,
      imageUrl: imageUrl,
    }));
  };
  
  const columns = [
    {
      Header: "Actions",
      accessor: "actions",
      Cell: (props) => {
        return (
          <div style={{ marginLeft: "25%", color: "red" }}>
            <i
              className="fa fa-times-circle"
              onClick={() => {
                 deleteRowScanPre(props.index);
              }}
            ></i>
           

          </div>
        );
      },

      sortable: false,
      filterable: false,
      width: 60,
    },
    {
      Header: "Name",
      Cell: renderTable,
      accessor: "consumablename",
      width: 100,
      sortable: false,
    },
    {
      Header: "Type",
      accessor: "consumabletype",
      Cell: renderTable,
      width: 100,
      sortable: false,
    },
    {
      Header: "Manufacturer",
      accessor: "manufacturer",
      Cell: renderTable,
      sortable: false,
      width: 100,
    },
    {
      Header: "Brand",
      accessor: "brand",
      Cell: renderTable,
      sortable: false,
      width: 100,
    },
    {
      Header: "Model",
      accessor: "model",
      Cell: renderTable,
      sortable: false,
      width: 100,
    },
    {
      Header: "Size",
      accessor: "size",
      Cell: renderTable,
      sortable: false,
      width: 60,
    },
    {
      Header: "Weight",
      accessor: "weight",
      Cell: renderTable,
      sortable: false,
      width: 60,
    },
    {
      Header: "Volume",
      accessor: "volume",
      Cell: renderTable,
      sortable: false,
      width: 60,
    }, {
      Header: "Color",
      accessor: "color",
      Cell: renderTable,
      sortable: false,
      width: 100,
    }, {
      Header: "Image",
      accessor: "imageUrl",
      Cell:renderImage,
      //  ({ value }) => {
      //   return value ? <img src={value} alt="Image" style={{ maxWidth: "100%", maxHeight: "100%" }} /> : null;
      // },
      sortable: false,
      width: 60,
    },
    {
      Header: "Description",
      accessor: "description",
      Cell: renderDesc,
      sortable: false,
      width: 60,
    },

  ];

  const handleColorChange= (e) => {
    const val = e.target.value;
    const key = e.target.name;
   
   
    setData( current => ({
        ...current , color: val
    }))
}
const handleSizeChange= (e) => {
  const val = e.target.value;
  const key = e.target.name;
  
  setData( current => ({
      ...current , size: val
  }))
}
const removePicPreview = (e) => {
  e.preventDefault();
setImage("")
  setData((currentData) => ({
    ...currentData,
    imageUrl: "",
  }));
};
  return (
    <>
      <div style={{ marginTop: "-16px" }} className='prod-div'  ><h6 className="title-of-consumables" style={{ marginTop: '0' }}>Add Consumable</h6></div>

      <div style={{}} className=' cons-add'><br />
        <div className='row' style={{ paddingLeft: '2%', paddingRight: "2%" }}>
          <div className='col-4 grid-prop'>
            <small style={{ marginLeft: "3%" }}>Consumable Name</small><br />
            <input type='text' className='gridinputprop' name='consumablename' value={data.consumablename} onChange={handleTitle} />
          </div>
          <div className='col-2  grid-prop'>
            <small style={{ marginLeft: "3%" }}>Type</small><br />
            <input type='text' className='gridinputprop' name='consumabletype' value={data.consumabletype} onChange={handleTitle} />
          </div>
          <div className='col-6  grid-prop'>
            <small style={{ marginLeft: "3%" }}>Manufacturer</small><br />
            <input type='text' className='gridinputprop' name='manufacturer' value={data.manufacturer} onChange={handleTitle} />
          </div>
        </div>

        <div className='row' style={{ paddingLeft: '2%', paddingRight: "2%" }}>
          <div className='col-2 grid-prop'>
            <small style={{ marginLeft: "3%" }}>Brand</small><br />
            <input type='text' className='gridinputprop' name='brand' value={data.brand} onChange={handleTitle} />
          </div>
          <div className='col-2  grid-prop'>
            <small style={{ marginLeft: "3%" }}>Model</small><br />
            <input type='text' className='gridinputprop' name='model' value={data.model} onChange={handleTitle} />
          </div>
          <div className='col-2  grid-prop'>
            <small style={{ marginLeft: "3%" }}>Size</small><br />
            <select
                    value={data.size}
                    onChange={(data,e)=>{handleSizeChange(data,e)
                    }}
                    name='size'
                    className="gridinputprop "
                    
                  >
                     <option selected hidden style={{color:"#495057cf"}} ></option>
                                {group.map(item => (
                                    <><option value={item.value} >
                                        {item.label}
                                    </option></>
                                ))}
          </select>
          </div>
          <div className='col-2 grid-prop'>
            <small style={{ marginLeft: "3%" }}>Weight in Kg</small><br />
            <input type='text' className='gridinputprop' name='weight' value={data.weight} onChange={handleTitle} />
          </div>
          <div className='col-2  grid-prop'>
            <small style={{ marginLeft: "3%" }}>Volume in L</small><br />
            <input type='text' className='gridinputprop' name='volume' value={data.volume} onChange={handleTitle} />
          </div>
          <div className='col-2  grid-prop'>
            <small style={{ marginLeft: "3%" }}>Color</small><br />
            <select
                    value={data.color}
                    onChange={(data,e)=>{handleColorChange(data,e)
                    }}
                 
                  
                    name='color'
                    className="gridinputprop "
                  >
                     <option selected hidden style={{color:"#495057cf"}} ></option>
                                {group3.map(item => (
                                    <><option value={item.value} >
                                        {item.label}
                                    </option></>
                                ))}
                    </select>
            {/* <input type='text' className='gridinputprop' name='color' value={data.color} onChange={handleTitle} /> */}
          </div>
        </div>
        <div className='row' style={{ marginTop: "3%", paddingLeft: '2%', paddingRight: "2%" }}>
          <div className='col-9 grid-prop' >

            <textarea style={{ height: "125px", padding: "2%" }} type='text' placeholder='Description' className='gridinputprop' name='description' value={data.description} onChange={handleTitle} />
          </div>
          <div className='col-3  grid-prop'>
            {data.imageUrl ? (<>
              <div onClick={(e) => { removePicPreview(e ) }}>
              <i
                  style={{ position: "absolute" }}
                  className="fas fa-times-circle"
              ></i>
          </div>
              <img src={data.imageUrl} alt="Selected" style={{ maxWidth: '130px', marginTop: "3%", maxHeight: '130px', borderRadius: '5px', boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)' }} />
              </>
            ) : <div className="image-holder-div">
              <input

                name="img1"
                type="file"
                className="custom-file-input"
                id="validatedCustomFile1"
                onChange={handleImageUpload}
              />

              <label style={{ width: "100%" }} className="" htmlFor="validatedCustomFile1">


                <i style={{ marginLeft: "42%", marginTop: "17%", color: "#ADADAD", }} className="fa fa-picture-o fa-2x"></i><br />
                <span style={{ marginLeft: "35%", color: "#ADADAD", fontSize: "10px" }}></span>

              </label>

            </div>

            }
          </div>

        </div>


      </div>
      <div className='text-center'>
        <button onClick={() => {
          if(data.consumablename===""){
            alert.error("please Enter Product Name")
          }else if(data.consumabletype===""){
            alert.error('please Enter Type of the Product')
          }
          else{
            let n = dataArray.length;
            if (image !== "") {
              imageArray.push(image)
            }
            else {
              imageArray.push("noimg")
            }
  
            console.log("aaaaaaa", data)
            setDataArray([...dataArray, data]);
          // setColor({value:"Select",label:"Select"})
          // setSize({value:"Select",label:"Select"})
            setData({
              consumablename: '',
              consumabletype: '',
              manufacturer: '',
              description: '',
              brand: "",
              volume:"",
              color: "",
              model: "",
              weight: "",
              size: "",
              imageUrl: null
            })
         
            setImage("")
          }
        
        }} className='cons-button'>Add</button>

      </div>
     {dataArray.length>0?<> <div>
        <div style={{ marginBottom: "-18px" }} className='prod-div'  ><h6 className="title-of-page" style={{ marginTop: '0' }}>Preview</h6></div>
        <div className="flex-row">
          <ReactTable
            columns={columns}
            data={dataArray}
            defaultPageSize={5}
            style={{ overflow: "wrap", textAlign: "left" }}
            resizable
            className="service-req-table-consumables"
          ></ReactTable>
        </div>

      </div>
      <div className='text-center'>
        <button onClick={saveChanges} className='cons-button'>Submit</button>

      </div></>:""}


      {show ? (
        <InfoPopup
          show={show}
          onHide={() => {
            setShow(false);
          }}
        />
      ) : (
        ""
      )}
    

      

    </>
  )
}

export default AddConsumable
