import React, { useState,useEffect } from "react";
import "./HealthProfile.css";
import physique1 from "../../../assets/images/physique1.png";
import physique2 from "../../../assets/images/physique2.png";
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function MyHealthPhysique() {
  const [physiqueData, setPhysiqueData] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedBloodGroup, setSelectedBloodGroup] = useState("");
  const [updatedHeight, setUpdatedHeight] = useState('');
  const [updatedWeight, setUpdatedWeight] = useState('');
  const [validationError, setValidationError] = useState(false);
  const [validationErrorWidth, setValidationErrorWidth] = useState(false);
  const [refreshPhysiqueData,setRefresh] = useState(false);
  const [Usergender, setGender] = useState('male');
  let updatedWeightToApi = '';
  let updatedHeightToApi = '';
  let updatedBloodGToApi = '';
  let updatedBmiToApi = '';
  
  useEffect(() => {
    console.log("inside useeffect vitals");
    const tokenString = sessionStorage.getItem('usertoken');
    const gender = JSON.parse(sessionStorage.getItem('logindatas'));
    //console.log("gender 23323: " , gender.gender);
    setGender(gender.gender);
    let str = tokenString.replace(/["]+/g, '')
    axios
      .get(
        `https://uniapi.cianlogic.com/api/v1/patient/patient-bmi-calculation/?physique=true/`,
        {
          headers: {
            Authorization: 'Token ' + str
        
          },
        }
      )
      .then(function (response) {
        console.log("api response", response.data);
        if (response.status === 200) {
          console.log("success to retrive data");
          setPhysiqueData(response.data);
         
        }
      })
      .catch(function (error) {
        console.log("inside catch funv");
        if (error.response.status === 404) {
          console.log(error.response.data);
          setPhysiqueData(null);
        }
      });
      setRefresh(false);
  }, [refreshPhysiqueData]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleBloodGroup = (event) =>{
      setSelectedBloodGroup(event.target.value);
    };
    const handleHeight = (event) =>{
      event.preventDefault();
      if(event.target.value < 60 || event.target.value >260){
        setValidationError(true);
      }else{
        setValidationError(false);
        setUpdatedHeight(event.target.value);
      }
      
    };
    const handleWeight = (event) =>{
      event.preventDefault();
      if(event.target.value < 10 || event.target.value >200){
        setValidationErrorWidth(true);
      }else{
        setValidationErrorWidth(false);
        setUpdatedWeight(event.target.value);
      }
      
    };
   
    const handlePhysiqueUpdate = () =>{
      console.log("inside phy update");
      if(!validationError && !validationErrorWidth){
        console.log("values",updatedHeight,updatedWeight,selectedBloodGroup);
        if(updatedHeight !== ''){
          updatedWeightToApi = updatedWeight; 
        }else{
          updatedWeightToApi = physiqueData.weight;
        }
        if(updatedHeight !== ''){
          updatedHeightToApi = updatedHeight;
        }else{
          updatedHeightToApi = physiqueData.height;
        }
        if(selectedBloodGroup !== ''){
          updatedBloodGToApi = selectedBloodGroup;
        }else{
          updatedBloodGToApi = physiqueData.blood_group;
        }
        const hig = updatedHeightToApi / 100;
          const hig2 = hig * hig;
          updatedBmiToApi = updatedWeightToApi / hig2;
      
      console.log("updared values",updatedHeightToApi,updatedWeightToApi,updatedBloodGToApi);
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '')
    axios
      .post(
        `https://uniapi.cianlogic.com/api/v1/patient/patient-bmi-calculation/?physique=true/`,
        {
      
    weight:updatedWeightToApi,
    height:updatedHeightToApi,
    bmi: updatedBmiToApi,
    blood_group:updatedBloodGToApi
        },
        {
          headers: {
            Authorization: 'Token ' + str
        
          },
        }
      )
      .then(function (response) {
        console.log("api response", response.data);
        if (response.status === 200) {
          console.log("success to update data");
           setRefresh(true);
        }
      })
      .catch(function (error) {
        console.log("inside catch funv");
        if (error.response.status === 404) {
          console.log(error.response.data);
          
        }
      });
    };
    handleCloseS();
  }
    const handleCloseS = () =>{
      setShow(false);
      setValidationErrorWidth(false);
      setValidationError(false);
      setUpdatedHeight('');
      setUpdatedWeight('');
      setSelectedBloodGroup(null);
    }
  
  return (
    <div>
      
      <div style={{display:"flex"}}>
      <div style={{ marginLeft: "20%",textAlign:"center", }}>
        <div className="physiqueCards" style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
          <p className='pInPhysique'>Weight</p>
          {physiqueData && physiqueData.weight ?(<b className='bInPhysique'>{physiqueData.weight}</b>): <p>---</p>}
        </div>
        <div className="physiqueCards"style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
          <p className="pInPhysique">Height</p>
          {physiqueData && physiqueData.height ?(<b className='bInPhysique'>{physiqueData.height}</b>): <p>---</p>}
        </div>
        <div className="physiqueCards" style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
          <p className="pInPhysique">Blood Group</p>
          {physiqueData && physiqueData.blood_group ?(<b className='bInPhysique'>{physiqueData.blood_group}</b>): <p>---</p>}
        </div>
        <div className="physiqueCards" style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
          <p className="pInPhysique">BMI</p>
          {physiqueData && physiqueData.bmi ?(<b className='bInPhysique'>{physiqueData.bmi.toFixed(1)}</b>): <p>---</p>}
        </div>
      </div>
      <div>
        <div style={{width:"80%",height:"500px"}}>
        {Usergender === 'other' || Usergender === 'female' ?(<img
          src={physique2}
          alt="image"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />):(<img
          src={physique1}
          alt="image"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />)}
        </div>
      </div>
      
      </div>
      <div style={{textAlign:"center",color:"#007E85",textDecoration:"underline",fontFamily:"Poppins"}}>
      <a onClick={handleShow}>Edit Physique</a>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{fontFamily:"Poppins"}}>Edit Physique</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{display:"flex"}}>
          <div>
         <span>Height:</span><span><input type='text' className=' prof-input'style={validationError?{borderColor:"red"}:{}} name='height' onChange={handleHeight} /></span>
         </div>
         
         <div>
         <span>Weight:</span><span><input type='text' className=' prof-input'style={validationErrorWidth?{borderColor:"red"}:{}} name='weight' onChange={handleWeight}  /></span>
         </div>
         </div>
         <div>
          <label htmlFor="bloodGroup" style={{marginRight:"3%"}}>Blood Group:</label>
          <select id ="bloodGroup"value={selectedBloodGroup} className=' prof-input' style={{width:"60%",marginTop:"3%"}} onChange={handleBloodGroup}>
            <option value="">Select Blood Group</option>
            <option value="A+">A+ </option>
            <option value="A-">A-</option>
            <option value="B+">B+</option>
            <option value="B-">B-</option>
            <option value="AB+">AB+</option>
            <option value="AB-">AB-</option>
            <option value="O+">O+</option>
            <option value="O-">O-</option>
          </select>
         </div>
         {validationError || validationErrorWidth?<p style={{color:"red",fontFamily:"Poppins",fontSize:"14px"}}>Invalid Data!</p>:""}
        </Modal.Body>
        <Modal.Footer>
        <Button style={{background:"#007E85",fontFamily:"Poppins"}} onClick={handleCloseS}>Close</Button>
          <Button style={{background:"#007E85",fontFamily:"Poppins"}} onClick={handlePhysiqueUpdate}>Save</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default MyHealthPhysique;
