import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Link, useNavigate, NavLink, useLocation } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import { Modal } from "react-bootstrap";
import { MdFavorite } from "react-icons/md";
import { handleFavorites } from "./handleFav";
import classes from "./favorite.module.css";
import { Fav } from "../patient/Context";
import { FaHeart } from "react-icons/fa";
import { CartContext } from "./AddToCartContext";
import { FavDelete, FavFromQuickView } from "../patient/Context";
import PatientNav from "./PatientNav";
import Tab from "react-bootstrap/Tab";
import PatientFooter from "./PatientFooter";
import { APIURL } from "../../Global";
import { Badge, Button } from "react-bootstrap";
import { useAlert } from "react-alert";
import AddToCartBestSell from "./AddtoCartBestSell";

import QuickView from "./QuickView";

function DealsPage({ setShowNavbarAndFooter, updateCartData }) {
  const location = useLocation();
  const { handleAddToCart } = useContext(CartContext);
  const params = new URLSearchParams(location.search);
  const { favAdded, setFavAdded } = useContext(Fav);
  const { favDeletedList, setFavDeletedList } = useContext(FavDelete);
  const promoId = params.get("promo_id");
  const [distance, setDistance] = useState(0.0);
  const { favFromQuickView, setFavFromQuickView } =
    useContext(FavFromQuickView);
  const searchList = params.get("searchList");
  const [promotions, setPromotions] = React.useState([]);
  const [cartdata, setCartdata] = useState("");
  const [cartShow, setCartShow] = useState(false);
  const [favData, setFavData] = useState([]);
  const [favUpdated, setFavUpdated] = useState(false);
  const alert = useAlert();
  const [consumableShow, setConsumableShow] = useState(false);
  const [serviceShow, setServiceShow] = useState(false);
  const [quickViewDetails, setQuickViewDetails] = useState({});
  const [showDetails, setShowDetails] = useState(false);
  const [featureList, setFeatureList] = useState([]);
  const [featureList1, setFeatureList1] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [login_datas, setLogin_datas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas"))
      ? JSON.parse(sessionStorage.getItem("logindatas"))
      : null
  );

  // useEffect(() => { setShowNavbarAndFooter(false); return () => setShowNavbarAndFooter(true); }, [setShowNavbarAndFooter]);

  useEffect(() => {
    setShowNavbarAndFooter(false);
    return () => setShowNavbarAndFooter(true);
  }, [setShowNavbarAndFooter]);

  useEffect(() => {
    if (login_datas) {
      const userId = sessionStorage.getItem("userid");
      console.log("userid", userId);
      const localStorageKey = `myFavorite_${userId}`;
      try {
        const favoriteDatas = JSON.parse(localStorage.getItem(localStorageKey));
        //const parsedData = JSON.parse(favoriteDatas);
        setFavData(favoriteDatas);
      } catch (error) {
        let favoriteDatas = [];
      }

      setFavUpdated(false);
      setFavDeletedList(false);
      setFavFromQuickView(false);
    }
  }, [favUpdated, favDeletedList, favFromQuickView, login_datas]);

  const addToCart = (item) => {
    handleAddToCart(item);
    // Additional logic or actions
  };

  useEffect(() => {
    // const tokenString = sessionStorage.getItem("usertoken");

    // let v2 = tokenString.replace(
    //   /["]+/g,
    //   ""
    // ); /* REGEX To remove double quotes from tokenstring */

    axios
      .get(
        `${APIURL}/api/v1/patient/list-promotions/?promotion=${promoId}`
        // {
        //   headers: { Authorization: "Token " + v2 },
        // }
      )
      .then((res) => {
        //console.log(res);
        if (res.status == 200) {
          const datas = res.data.items;
          console.log(datas);
          setPromotions(datas);
          //console.log(featureList)
        } else {
          //setLoading(false);
        }
      })
      .catch((err) => {
        // setLoading(false);
      });
  }, []);

  // const handleAddToCart = (item) => {

  //   const today = new Date();
  //   const cartItem = {
  //     item: item,
  //     date: today.toISOString().split('T')[0] // Get today's date in YYYY-MM-DD format
  //   };

  //

  //   if (login_datas) {

  //     // const tokenString = sessionStorage.getItem("usertoken");

  //     // let v2 = tokenString.replace(
  //     //   /["]+/g,
  //     //   ""
  //     // );
  //     axios.get(`${APIURL}/api/v1/patient/service-provider-detail/?hospital_id=${item.hospital_id}`,
  //     // {
  //     //   headers: { Authorization: "Token " + v2 },
  //     // }
  //     )
  //       .then((res) => {
  //         console.log(res.data);
  //         let hospital = res.data.hosp_details;
  //         if (hospital.latitude && hospital.longitude) {
  //           console.log('YES THE COORDINATES', login_datas.latitude, hospital.latitude, login_datas.longitude, hospital.longitude);
  //           calculateDistance(login_datas.latitude, hospital.latitude, login_datas.longitude, hospital.longitude);
  //         }

  //       })

  //     const dataTosend = {

  //       item_service: item.item_service,
  //       item_due_date: new Date().toISOString().split("T")[0],
  //       item_service_priority: "critical",
  //       geo_distance: distance,
  //       item_quantity:"1"
  //     };

  //     axios
  //       .post(`${APIURL}/api/v1/patient/cart/`, dataTosend,
  //       // {
  //       //   headers: {
  //       //     "Content-Type": "application/json",
  //       //     Authorization: "Token " + v2,
  //       //   },
  //       // }
  //       )
  //       .then((res) => {
  //         if (res.data.status === "success") {
  //           alert.success("added to cart");
  //           updateCartData();
  //           //setTimeout(refreshPage, 1000);

  //         } else {
  //           alert.error(res.data.message);

  //         }
  //       })
  //       .catch((err) => {
  //         //setErrorMsg("Error in submission");
  //       });

  //   }

  //   else{
  //

  //      // If the user is not logged in, store the cart item locally
  //   const existingCartItems = JSON.parse(sessionStorage.getItem('cartItems')) || [];
  //   const updatedCartItems = [...existingCartItems, cartItem];
  //   sessionStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
  //   alert.success("added to cart");

  //   }
  // }

  const AddTohandleFavorites = (item) => {
    console.log("add handFav fun", item);
    const DataToFav = {
      p_id: item.item_service,
      p_name: item.item_name,
      p_amount: item.amount,
      p_img: item.image,
      p_category: item.service_category,
    };
    handleFavorites(DataToFav);
    console.log("after handleFavorites fun call");
    setFavUpdated(true);
    setFavAdded(true);
  };

  const calculateDistance = (latt1, latt2, long1, long2) => {
    var R = 6371; // Radius of the earth in km
    const lat1 = parseFloat(latt1);
    const lat2 = parseFloat(latt2);
    const lon1 = parseFloat(long1);
    const lon2 = parseFloat(long2);
    var dLat = deg2rad(lat2 - lat1); // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    // return d;
    setDistance(parseFloat(d).toFixed(4));
    console.log("THE DISTANCE", d);
  };
  const deg2rad = (deg) => {
    return deg * (Math.PI / 180);
  };

  const HandleClose = () => {
    setCartShow(false);
  };

  // const handleQuickView = (item) => {
  //   let id = item.item_service;

  //   const tokenString = sessionStorage.getItem("usertoken");

  //   let v2 = tokenString.replace(
  //     /["]+/g,
  //     ""
  //   ); /* REGEX To remove double quotes from tokenstring */

  //   axios
  //     .get(`${APIURL}/api/v1/patient/hospital-services-detail/${id}/`, {
  //       headers: { Authorization: "Token " + v2 },
  //     })
  //     .then((res) => {
  //       //console.log(res);
  //       if (res.status == 200) {
  //         const datas = res.data.data;
  //         console.log(datas);
  //         setSelectedItem(datas);
  //         setShowDetails(true);
  //         //console.log(featureList)
  //       } else {
  //         //setLoading(false);
  //       }
  //     })
  //     .catch((err) => {
  //       // setLoading(false);
  //     });
  // };

  const AddtoCartPopUp = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddToCartBestSell
            datas={props.data}
            handle={HandleClose}
            // stage={displaystage}
            // onHide={handleDiseaseClose}
            // submitduration={submitDurationHandle}
          />
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={props.onHide}>
                        Close
                      </Button> */}
        </Modal.Footer>
      </Modal>
    );
  };

  const handleQuickView = (item) => {
    setQuickViewDetails({
      amount: item.amount,
      discount: item.discount,
      hospital: item.hospital_id,
      main_service:
        item.service_category === "master_labtest"
          ? "Laboratory"
          : item.service_category === "master_scan"
          ? "Scan"
          : item.service_category === "master_medicine"
          ? "Medicine"
          : item.service_category === "misc"
          ? "Miscellaneous"
          : item.service_category === "master_procedure"
          ? "Procedure"
          : item.service_category === "master_consumable"
          ? "Consumables"
          : "",
      name: item.hospital_name,
      service: item.item_name,
      service_category: item.service_category,
      image:
        item.service_category === "master_labtest"
          ? ""
          : item.service_category === "master_scan"
          ? ""
          : item.service_category === "master_medicine"
          ? item.image
          : item.service_category === "misc"
          ? ""
          : item.service_category === "master_procedure"
          ? ""
          : item.service_category === "master_consumable"
          ? item.image
          : "",
      id: item.item_service,
    });

    setShowDetails(true);
  };

  const handleConsumable = (e) => {
    setConsumableShow(true);
    setServiceShow(false);
  };
  const handleService = (e) => {
    setServiceShow(true);
    setConsumableShow(false);
  };
  const DetailPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          style={{ borderBottom: "none", height: "30px", marginTop: "2%" }}
        >
          <button
            style={{ backgroundColor: "white", marginLeft: "95%" }}
            variant="secondary"
            onClick={props.onHide}
          >
            <i
              class="fa fa-times-circle fa-2x"
              style={{ color: "#F0A639" }}
              aria-hidden="true"
            ></i>
          </button>
        </Modal.Header>
        <Modal.Body style={{ width: "96%" }}>
          <QuickView
            item={quickViewDetails}
            handleRefreshCart={HandleClose}
            from="wo"
          />
        </Modal.Body>
      </Modal>
    );
  };
  const promotionFilter = promotions.filter(
    (p) =>
      p["service_category"] !== "master_consumable" &&
      p["service_category"] !== "master_medicine"
  );

  const promotionFilter1 = promotions.filter(
    (p) =>
      p["service_category"] === "master_consumable" ||
      p["service_category"] === "master_medicine"
  );

  return (
    <>
      <PatientNav />
      <br />
      <br />
      <section
        className="page-title-area"
        style={{ height: "20px", marginTop: "-4%" }}
      >
        <h1 style={{ color: "#6F6F6F", marginTop: "1%" }}>
          Deals {promotions.length > 0 ? `${promotions[0].discount}% Off` : ""}
        </h1>
      </section>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <button
          className="sideboxcart"
          style={{ marginTop: "6%", marginLeft: "3%", color: "white" }}
          onClick={(e) => handleConsumable(e)}
        >
          Products({promotionFilter1.length})
        </button>

        <button
          className="sideboxcart1"
          style={{ marginTop: "1%", marginLeft: "3%", color: "white" }}
          onClick={(e) => handleService(e)}
        >
          Services ({promotionFilter.length})
        </button>
      </div>

      <div className="scrolbar"></div>
      <section
        className="products-area ptb-70"
        style={{ marginTop: "-65%", marginLeft: "20%", marginBottom: "40%" }}
      >
        <div className="container">
          <div className="row">
            <div class="col-lg-9 col-md-12">
              <div class="drodo-grid-sorting row align-items-center">
                <div class="col-lg-6 col-md-6 result-count">
                  <p>
                    {promotionFilter.length === 0 &&
                      promotionFilter1.length === 0 && (
                        <span>No items found</span>
                      )}
                    {/* { serviceShow && promotionFilter1.length > 0 && promotionFilter.length === 0 &&
    <span>  No Service</span>
  } */}
                    {serviceShow && promotionFilter1.length > 0 && (
                      <span>{promotionFilter.length} Service</span>
                    )}
                    {consumableShow && promotionFilter1.length > 0 && (
                      <span>{promotionFilter1.length} Product</span>
                    )}
                    {/* {promotionFilter.length > 0 && promotionFilter1.length > 0 && 
    <span> items are </span>
  } */}
                    {(promotionFilter.length === 0 &&
                      promotionFilter1.length === 0) || (
                      <span> item are available for you </span>
                    )}
                  </p>
                </div>
              </div>

              <div class="row" style={{ width: "150%" }}>
                {/* <OwlCarousel items={5}
                            className="owl-theme"
                            loop
                            nav
                            margin={0}
                     
                        > */}
                {serviceShow &&
                  promotionFilter.map((item) => {
                    const isFavorite =
                      favData &&
                      favData.some(
                        (favItem) => item.item_service === favItem.p_id
                      );
                    return (
                      <div className="doctor-card456">
                        <div
                          className="text-center123"
                          style={{ width: "270px", fontSize: "17px" }}
                        >
                          <b onClick={() => ""} style={{ color: "black" }}>
                            {item.item_name} <span>&nbsp;</span>{" "}
                            <Badge
                              pill
                              variant="success"
                              style={{ fontSize: "10px" }}
                            >
                              {item.discount}% OFF!
                            </Badge>
                            <Link
                              to="/search"
                              state={{ details: item.item_service }}
                              style={{ float: "right", marginRight: "1%" }}
                            >
                              <i className="bx bx-search-alt"></i>
                            </Link>
                          </b>
                          <br />
                          <span
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginTop: "6px",
                            }}
                          >
                            <b
                              style={{
                                fontSize: "11px",
                                margin: "0 0 2px 0",
                              }}
                            >
                              <i
                                className="fa fa-check-square"
                                aria-hidden="true"
                              ></i>
                              <span>&nbsp;</span>
                              {item.service_category === "master_scan"
                                ? "Scan"
                                : item.service_category === "misc"
                                ? "Misc"
                                : item.service_category === "master_medicine"
                                ? "Medicine"
                                : item.service_category === "master_procedure"
                                ? "Procedure"
                                : item.service_category === "master_consumable"
                                ? "Consumables"
                                : item.service_category === "master_labtest"
                                ? "Test"
                                : item.service_category === "registration"
                                ? "Registration"
                                : ""}
                            </b>
                            <b style={{ fontSize: "11px", margin: "0" }}>
                              <i
                                className="fa fa-h-square"
                                aria-hidden="true"
                              ></i>
                              <span>&nbsp;</span>
                              {item.hospital_name}
                            </b>
                          </span>
                          <div
                            className="price"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "250px",
                              marginTop: "10px",
                            }}
                          >
                            <b style={{ fontSize: "14px", color: "black" }}>
                              {item.amount !==
                              (item.amount * (100 - item.discount)) / 100 ? (
                                <span className="price">
                                  <span
                                    className="old-price"
                                    style={{
                                      textDecoration: "line-through",
                                      color: "grey",
                                      fontWeight: "normal",
                                      fontSize: "15px",
                                    }}
                                  >
                                    <i class="fa fa-usd" aria-hidden="true"></i>
                                    {item.amount}
                                  </span>
                                  <span>&nbsp;</span>
                                  <span className="discounted-price">
                                    <b>
                                      {" "}
                                      {(
                                        (item.amount * (100 - item.discount)) /
                                        100
                                      ).toFixed(2)}
                                    </b>
                                  </span>
                                </span>
                              ) : (
                                <>
                                  <span className="price">
                                    {" "}
                                    <i class="fa fa-usd" aria-hidden="true"></i>
                                    <b> {item.amount} </b>
                                  </span>
                                </>
                              )}
                            </b>

                            <span style={{ marginLeft: "10px" }}></span>
                            {login_datas ? (
                              <FaHeart
                                onClick={() => AddTohandleFavorites(item)}
                                className={
                                  isFavorite
                                    ? classes.favAdded
                                    : classes.favNotAdded
                                }
                                style={{ color: "#e2e2d0", marginLeft: "5px" }}
                              />
                            ) : (
                              ""
                            )}
                            <button
                              className="my-button"
                              style={{ marginLeft: "1%" }}
                              onClick={() => addToCart(item)}
                            >
                              Add to Cart
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                {consumableShow && promotionFilter1.length > 0 ? (
                  <div style={{ display: "contents", width: "200px" }}>
                    {promotionFilter1.map((item) => {
                      const isFavorite =
                        favData &&
                        favData.some(
                          (favItem) => item.item_service === favItem.p_id
                        );
                      return (
                        <div
                          className="single-products-box"
                          style={{ width: "20%", marginRight: "3%" }}
                        >
                          <div
                            className="image"
                            style={{ backgroundColor: "#f7f8fa" }}
                          >
                            <div className="sale">Sale</div>
                            <a href="#" className="d-block">
                              {" "}
                              <Link
                                to="/search"
                                state={{ details: item.item_service }}
                              >
                                <img
                                  src={item.image}
                                  alt="image"
                                  style={{
                                    height: "250px",
                                    width: "300px",

                                    backgroundColor: "#f7f8fa",
                                  }}
                                  onClick={() => ""}
                                />
                              </Link>
                            </a>

                            <div className="buttons-list">
                              <ul>
                                <li>
                                  <div
                                    onClick={() => addToCart(item)}
                                    className="cart-btn"
                                  >
                                    <a
                                      data-toggle="modal"
                                      data-target="#productsQuickView"
                                    >
                                      <i
                                        class="fa fa-cart-plus"
                                        aria-hidden="true"
                                      ></i>
                                      <span className="tooltip-label">
                                        Add to Cart
                                      </span>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div
                                    onClick={() => ""}
                                    className="quick-view-btn"
                                  >
                                    {" "}
                                    <Link
                                      to="/search"
                                      state={{ details: item.item_service }}
                                    >
                                      <i className="bx bx-search-alt"></i>
                                      <span className="tooltip-label">
                                        Quick View
                                      </span>
                                    </Link>
                                  </div>
                                </li>
                                <li>
                                  {login_datas ? (
                                    <div
                                      onClick={() => AddTohandleFavorites(item)}
                                      className="quick-view-btn"
                                    >
                                      <a
                                        data-toggle="modal"
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          paddingTop: "2px",
                                        }}
                                      >
                                        <MdFavorite
                                          className={
                                            isFavorite
                                              ? classes.favAdded
                                              : classes.favNotAdded
                                          }
                                        />
                                        <span className="tooltip-label">
                                          Add to Favorite
                                        </span>
                                      </a>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div className="content">
                            <h3 style={{ fontFamily: "Arial, monospace" }}>
                              <b>{item.item_service_name}</b>
                            </h3>
                            <div className="price">
                              <span className="old-price">
                                {" "}
                                <i class="fa fa-usd" aria-hidden="true"></i>
                                {item.amount}
                              </span>
                              <span>&nbsp;</span>{" "}
                              <span className="discounted-price">
                                {(
                                  (item.amount * (100 - item.discount)) /
                                  100
                                ).toFixed(2)}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    {/* </OwlCarousel> */}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />
      <PatientFooter />

      {showDetails ? (
        <DetailPopup
          show={showDetails}
          data={selectedItem}
          onHide={() => {
            setShowDetails(false);
          }}
        />
      ) : (
        ""
      )}

      {cartShow ? (
        <AddtoCartPopUp
          show={cartShow}
          data={cartdata}
          onHide={() => {
            setCartShow(false);
          }}
        />
      ) : (
        ""
      )}
    </>
  );
}
export default DealsPage;
