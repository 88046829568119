import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { APIURL } from "../../../../Global";
import isBefore from 'date-fns/isBefore';
import "rsuite/dist/rsuite.min.css";
import { DateRangePicker } from "rsuite";
import { useAlert } from 'react-alert'
import moment from 'moment'
import "./deals.css"
import ShowMoreText from "react-show-more-text";
import { Modal } from 'react-bootstrap';
import LoadingOverlay from "react-loading-overlay";
import Select from 'react-select';
function DealPromo(props) {

    const [overlay, setOverlay] = useState({
        overlay_text: "",
        overlay_colour: "",
        overlay_size: "",
    })
    const group3 = [
        { value: 'red', label: 'Red' },
        { value: 'pink', label: 'Pink' },
        { value: 'orange', label: 'Orange' },
        { value: 'yellow', label: 'Yellow' },
        { value: 'blue', label: 'Blue' },
        { value: 'lightblue', label: 'Light Blue' },
        { value: 'darkblue', label: 'Dark Blue' },
        { value: 'violet', label: 'Violet' },
        { value: 'purple', label: 'Purple' },
        { value: 'green', label: 'Green' },
        { value: 'darkgreen', label: 'Dark Green' },
        { value: 'lightgreen', label: 'Light Green' },
        { value: 'black', label: 'Black' },
        { value: 'white', label: 'White' },
        { value: 'brown', label: 'Brown' },
    ]
    const [colour, setColour] = useState({ label: "overlay text colour", value: "Overlay Text Colour" });
    const [errorShow, setErrorShow] = useState(false)
    const [errormsg, setErrormsg] = useState("")
    const [prodTemp, setProdTemp] = useState([{ name: "", id: 0 }])
    const alert = useAlert();
    const [img1, setImg1] = useState(null);
    const [img2, setImg2] = useState(null);
    const [isUpdating1, setIsUpdating1] = useState(false)
    const [isUpdating4, setIsUpdating4] = useState(false)
    const [previewOn1, setPreviewOn1] = useState(false);
    const [previewOn2, setPreviewOn2] = useState(false);
    const [invalidPic, setInvalidPic] = useState(null);
    const [searchTerm1, setSearchTerm1] = useState("")
    const [promoId1, setPromoId1] = useState(0)
    const [prod1, setProd1] = useState([])
    const [uploadShow, setUploadShow] = useState(false);
    const [validShow, setValidShow] = useState(false);
    const [sizeShow, setSizeShow] = useState(false);
    const [contentShow, setContentShow] = useState(false);
    const [display1, setDisplay1] = useState(false);
    const [displayDisc, setDisplayDisc] = useState(false);
    const [date1, setDate1] = useState({ from: "", to: "" })
    const [dateChaned1, setDateChanged1] = useState(false)
    const [disc1, setDisc1] = useState()
    const [discList1, setDiscList1] = useState([])
    const [newList, setNewList] = useState([])
    const reader = new FileReader();
    var dateObj = new Date();
    const aggr_type = sessionStorage.getItem("aggr_type") ? sessionStorage.getItem("aggr_type") : null

    useEffect(() => {
        setOverlay({
            overlay_text: "",
            overlay_colour: "",
            overlay_size: '',
        })
        setDateChanged1(false)
        setIsUpdating1(false);
        setIsUpdating4(false)
        setDate1({ from: "", to: "" })
        setDisc1(0)
        setDiscList1([]);
        setColour({ label: "overlay text colour", value: "Overlay Text Colour" })

        if (props.dataList.length > 0) {
            setOverlay({
                overlay_text: props.dataList[0].overlay_text !== null ? props.dataList[0].overlay_text : "",
                overlay_colour: props.dataList[0].overlay_colour === null ? "Overlay Text Colour":props.dataList[0].overlay_colour,
                overlay_size: props.dataList[0].overlay_size !== null ? props.dataList[0].overlay_size : "",
            })

            setColour({ value: props.dataList[0].overlay_colour, label: props.dataList[0].overlay_colour!==null ? props.dataList[0].overlay_colour.charAt(0).toUpperCase() + props.dataList[0].overlay_colour.slice(1) : "Overlay Text Colour" })
            setPromoId1(props.dataList[0].promotion)
            setDate1({ from: props.dataList[0].start_date, to: props.dataList[0].end_date })
            setImg1(props.dataList[0].banner);
            setDisc1(props.dataList[0].promo_offer)
            setPreviewOn1(true)
            if (props.dataList[0].promo_banner_app !== null) {
                setImg2(props.dataList[0].promo_banner_app)
                setPreviewOn2(true)
            }
            else {
                setImg2(null)
                setPreviewOn2(false)
            }
        }
        else {
            setImg1(null);
            setImg2(null)
            setPreviewOn1(false);
            setPreviewOn2(false)
        }
    }, [props.dataList])

    useEffect(() => {

        prod1.splice(0, prod1.length + 1)
        if (props.dataList.length > 0) {
            let pro1 = props.dataList[0].prom_items

            pro1.map((item, index) => {


                prod1.push({
                    name: item.item_name,
                    id: item.item_service
                })

            })
        }
    }, [props.dataList])
    const initialRender = useRef(true);
    useEffect(() => {

        if (initialRender.current) {
            initialRender.current = false;
        }
        if (searchTerm1 === "") {
            setNewList([]);
            setDisplay1(false);
        }



        if (searchTerm1 !== "") {

            const tokenString = sessionStorage.getItem("usertoken");

            let v2 = tokenString.replace(
                /["]+/g,
                ""
            ); /* REGEX To remove double quotes from tokenstring */

            axios
                .get(
                    `${APIURL}/api/v1/service-provider/hosp-services-promotions/?search=${searchTerm1}`,
                    {
                        headers: { Authorization: "Token " + v2 },
                    }
                )
                .then((res) => {
                    if (res.status === 200) {
                        setNewList(res.data.services);


                        setDisplay1(true)




                    } else {
                    }
                })
                .catch((error) => { });
        }
    }, [searchTerm1]);





    useEffect(() => {

        if (initialRender.current) {
            initialRender.current = false;
        } else if (disc1 === null) {

            setDiscList1([]);

        }




        if (disc1 !== 0) {

            const tokenString = sessionStorage.getItem("usertoken");

            let v2 = tokenString.replace(
                /["]+/g,
                ""
            ); /* REGEX To remove double quotes from tokenstring */

            axios
                .get(
                    `${APIURL}/api/v1/service-provider/hosp-services-promotions/?disc=${disc1}&complete=true`,
                    {
                        headers: { Authorization: "Token " + v2 },
                    }
                )
                .then((res) => {
                    if (res.status === 200) {

                        setDiscList1(res.data.services);


                    } else {
                    }
                })
                .catch((error) => { });
        }
    }, [disc1]);
    const handleActivation = (action) => {
        let data = {}
        if (action === "act") {

            data = {
                is_active: !props.dataList[0].is_active

            }
        } else {
            data = {
                is_deleted: true
            }
        }

        const tokenStr = sessionStorage.getItem("usertoken");


        if (tokenStr) {
            const v = tokenStr;
            let v2 = v.replace(/["]+/g, "");
            axios.put(`${APIURL}/api/v1/service-provider/add-promotions/${promoId1}/`, data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Token " + v2,
                },
            })
                .then((res) => {
                    if (res.data.status === "success") {
                        alert.success("Successfully Updated")
                        props.onSuccess()

                    } else {
                        alert.error("Error in submission")
                    }
                })
                .catch((err) => {
                    alert.error("Error in submission");
                });
        }

    };
    const ErrorPopup = (props) => {


        return (
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h6 style={{ color: "red" }}> {errormsg}</h6>

                </Modal.Body>
                <Modal.Footer>

                    <div className="form-group " style={{ marginLeft: "120px" }}>
                        <button type="button" className="btn btn-danger btn-cancel" onClick={props.onHide}> Cancel </button>
                        <button type="submit" className="btn btn-primary btn-col" onClick={() => {

                            setProd1([...prod1, {
                                name: prodTemp.name,
                                id: prodTemp.id
                            }])


                                ; setErrorShow(false)
                        }
                        } >Confirm </button>
                    </div>




                </Modal.Footer>
            </Modal>
        );
    }
    const uploadImage = async (e) => {
        const key = e.target.name;
        const file = e.target.files[0];
        console.log(key)

        if (!file) {
            setUploadShow(!uploadShow);
            //setInvalidPic('Please Select an Image')
            return false;
        }
        if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
            setValidShow(!validShow);
            //setInvalidPic('Please select valid image')
            return false;
        }
        if (file.size > 2e6) {
            setSizeShow(!sizeShow);
            return false;
        }

        reader.onload = (e) => {
            const img = new Image();
            img.onload = () => {
                if (key === "img1")
                    setImg1(file);
                else if (key === "img2")
                    setImg2(file);

                setInvalidPic(null);
            };

            img.onerror = () => {
                // setPreviewOn(false);
                setContentShow(!contentShow);
                //setInvalidPic('Invalid Image Content')
                return false;
            };
            img.src = e.target.result;
        };

        reader.readAsDataURL(file);

        const objectURL = URL.createObjectURL(file);
        if (key === "img1") {

            setPreviewOn1(true);
        } else if (key === "img2") {

            setPreviewOn2(true);
        }
    };
    const removePicPreview = (e, img) => {
        e.preventDefault();

        if (img === "img1") {
            setPreviewOn1(false);
            setImg1("")
        }
        else if (img === "img2") {
            setPreviewOn2(false);
            setImg2("")
        }

    };

    const handleDiscChange = (e) => {

        if (e.target.name === "disc1") {

            setDisc1(e.target.value);
            setDisplayDisc(true)
        }

        // if (e.target.value === '') {
        //     setDisplay1(false);

        // }
        // document.body.addEventListener("click", bodyClickHandler);
    };

    const handleSearchChange = (e) => {

        if (e.target.name === "search1") {

            setSearchTerm1(e.target.value);
        }


        if (e.target.value === '') {
            setDisplay1(false);

        }

    };
    const handleUpdate = (e) => {
        let arr = []
        e.preventDefault();
        let data = {}
        let isimg = false
        const fd = new FormData();
        const tokenStr = sessionStorage.getItem("usertoken");
        if (isUpdating1 === true || isUpdating4 === true) {
            if (props.dataList.length !== 0 && props.dataList[0].banner !== img1) {
                fd.append(`promo_banner`, img1)
                fd.append(`promo_display`, props.dataList[0].promo_display)
                isimg = true
            }
            else if (props.dataList.length !== 0 && props.dataList[0].promo_banner_app !== img2) {
                fd.append(`promo_banner_app`, img2)
                fd.append(`promo_display`, props.dataList[0].promo_display)
                isimg = true
            } else if (props.dataList.length !== 0 && props.dataList[0].promo_banner_app !== img2 && props.dataList[0].banner !== img1) {
                fd.append(`promo_banner`, img1)
                fd.append(`promo_banner_app`, img2)
                fd.append(`promo_display`, props.dataList[0].promo_display)
                isimg = true
            }
            else {
                if (props.dataList.length !== 0 && props.dataList[0].promo_offer !== disc1) {
                    prod1.map((item) => {
                        arr.push(item.id)
                    })
                    data = {
                        "items": arr,
                        "start_date": date1.from,
                        "end_date": date1.to,
                        "offer_percent": disc1,
                        "overlay_text": overlay.overlay_text,
                        "overlay_colour": overlay.overlay_colour,
                        "overlay_size": overlay.overlay_size,
                        "promo_display": props.dataList[0].promo_display

                    }
                    isimg = false
                } else {
                    prod1.map((item) => {
                        arr.push(item.id)
                    })
                    data = {
                        "items": arr,
                        "start_date": date1.from,
                        "end_date": date1.to,
                        "overlay_text": overlay.overlay_text,
                        "overlay_colour": overlay.overlay_colour,
                        "overlay_size": overlay.overlay_size,
                        "promo_display": props.dataList[0].promo_display

                    }
                    isimg = false
                }

            }
        }

        if (img1 === "") {
            alert.error("Please add banner for Web")
        }
        else if (img2 === "") {
            alert.error("Please add banner for App")
        }
        else if (prod1.length === 0) {
            alert.error("Please add deals product")
        } else if (disc1 === 0) {
            alert.error("Please add a discount")
        } else if (date1.from === "" || date1.to === "") {
            alert.error("Please select a date range")
        }
        else {

            if (tokenStr) {
                const v = tokenStr;
                let v2 = v.replace(/["]+/g, "");
                axios.put(`${APIURL}/api/v1/service-provider/add-promotions/${promoId1}/`, isimg ? fd : data, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Token " + v2,
                    },
                })
                    .then((res) => {
                        if (res.data.status === "success") {
                            alert.success("Successfully Updated")
                            props.onSuccess()

                        } else {
                            alert.error("Error in submission")
                        }
                    })
                    .catch((err) => {
                        alert.error("Error in submission");
                    });
            }
        }

    };
    const handleEvent1 = (event, picker) => {
        const a = moment(event[0]).format('YYYY-MM-DDThh:mm');
        let b = moment(event[1]).format('YYYY-MM-DDThh:mm');
        setDateChanged1(true)
        setDate1({ from: a, to: b })
    }
    const handleColorChange = (data) => {
        setColour(data)
        setOverlay(current => ({
            ...current, overlay_colour: data.value
        }))
    }
    const handleTitle = (e) => {
        e.preventDefault();
        const val = e.target.value;
        const key = e.target.name;
        setOverlay(current => ({
            ...current, [key]: val
        }))
    }
    const handleSubmit = () => {
        const fd = new FormData();
        const tokenStr = sessionStorage.getItem("usertoken");


        fd.append(`promotions[0]promo_type`, "deal_promotion")
        fd.append(`promotions[0]promo_banner`, img1)
        fd.append(`promotions[0]promo_banner_app`, img2)
        fd.append(`promotions[0]start_date`, date1.from)
        fd.append(`promotions[0]end_date`, date1.to)
        fd.append(`promotions[0]offer_percent`, disc1)
        fd.append(`promotions[0]promo_display`, props.display)
        fd.append(`promotions[0]overlay_text`, overlay.overlay_text)
        fd.append(`promotions[0]overlay_size`, overlay.overlay_size)
        fd.append(`promotions[0]overlay_colour`, overlay.overlay_colour)
        prod1.map((item, index) => {
            item.id !== 0 && fd.append(`promotions[0]promotion_items[${index}]`, item.id)
        })

        if (img1 === "") {
            alert.error("Please add banner for Web")
        }
        else if (img2 === "") {
            alert.error("Please add banner for App")
        }
        else if (prod1.length === 0) {
            alert.error("Please add deals product")
        } else if (disc1 === 0) {
            alert.error("Please add a discount")
        } else if (date1.from === "" || date1.to === "") {
            alert.error("Please select a date range")
        }
        else {

            if (tokenStr) {
                const v = tokenStr;
                let v2 = v.replace(/["]+/g, "");
                axios.post(`${APIURL}/api/v1/service-provider/add-promotions/?${aggr_type === "aggregator" ? "aggregator=True" : ""}`, fd, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Token " + v2,
                    },
                })
                    .then((res) => {
                        if (res.data.status === "success") {
                            alert.success("Successfully Updated")
                            props.onSuccess()

                        } else {
                            alert.error(res.data.message)
                        }
                    })
                    .catch((err) => {
                        alert.error("Error in submission");
                    });
            }
        }

    };

    return (
        <div className="row">
            <h6 style={{ color: "#1FAEDB", marginTop: "2%" }} className="text-center"><b>
                {props.display === "dp1" ? "Deal Promotions 1" : props.display === "dp2" ? "Deal Promotions 2" : "Deal Promotions 3"}
            </b>

                {props.dataList.length > 0 ? <div style={{ float: "right", paddingRight: "2%", display: "flex" }}>
                    <i style={{
                        marginLeft: "25px",
                        color: props.dataList.length > 0 && props.dataList[0].is_active ? "green" : "red",
                    }} onClick={() => { handleActivation("act") }}
                        class={props.dataList.length > 0 && props.dataList[0].is_active ? "fa fa-toggle-on fa-lg" : "fa fa-toggle-on fa-lg fa-rotate-180 "}
                        aria-hidden="true"></i>
                    <i style={{
                        marginLeft: "25px",
                        color: props.dataList.length > 0 && "red",
                    }} onClick={() => { handleActivation("del") }} class="fa fa-trash-o fa-lg" aria-hidden="true"></i>
                </div> : ""}
            </h6>
            <div style={{ marginTop: "3%", boxShadow: "2px 0px 0px #b4b2b2", height: "350px" }} className="col-5 row">

                <div className="col-6">
                    <LoadingOverlay
                        active={props.dataList.length > 0 && !props.dataList[0].is_active}
                    >


                        <div className="avatardeals">
                            <div className="insideavtr">Web</div>
                            {previewOn1 === false ? (
                                <div className="image-holder-div">
                                    <input

                                        name="img1"
                                        type="file"
                                        className="custom-file-input"
                                        id="validatedCustomFile1"
                                        onChange={uploadImage}
                                    />
                                    {invalidPic && <p className="error">{invalidPic}</p>}
                                    <label style={{ width: "100%" }} className="" htmlFor="validatedCustomFile1">


                                        <i style={{ marginLeft: "42%", marginTop: "15%", color: "#ADADAD", }} className="fa fa-picture-o fa-2x"></i><br />
                                        <span style={{ marginLeft: "35%", color: "#ADADAD", fontSize: "10px" }}>Size : 570x570</span>

                                    </label>

                                </div>
                            )

                                : (
                                    <div>
                                        {isUpdating1 || props.dataList.length === 0 ? <div onClick={(e) => { removePicPreview(e, "img1") }}>
                                            <i
                                                style={{ position: "absolute" }}
                                                className="fas fa-times-circle"
                                            ></i>
                                        </div> : ""}
                                        <img
                                            src={img1}
                                            alt="Deals Image.Please Save to view"
                                            className="avatardeals"

                                        />
                                    </div>
                                )}</div>

                        <br /><br />


                    </LoadingOverlay>

                </div>

                <div className="col-6">
                    <LoadingOverlay
                        active={props.dataList.length > 0 && !props.dataList[0].is_active}
                    >


                        <div className="avatardeals">
                            <div className="insideavtr">Mobile</div>
                            {previewOn2 === false ? (
                                <div className="image-holder-div">
                                    <input

                                        name="img2"
                                        type="file"
                                        className="custom-file-input"
                                        id="validatedCustomFile2"
                                        onChange={uploadImage}
                                    />
                                    {invalidPic && <p className="error">{invalidPic}</p>}
                                    <label style={{ width: "100%" }} className="" htmlFor="validatedCustomFile2">


                                        <i style={{ marginLeft: "42%", marginTop: "15%", color: "#ADADAD", }} className="fa fa-picture-o fa-2x"></i><br />
                                        <span style={{ marginLeft: "35%", color: "#ADADAD", fontSize: "10px" }}></span>

                                    </label>

                                </div>
                            )

                                : (
                                    <div>
                                        {isUpdating1 || props.dataList.length === 0 ? <div onClick={(e) => { removePicPreview(e, "img2") }}>
                                            <i
                                                style={{ position: "absolute" }}
                                                className="fas fa-times-circle"
                                            ></i>
                                        </div> : ""}

                                        <img
                                            src={img2}
                                            alt=" Deals App Image.Please Save to view "
                                            className="avatardeals"
                                        />
                                    </div>
                                )}</div>
                        <br /><br />

                    </LoadingOverlay>
                </div>

                {props.dataList.length > 0 ? <button style={{ width: "100px", backgroundColor: "#115269", marginLeft: "42%", marginTop: "5%" }} onClick={(e) => { !isUpdating1 ? setIsUpdating1(true) : handleUpdate(e, 1) }} className="webpromo">{!isUpdating1 ? <i class="fa fa-pencil" aria-hidden="true">{" "}Edit</i> : <i class="fa fa-floppy-o" aria-hidden="true">{" "}Update</i>}</button> : ""}
            </div>
            <div style={{ marginTop: "2%", paddingLeft: "2%" }} className="col-7">
                <LoadingOverlay
                    active={props.dataList.length > 0 && !props.dataList[0].is_active}
                >
                    <div className="row">
                        <div className='col-7 grid-prop' >

                            <textarea disabled={!isUpdating4 && props.dataList.length !== 0} rows="4" cols="100" maxlength="100" style={{ height: "90px" }} type='text'
                                placeholder='Overlay Text(max 100 characters)' className='gridinputprop' name='overlay_text'
                                value={overlay.overlay_text} onChange={handleTitle} />
                        </div>
                        <div className='col-5  grid-prop'>
                            <div>
                                <input disabled={!isUpdating4 && props.dataList.length !== 0} type='number' placeholder='Overlay Text size in px' className='gridinputprop' name='overlay_size' value={overlay.overlay_size} onChange={handleTitle} />
                            </div>
                            <div style={{ marginTop: "7%" }}>
                                <Select
                                    isDisabled={!isUpdating4 && props.dataList.length !== 0}
                                    value={colour}

                                    onChange={(data, e) => {
                                        handleColorChange(data, e)
                                    }}
                                    placeholder='Overlay Text Color'
                                    options={group3}
                                    name='color'
                                    className="gridinputprop "
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex" }}>

                        {date1.from === "" || isUpdating4 ? <div className="prod-div-date" style={{ fontSize: "small", marginTop: "2%" }}>

                            <DateRangePicker
                                appearance="default"
                                style={{ width: "320px" }}
                                placeholder={"Select Date Range"}
                                onChange={handleEvent1}
                                disabledDate={(date) =>
                                    isBefore(date, dateObj)}

                            >

                            </DateRangePicker>&nbsp;&nbsp;&nbsp;
                        </div> :
                            <div className="prod-div-date" style={{ fontSize: "small", display: "flex", marginTop: "2%" }}>
                                <div class="dealsinputDate" style={{ width: "70px" }}>Range</div>

                                <div class="dealsinputDate">{date1.from !== null && (date1.from).split("T")[0]}&nbsp;To&nbsp;{date1.to !== null && (date1.to).split("T")[0]}</div>
                                {dateChaned1 ? <i style={{ marginTop: "-17px" }} onClick={() => { setDate1({ from: "", to: "" }) }} className="fas fa-times"></i> : ""}</div>}
                                &nbsp;&nbsp;&nbsp;
                        <div class="prod-div-date" style={{ marginTop: "2%", display: "flex" }}>
                            <span style={{ display: "flex", paddingTop: "4%", fontSize: "small" }} class="dealsdisc">&nbsp;Discount<i style={{ marginTop: "3%", }} class="fa fa-percent fa-fw"></i></span>
                            <input disabled={props.dataList.length > 0 && props.dataList[0].promo_offer !== 0} style={{ width: "50px" }} onChange={handleDiscChange} class="dealsdisc" name="disc1" value={disc1} type="number" placeholder="" />

                        </div>

                    </div>
                    <div style={{ marginTop: "5%" }} className="form-button">
                        <div className="">
                            <div className="" style={{ height: "38px", marginTop: "-1px", border: "1px solid #948686", backgroundColor: "white", width: "550px" }}>
                                <div style={{ display: "flex", marginTop: "0.8%", marginLeft: "2%", paddingRight: "1%" }}>
                                    <input
                                        name="search1"
                                        disabled={!isUpdating4 && props.dataList.length !== 0}
                                        type="text"
                                        className=""
                                        placeholder="Search here "
                                        value={searchTerm1}
                                        onChange={handleSearchChange}
                                        style={{ width: "500px", paddingLeft: "10px", border: "none", fontSize: "15px" }}
                                    />
                                    <div style={{ paddingTop: "0.9%", paddingLeft: "1.5%", color: "gray", height: "30px", width: "30px" }} className="search-btn1">
                                        {" "}
                                        <i className="fas fa-search"></i>{" "}
                                    </div>
                                </div>
                            </div >

                            {display1 ? (
                                <div className="suggest-display adjust-dimensions" >
                                    {newList.length > 0 ? (
                                        newList.map((item) => {
                                            return (
                                                <p
                                                    onClick={(e) => {
                                                        if (item.promo_display !== null) {
                                                            if (item.discount !== disc1) {
                                                                setProdTemp({
                                                                    name: item.item_service_name,
                                                                    id: item.item_service
                                                                })
                                                                setErrormsg(item.item_service_name + " is already added in " + item.promo_display + " with a discount of " + item.discount + "%. If you want to proceed, then item will be disconnected from " + item.promo_display)
                                                                setErrorShow(true)
                                                                setDisplay1(false);
                                                                setSearchTerm1("")
                                                            }

                                                        } else {
                                                            setProd1([...prod1, {
                                                                name: item.item_service_name,
                                                                id: item.item_service
                                                            }]);
                                                            setDisplay1(false);
                                                            setSearchTerm1("")
                                                        }
                                                    }}
                                                    className=" text-secondary"
                                                    style={{ padding: "2%" }}
                                                >
                                                    {item.item_service_name}
                                                </p>
                                            );
                                        })
                                    ) : (
                                        <h6 className="text-muted" style={{ padding: 10 }}>
                                            No Services found !!
                                        </h6>
                                    )}
                                </div>
                            ) : null}
                            <br /><br />
                            {displayDisc && <ShowMoreText
                                lines={3}
                                more="Show more"
                                less="Show less"
                                className="content-css"
                                anchorClass="show-more-less-clickable"
                                expanded={false}
                                truncatedEndingComponent={"... "}
                            >
                                {discList1.length > 0 && discList1.map((item) => {
                                    return (

                                        <div>{item.item_service_name}<br /></div>
                                    );
                                })}
                            </ShowMoreText>}
                            <div className="">
                                {/* {props.dataList.length > 0 ? <button style={{ float: "right" }} onClick={(e) => { !isUpdating4 ? setIsUpdating4(true) : handleUpdate(e, 1, promoId1) }} className={!isUpdating4 ? "promo-edit-button" : "promo-save-button"}>
                                <i class={!isUpdating4 ? "fa fa-pencil" : "fa fa-floppy-o"} aria-hidden="true"></i></button> : ""} */}
                                {prod1.length > 0 && prod1.map((item, index) => {
                                    return (

                                        <div> {prod1[index].id !== 0 && <i
                                            class="fa fa-trash-o "
                                            style={{ cursor: isUpdating4 ? "pointer" : "none", color: "red" }}
                                            onClick={() => {
                                                if (isUpdating4)
                                                    setProd1((current) =>
                                                        current.filter((i) => i.id !== item.id)
                                                    );



                                            }}
                                        ></i>}&nbsp;&nbsp; {item.name}</div>)
                                })}
                            </div>

                        </div>
                    </div>
                </LoadingOverlay>
            </div>
            <div className="form-button" style={{ float: "right", }}>
                <button onClick={(e) => { props.dataList.length === 0 ? handleSubmit() : !isUpdating4 ? setIsUpdating4(true) : handleUpdate(e, 1, promoId1) }} style={{ backgroundColor: "#115269", marginLeft: "80%", marginBottom: "10%" }} className="webpromo"> <i class={props.dataList.length === 0 ? "" : !isUpdating4 ? "fa fa-pencil" : "fa fa-floppy-o"} aria-hidden="true"></i>{" "}{props.dataList.length === 0 ? "Save" : !isUpdating4 ? "Edit" : "Update"}</button>
            </div>
            {/* <div className="form-button" style={{ float: "right", }}>
                <button disabled={props.dataList.length !== 0} onClick={handleSubmit} style={{ backgroundColor: "#115269", marginLeft: "80%", marginBottom: "3%" }} className="webpromo">Save</button>
            </div> */}
            {
                errorShow ?
                    <ErrorPopup
                        show={errorShow}
                        onHide={() => {
                            setErrorShow(false)
                        }}
                    /> : null
            }
        </div>

    )
}
export default DealPromo