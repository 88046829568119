import React, { useState, useEffect, useContext } from "react";
import "./cart.css";
import {
  Link,
  useNavigate,
  NavLink,
  useLocation,
  Navigate,
} from "react-router-dom";
import axios from "axios";
import PatientNav from "./PatientNav";
import loginpage from "./assets/img/loginpage.png";
import horizonlogo from "./assets/img/horizonlogo.png";
import { APIURL } from "../../Global";
import PhoneInput from "react-phone-input-2";
import { LoginStatusContext } from "../contexts/LoginStatusContext";
import { LoginContext } from "../contexts/LoginContext";
import { Modal, Form, ToggleButtonGroup, ToggleButton } from "react-bootstrap";
import { Button } from "react-bootstrap";

import DatePicker from "react-date-picker";
import LoadingOverlay from "react-loading-overlay";
import { Badge } from "react-bootstrap";
import { HospLoginContext } from "../contexts/HospitalLoginContext";
import AuthUtil from "../login/AuthUtil";
import "react-datepicker/dist/react-datepicker.css";
import { useAlert } from "react-alert";
function LoginPagePatient({ setShowNavbarAndFooter }) {
  let isValid = true;
  const [selectedValue, setSelectedValue] = useState("email");
  const [emaildata, setEmailData] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [loginUsername, setLoginUsername] = useState("");
  const [error, setError] = useState("");

  const [loginPassword, setLoginPassword] = useState("");
  const [aggregator, setAggregator] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [, setHospData] = useContext(HospLoginContext);
  //const [, setHospDocData] = useContext(HospDrLoginContext);
  const location = useLocation();
  const [, setLoginDatas] = useContext(LoginContext);
  const [, setIsLoggedIn] = useContext(LoginStatusContext);
  const [phonedata, setPhoneData] = useState({
    phone: {
      dialcode: "",
      rawphone: "",
      mobile_number: "",
    },
  });
  const [orgImg, setOrgImg] = useState("");
  const [orgFlag, setOrgFlag] = useState(false);

  const [regcount, setRegCount] = useState("");
  const [username, setUsername] = useState("");
  const [serveCount, setServeCount] = useState("");
  const [password, setPassword] = useState("");
  let navigate = useNavigate();
  const alert = useAlert();

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleToggle = (value) => {
    if (selectedValue === value) {
      // Deselect the button if it is clicked again
      setSelectedValue(null);
    } else {
      // Select the button
      setSelectedValue(value);
    }
  };

  useEffect(() => {
    setLoginDatas(null);
    setIsLoggedIn(false);
  }, []);

  useEffect(() => {
    axios.get(`${APIURL}/api/v1/account/customer-info/`).then((res) => {
      if (res.data.status === "success") {
        setServeCount(Number(res.data["service provider count"]));
        sessionStorage.setItem("project_type", res.data.project_type);
        setAggregator(res.data.aggregation_type);
        setOrgImg(res.data.organization_logo);
        if (
          res.data.organization_logo !== APIURL + "/media/null" &&
          res.data.organization_logo !== ""
        ) {
          setOrgFlag(true);
        }
        setRegCount(res.data.registered_sp_count);
      }
    });
  }, []);
  useEffect(() => {
    setShowNavbarAndFooter(false);
    return () => setShowNavbarAndFooter(true);
  }, [setShowNavbarAndFooter]);
  const handleEmailChane = (e) => {
    

    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;
    setEmailData(val);

    console.log(emaildata);
  };

  const handlePhoneInput = (value, data, event, formattedValue) => {
    const rawPhone = value.slice(data.dialCode.length);

    const dialcode = value.slice(0, data.dialCode.length);
    setPhoneData((currentstate) => ({
      ...currentstate,
      phone: {
        dialcode: dialcode,
        rawphone: rawPhone,
        mobile_number: value,
      },
    }));
  };
  const handleEmail = (e) => {
    e.preventDefault();

    if (emaildata !== "") {
      // Email validation
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(emaildata)) {
        setError("Please enter a valid email address");
        return;
      }

      let data = {
        email: emaildata,
      };

      axios
        .post(`${APIURL}/api/v1/account/check-user/`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            if (res.data.is_existing_user === true) {
              axios
                .post(`${APIURL}/api/v1/account/send-otp/`, data, {
                  headers: {
                    "Content-Type": "application/json",
                  },
                })
                .then((res) => {
                  if (res.data.status === "success") {
                    alert.success("OTP sent successfully to given email ID");
                    navigate("/emailotplogin", { state: { email: emaildata } });
                  } else {
                    alert.error(res.data.message);
                  }
                })
                .catch((err) => {});
            } else {
              alert.error("Email id not registered");
            }
          }
        });
    } else {
      setError("Please enter your email ID");
    }
  };

  const handlePhone = () => {
    
    if (
      phonedata.phone.rawphone !== "" &&
      phonedata.phone.rawphone.length === 10
    ) {
      // Phone number validation
      const phonePattern = /^[0-9]{10}$/;
      if (!phonePattern.test(phonedata.phone.rawphone)) {
        setError("Please enter a valid phone number");
        return;
      }

      let data = {
        code: "91",
        mobile_number: phonedata.phone.rawphone,
      };

      axios
        .post(`${APIURL}/api/v1/account/check-user/`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })

        .then((res) => {
          if (res.data.status === "success") {
            if (res.data.is_existing_user === true) {
              axios
                .post(`${APIURL}/api/v1/account/send-otp/`, data, {
                  headers: {
                    "Content-Type": "application/json",
                  },
                })
                .then((res) => {
                  if (res.data.status === "success") {
                    alert.success(
                      "OTP sent successfully to your mobile number"
                    );
                    navigate("/phoneotplogin", {
                      state: { phone: phonedata.phone.rawphone },
                    });
                  } else {
                    alert.error(res.data.message);
                  }
                })
                .catch((err) => {});
            } else {
              alert.error("Mobile Number Not Registered");
            }
          }
        });
    } else {
      setError("Please enter a valid phone number");
    }
  };

  const handleUser = async (e) => {
    console.log(serveCount, regcount);
    e.preventDefault();

    if (!password || password === "" || password === null) {
      isValid = false;
      alert.error("Please enter password");
    }

    if (password && typeof password !== "undefined") {
      if (password.length > 0 && password.length < 8) {
        isValid = false;
        alert.error("Password must contain atleast 8 characters");
      }
    }
    await apiCall();
  };

  const apiCall = () => {
    

    if (isValid)
      axios
        .post(
          `${APIURL}/api/v1/account/login/`,
          {
            username: username,
            password: password,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.data.status === "success") {
            console.log("location", location)
            sessionStorage.setItem("logindatas", JSON.stringify(res.data));

            setLoginDatas(res.data);
            /* Store Token */
            sessionStorage.setItem("usertoken", JSON.stringify(res.data.token));

            sessionStorage.setItem("usertype", res.data.user_type);
            sessionStorage.setItem("userid", res.data.user_id);
            sessionStorage.setItem("hospId", res.data.hospital_id);
            sessionStorage.setItem("role", res.data.role);
            sessionStorage.setItem("aggr_type", aggregator);
            if (res.data.verification_status === "pending") {
              sessionStorage.setItem("hosp_data", JSON.stringify(res.data));
              sessionStorage.setItem("hospital_pending", "true");
              setHospData(res.data);
            }
            setUsername("");
            setPassword("");
            // if(location.search==="?booking-details"){
            //   navigate("/booking-details" ,{
            //     state: 
            //     location.state
                
            //   })
            //   return;
            // }
            window.location.reload();
            // refreshPage();
            // }
          } else {
          
            alert.error(res.data.message);
          }

          const myFavKey = `myFavorite_${res.data.user_id}`;
          const item = localStorage.getItem(myFavKey);

          if (!item) {
            localStorage[myFavKey] = "[]";
          }
        })
        .catch(() => {
          setUsername("");
          setPassword("");
          alert.error("There was some error in login");
        });
  };

  const authUrl = AuthUtil.validateAuth();
  //When authentication is valid.
  if (authUrl) {
    if(location.search==="?booking-details"){
      return <Navigate to={"/booking-details"} state={location.state} />;
    }
    else{
      return <Navigate to={authUrl} state={{ from: location }} />;
    }
   
  }

  const handleUserNameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  return (
    <>
      <section className="products-area ptb-70" style={{ marginTop: "2%" }}>
        <div className="container" style={{ borderRadius: "2%" }}>
          <div class="row" style={{ borderRadius: "2%" }}>
            <div class="col-lg-8 col-md-6">
              <div className="vector">
                <div
                  className="leftpage"
                  style={{
                    backgroundImage: `url(${loginpage})`,
                    backgroundSize: "cover",
                  }}
                ></div>
              </div>
            </div>
            <div className="col-lg-1 col-md-1">
              <div className="vertical-line"></div>
            </div>

            <div class="col-lg-3 col-md-5">
              <div
                className="righttop"
                style={{
                  backgroundImage: `url(${horizonlogo})`,
                  backgroundSize: "cover",
                }}
              ></div>
              <h5 className="logintitle">Login to your Account</h5>

              <ToggleButtonGroup
                type="radio"
                name="options"
                style={{
                  marginLeft: "-15%",
                  marginTop: "3%",
                  backgroundColor: "#6BCBB8",
                }}
              >
                <ToggleButton
                  id="tbg-radio-1"
                  value={"email"}
                  className="btn btn-light toggle-btnsnew toggle-btn-7"
                  style={{
                    width: "119px",
                    height: "46px",
                    color: "white",
                    background:
                      selectedValue === "email" ? "#F0A639" : "#6BCBB8",
                    border: "0px",
                    borderRadius: "5px",
                  }}
                  onChange={() => handleToggle("email")}
                >
                  <h6
                    style={{
                      fontFamily: "Titillium Web",
                      fontSize: "14px",
                      marginTop: "5%",
                      marginLeft: "-5%",
                    }}
                  >
                    {" "}
                    EMAIL ID
                  </h6>
                </ToggleButton>
                <ToggleButton
                  id="tbg-radio-2"
                  value={"phone"}
                  className="btn btn-light toggle-btnsnew toggle-btn-7"
                  style={{
                    width: "119px",
                    height: "46px",
                    color: "white",

                    background:
                      selectedValue === "phone" ? "#F0A639" : "#6BCBB8",
                    border: "0px ",
                    borderRadius: "8.08px",
                  }}
                  onChange={() => handleToggle("phone")}
                >
                  <h6
                    style={{
                      fontFamily: "Titillium Web",
                      fontSize: "14px",
                      marginTop: "5%",
                      marginLeft: "-5%",
                    }}
                  >
                    {" "}
                    PHONE NUMBER
                  </h6>
                </ToggleButton>

                <ToggleButton
                  id="tbg-radio-3"
                  value={"user"}
                  className="btn btn-light toggle-btnsnew toggle-btn-7"
                  style={{
                    width: "119px",
                    height: "46px",
                    color: "white",
                    background:
                      selectedValue === "user" ? "#F0A639" : "#6BCBB8",
                    border: "0px",
                    borderRadius: "8.08px",
                  }}
                  onChange={() => handleToggle("user")}
                >
                  <h6
                    style={{
                      fontFamily: "Titillium Web",
                      fontSize: "14px",
                      marginTop: "5%",
                      marginLeft: "-5%",
                    }}
                  >
                    USERNAME
                  </h6>
                </ToggleButton>
              </ToggleButtonGroup>

              {selectedValue === "email" ? (
                <div
                  className="form-group"
                  style={{ marginLeft: "-15%", marginTop: "10%" }}
                >
                  <label
                    htmlFor="email"
                    style={{ display: "block", fontFamily: "Poppins" }}
                  >
                    Email ID
                  </label>
                  <div className="input-group" style={{ width: "350px" }}>
                    <input
                      type="text"
                      className="form-control emailname"
                      onChange={(e) => handleEmailChane(e)}
                      placeholder="Email ID"
                    />

                    <span className="insideinput">
                      <i
                        className="fa fa-envelope-o"
                        aria-hidden="true"
                        style={{
                          marginLeft: "30%",
                          marginTop: "30%",
                          color: "white",
                        }}
                      ></i>
                    </span>
                  </div>

                  {error && <div className="error-message">{error}</div>}
                </div>
              ) : selectedValue === "phone" ? (
                <div
                  className="form-group"
                  style={{ marginLeft: "-15%", marginTop: "10%" }}
                >
                  <label
                    htmlFor="password"
                    style={{
                      display: "block",
                      marginTop: "5%",
                      fontFamily: "Poppins",
                    }}
                  >
                    Phone Number
                  </label>
                  <div className="input-group" style={{ width: "350px" }}>
                    <PhoneInput
                      className="form-control emailname"
                      country={"in"}
                      onChange={handlePhoneInput}
                      
                      dropdownStyle={{ display: "none" }}
                    />
                    <span className="insideinput">
                      <i
                        className="fa fa-phone"
                        aria-hidden="true"
                        style={{
                          marginLeft: "30%",
                          marginTop: "30%",
                          color: "white",
                        }}
                      ></i>
                    </span>
                  </div>
                  {error && <div className="error-message">{error}</div>}
                </div>
              ) : (
                <div
                  className="form-group"
                  style={{ marginLeft: "-15%", marginTop: "10%" }}
                >
                  <label
                    htmlFor="user"
                    style={{ display: "block", fontFamily: "Poppins" }}
                  >
                    User Name
                  </label>
                  <div className="input-group" style={{ width: "350px" }}>
                    <input
                      type="text"
                      className="form-control emailname"
                      onChange={handleUserNameChange}
                      placeholder="Enter username"
                    />
                    <span className="insideinput">
                      <i
                        className="fa fa-user"
                        aria-hidden="true"
                        style={{
                          marginLeft: "30%",
                          marginTop: "30%",
                          color: "white",
                        }}
                      ></i>
                    </span>
                  </div>
                  <label
                    htmlFor="password"
                    style={{
                      display: "block",
                      marginTop: "5%",
                      fontFamily: "Poppins",
                    }}
                  >
                    Password
                  </label>
                  <div className="input-group" style={{ width: "350px" }}>
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control emailname"
                      value={password}
                      onChange={handlePasswordChange}
                      placeholder="Enter your password"
                    />
                    <span
                      className="insideinput"
                      onClick={togglePasswordVisibility}
                    >
                      <i
                        className={
                          showPassword ? "fa fa-eye-slash" : "fa fa-eye"
                        }
                        aria-hidden="true"
                        style={{
                          marginLeft: "30%",
                          marginTop: "30%",
                          color: "white",
                          cursor: "pointer",
                        }}
                      ></i>
                    </span>
                  </div>
                </div>
              )}
              {selectedValue === "user" ? (
                <Link to="/forgot-password">
                  <h6
                    className="forgot"
                    style={{ marginTop: "1%", marginLeft: "60%" }}
                  >
                    Forgot password
                  </h6>
                </Link>
              ) : (
                ""
              )}

              {selectedValue === "email" ? (
                <button
                  className="loginbutton"
                  onClick={handleEmail}
                  style={{ color: "white", fontFamily: "Poppins" }}
                >
                  Send OTP
                </button>
              ) : (
                ""
              )}
              {selectedValue === "phone" ? (
                <button
                  className="loginbutton"
                  onClick={handlePhone}
                  style={{ color: "white", fontFamily: "Poppins" }}
                >
                  Send OTP
                </button>
              ) : (
                ""
              )}
              {selectedValue === "user" ? (
                <button
                  className="loginbutton"
                  onClick={handleUser}
                  style={{ color: "white", fontFamily: "Poppins" }}
                >
                  Login Now
                </button>
              ) : (
                ""
              )}
              <br />

              <div
                className="or-text"
                style={{ marginLeft: "-2%", marginTop: "5%", color: "#C2C2C2" }}
              >
                ----------------------------- OR ----------------------------
              </div>

              <button
                className="registerpat"
                style={{ color: "#1E2772", fontFamily: "Poppins" }}
                onClick={() => navigate("/registerpat")}
              >
                SignUp
              </button>

              <button
                className="registerpat"
                onClick={() => navigate("/signup")}
                style={{
                  color: "#1E2772",
                  fontFamily: "Poppins",
                  marginTop: "3%",
                }}
              >
                Service Provider Registration
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default LoginPagePatient;
