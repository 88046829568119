
import React, { useEffect } from "react";
import {useLocation, Navigate } from "react-router-dom";
import axios from "axios";
import { APIURL } from "../../Global";



function LogoutPage() {
    const location = useLocation();
    
    useEffect(() => {
    
        const tokenString = sessionStorage.getItem("usertoken");
    

        let str = tokenString.replace(/["]+/g, "");
    
        axios
          .get(`${APIURL}/api/v1/account/logout/`, {
            headers: {
              Authorization: "Token " + str,
              "Content-Type": "application/json",
            },
          })
    
          .then((res) => {
            if (res.data.status === "success") {
                sessionStorage.removeItem("usertoken");
                
            } else {
            }
          })
          .catch((err) => {});
         
            
      }, []);
    
      
         
  
  return (
    <>
     <Navigate to="/webordering" state={{ from: location }} />;
    </>
  );
}

export default LogoutPage;
